
import React from 'react';
import * as AsyncStorage  from "./features/common/AsyncStorage/storageHelper";
import { createSwitchNavigator } from 'react-navigation';
import {NavigationActions} from 'react-navigation'
import RoutesConfig from "./routes";
import {Platform , PermissionsAndroid } from 'react-native';

if (Platform.OS == "android") {
  PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
      {
          title: 'Foster Family Protal External Storage Permission',
          message:
              'Foster Family Protal needs access to your storage ' +
              'so you can save files.',
          buttonNeutral: 'Ask Me Later',
          buttonNegative: 'Cancel',
          buttonPositive: 'OK',
      },
  );
}

const SwitchNavigator =  createSwitchNavigator(
  RoutesConfig,
  {
    initialRouteName: 'AuthLoading',
  }
);

// const previousGetActionForPathAndParams =
// SwitchNavigator.router.getActionForPathAndParams;

// Object.assign(SwitchNavigator.router, {
//   async getActionForPathAndParams(path, params) {
//     var userDetails =  {};
//     try {
//       userDetails = JSON.parse(await AsyncStorage.getItem('loggedInUserDetails'));
//     }
//     catch{

//     }
    
//     if(!userDetails  || !userDetails.userId) {
//       return NavigationActions.navigate({
//         routeName: 'AuthLoading',
//       });
//     }
//     else {
//       if (userDetails.shouldChangePassword) {
//         return NavigationActions.navigate({
//           routeName: 'ChangePassword',
//         });
//       }
//       else if (userDetails.isGuestUser) {
//         return NavigationActions.navigate({
//           routeName: 'Guest',
//         });
//       }
//     }
//     return previousGetActionForPathAndParams(path, params);
//   },
// });


// const defaultGetStateForAction = SwitchNavigator.router.getStateForAction;

// SwitchNavigator.router.getStateForAction = async (action, state) => {
  
//   var userDetails =  {};
//   try {
//     const a = await AsyncStorage.getItem('loggedInUserDetails');
//     userDetails = JSON.parse(a);
//   }
//   catch{

//   }
  
//   if(!userDetails  || !userDetails.userId) {
//     return NavigationActions.navigate({
//       routeName: 'AuthLoading',
//     });
//   }
//   else {
//     if (userDetails.shouldChangePassword) {
//       return NavigationActions.navigate({
//         routeName: 'ChangePassword',
//       });
//     }
//     else if (userDetails.isGuestUser) {
//       return NavigationActions.navigate({
//         routeName: 'Guest',
//       });
//     }
//   }
//   // if (
//   //   state &&
//   //   action.type === NavigationActions.BACK &&
//   //   state.routes[state.index].params.isEditing
//   // ) {
//   //   // Returning null from getStateForAction means that the action
//   //   // has been handled/blocked, but there is not a new state
//   //   return null;
//   // }

//   return defaultGetStateForAction(action, state);
// };


export default SwitchNavigator;