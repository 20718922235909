import { StyleSheet, Platform } from "react-native";

const infoTextWidth = Platform.OS == "web" ? '30%' : '100%';
const homePageTextSize = Platform.OS == "web" ? 22 : 15;
const homePageClientWidth = Platform.OS == "web" ? '45%' : '95%';

export default StyleSheet.create({
    infoTextWidth: {
        width: infoTextWidth,
        // flexDirection: 'column',
    },
    modalLinkWrapperView: {
        borderRadius: 10,
        margin: 10,
        backgroundColor: "#e6deca",
        height: 30,
        justifyContent: 'center',
        paddingLeft: 10,
    },
    modalLinkText: {
        color: "#008AFC",
        fontWeight: "bold",
        textDecorationLine: "underline"
    },
    textSize: {fontSize: homePageTextSize },
    homeScreenClientWidth: {width: homePageClientWidth},
    cardBackgroundColor: { backgroundColor: "#FEFCF3"}
});