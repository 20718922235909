import axiosInst from "./axiosHelper";
import * as configHelper from "./configHelper";

const apiBaseUrl = configHelper.getConfigValue('apiBaseUrl');

const defaultConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }};

function get(url, config) {
    config = config || defaultConfig;
    return axiosInst.get(`${apiBaseUrl}${url}`, config)
        .catch((err) => {
            throw err;
        });
}

function post(url ,data, config) {
    console.log(axiosInst.post)
    config = config || defaultConfig;
    return axiosInst.post(`${apiBaseUrl}${url}`, data, config)
        // .catch((err) => {
        //     throw err;
        // });
}

function deleteApi(url, config) {
    config = config || defaultConfig;
    return axiosInst.delete(`${apiBaseUrl}${url}`, config)
        // .catch((err) => {
        //     throw err;
        // });
}

export {
    get,
    post,
    deleteApi,
}