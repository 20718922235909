import React from 'react'
import {View, Text} from 'react-native';
import styles from "./../../../Styles/commonStyles";

export default ({ labelName, labelNameViewStyle, labelNameTextStyle, labelValue, labelValueViewStyle, labelValueTextStyle, wrapperViewStyle, excludeColon }) => (
        <View style={wrapperViewStyle} data-testnode="wrapperView">
            <View style={labelNameViewStyle}>
                <Text style={[labelNameTextStyle || styles.textStyle, styles.boldText]}>{labelName}{excludeColon ? '' : ':'}</Text>
            </View>
            <View style={labelValueViewStyle}>
                <Text style={labelValueTextStyle || styles.textStyle}>{labelValue}</Text>
            </View>
        </View>
        );

