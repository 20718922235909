/**
 * Airbnb Clone App
 * @author: Andy
 * @Url: https://www.cubui.com
 */

import React from 'react';
import {
  View,
  Text,
  Platform,
  TouchableHighlight,
  ActivityIndicator, Image
} from 'react-native';
import { connect } from 'react-redux';
import styles from './../../Admin/styles'
import t from 'tcomb-form-native'
import * as generalHelper from './../../common/generalHelper'
import { userReduxTypes } from "../../../redux/users/user.redux";
import FFPAlert from '../../common/Alert/alert';
import DatePicker from 'react-datepicker'
import * as moment from 'moment'

const Form = t.form.Form;

var ForgotPassword = t.struct({
  email: generalHelper.extTCombCompTypes.emailType,
  //dateOfBirth: t.Date
});
// const myDatePickerTemplate = (locals) => {
//     return <View><Text style={locals.stylesheet.formGroup.normal}>{locals.label}</Text><Text>Some</Text></View>
//   }

var options = {
  fields: {
    dateOfBirth: {
      mode: 'date',
      config: {
        format: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
        defaultValueText: Platform.OS != "web" ? "" : generalHelper.tCombDateFormat(Platform.OS, null)
      }
    }
  }
};



class ForgotPasswordScreen extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    // headerLeft: <NavBarButton
    //   handleButtonPress={() => navigation.goBack()}
    //   location="left"
    //   icon={<Icon name="angle-left" color={colors.white} size={30} />}
    // />,
    // headerStyle: transparentHeaderStyle,
    // headerTransparent: true,
    // headerTintColor: colors.white,
  });


  constructor(props) {
    super(props);
    this.state = {
      isForgotPasswordSuccess: undefined,
    };
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  forgotPassword() {
    /*var userDetails = this.refs.form.getValue();
    if (userDetails) { // if validation fails, value will be null
      this.props.forgotPassword(userDetails, async (resp) => {
        this.setState({ isForgotPasswordSuccess: true })

      });
    }*/
    var userDetails = this.refs.form.getValue();
    var userDetailsToAPI = {...userDetails, dateOfBirth: this.state.Date };
    userDetailsToAPI.dateOfBirth = moment.default(userDetailsToAPI.dateOfBirth).format("MM-DD-YYYY").toString()
    this.props.forgotPassword(userDetailsToAPI, (resp) => {
      this.setState({ isForgotPasswordSuccess: true })
    })
  }

  render() {
    return (


      <View style={{ padding: 50 }}>
        <Image
          source={require('../../common/assets/logo.png')
          }
          style={{ width: 375, height: 200, alignSelf: 'center' }}
        />
        <Text style={{ textAlign: 'center' }}>Please provide the email and date of birth associated with your account. On submitting,
      we will send a temporary password to that email if it exists as a user in Foster Family Portal. Login with the temporary password and you'll be prompted to create a new password.</Text>

        <Form
          ref="form"
          type={ForgotPassword}
          options={options}
          value={this.state.value}
          onChange={(value) => generalHelper.onFormChange(value, this)}
        />
        <View style={{ paddingBottom: 15 }}>
          <Text style={{ fontSize: 17, fontWeight: '500', paddingBottom: 5 }}>Date of Birth</Text>
          <DatePicker placeholderText="Click to select a date" selected={this.state.Date} peekNextMonth
            showMonthDropdown
            showYearDropdown onChange={date => this.setState({ Date: date })} />
        </View>

        <TouchableHighlight style={styles.button} onPress={this.forgotPassword}
          underlayColor='#99d9f4' disabled={this.props.isForgotPasswordInProgress}>
          <Text style={styles.buttonText}>
            Retrieve Password
          </Text>
        </TouchableHighlight>
        <TouchableHighlight style={styles.button} onPress={() => { this.props.navigation.goBack() }}
          underlayColor='#99d9f4' >
          <Text style={styles.buttonText}>
            Back
            </Text>
        </TouchableHighlight>
        {this.props.isForgotPasswordInProgress && <ActivityIndicator />}
        {this.props.errorForgotPassword &&
          <Text style={{ color: 'red' }}>
            {generalHelper.getErrorMessageFromErrorResp(this.props.errorForgotPassword, 'Error Adding User. Please contact administrator.')}
          </Text>}

        {this.state && this.state.isForgotPasswordSuccess === true &&
          <FFPAlert title="Success" desc="Successfully Reset password. Please refer to the email for Login details."
            onPositivePress={() => { this.setState({ isForgotPasswordSuccess: undefined }); this.props.navigation.navigate('SignIn') }}
            onNegativePress={() => { }}
          />
        }
      </View>

    );
  }
}


const mapStateToProps = (state) => {
  return {
    isForgotPasswordInProgress: userReduxTypes.selectors.getIsForgotPasswordInProgress(state),
    errorForgotPassword: userReduxTypes.selectors.getErrorForgotPassword(state),
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (userDetails, callback) => userReduxTypes.actions.forgotPassword(dispatch, userDetails, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen)
