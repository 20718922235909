import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import {connect} from 'react-redux';

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);

    this.onPress = this.onPress.bind(this);

    
  }
  
    onPress() {
      // call getValue() to get the values of the form
      var value = this.refs.form.getValue();
      if (value) { // if validation fails, value will be null
        console.log(value); // value here is an instance of Person
      }
    }
  
    render() {
      

      // const Container = createAppContainer(Tabs);
      return (
        <View style={styles.container}>
          {/* display */}
           
         <Text> Home screen</Text>
        </View>)
      ;
    }
}

const mapStateToProps = (state) => {
  return {
      userDetails: userAuthReduxTypes.selectors.getAuthDetails(state)
  };
};

export default connect(mapStateToProps)(HomeScreen);

  
var styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    marginTop: 50,
    padding: 20,
    backgroundColor: '#ffffff',
  }
});