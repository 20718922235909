
import autodux from "autodux";
import * as apiHelper from "../../features/common/apiHelper";
// import {join, o, adjust, toUpper} from 'ramda'
import * as helper from "../../features/common/generalHelper";
import * as reduxHelper from '../helper'


// const autodux = () => {return {actions: {}, selectors: {}, reducer: (state, action) => {return state || {}}}}


const initialState = {
    allRoles: [],
    isFetchingAllRoles: false,
    errorFetchingAllRoles: false,
    saveRoleSuccessResponse: undefined,
    isSaveRoleInProgress: false,
    errorSavingRole: false,
    deleteRoleSuccessResponse: undefined,
    isDeleteRoleInProgress: false,
    errorDeletingRole: false,

  }
const roleReduxTypes = autodux({
    slice: 'roleReducer',
    initial: initialState
  })

  roleReduxTypes.actions = {
    ...roleReduxTypes.actions,
    fetchAllRoles: (dispatch, requestParams, successCallback, failureCallBack) => {
        const getAllRolesUrl = '/roles/all';
        const apiFunc = (getAllRolesUrl, requestParams) => () => apiHelper.get(getAllRolesUrl, {params: requestParams})
        reduxHelper.asyncActionGenerator(dispatch, 
            apiFunc(getAllRolesUrl, requestParams), 
            successCallback, 
            failureCallBack,
            roleReduxTypes.actions,
            helper.getPropertyName(() =>  initialState.allRoles),
            "data.roles",
            helper.getPropertyName(() => initialState.isFetchingAllRoles),
            helper.getPropertyName(() => initialState.errorFetchingAllRoles)
        )
        
    },
    saveRole: (dispatch, roleDetails, successCallback, failureCallBack) => {
        const saveRoleUrl = `/roles/save/${roleDetails.roleId || ''}`;
        const apiFunc = (saveRoleUrl, roleDetails) => () => apiHelper.post(saveRoleUrl, roleDetails)
        reduxHelper.asyncActionGenerator(dispatch, 
            apiFunc(saveRoleUrl, roleDetails), 
            successCallback, 
            failureCallBack,
            roleReduxTypes.actions,
            helper.getPropertyName(() =>  initialState.saveRoleSuccessResponse),
            "",
            helper.getPropertyName(() => initialState.isSaveRoleInProgress),
            helper.getPropertyName(() => initialState.errorSavingRole)
        )
        
    },
    deleteRole: (dispatch, roleDetails, successCallback, failureCallBack) => {
        const deleteRoleUrl = `/roles/${roleDetails.roleId || ''}`;
        const apiFunc = (deleteRoleUrl, roleDetails) => () => apiHelper.deleteApi(deleteRoleUrl)
        
        reduxHelper.asyncActionGenerator(dispatch, 
            apiFunc(deleteRoleUrl, roleDetails), 
            successCallback, 
            failureCallBack,
            roleReduxTypes.actions,
            helper.getPropertyName(() =>  initialState.deleteRoleSuccessResponse),
            "",
            helper.getPropertyName(() => initialState.isDeleteRoleInProgress),
            helper.getPropertyName(() => initialState.errorDeletingRole)
        )
        
    }
}


  export {roleReduxTypes}
  