import { StyleSheet, Platform } from 'react-native';
export default StyleSheet.create({
    baseContainer: {
        flex: 1,
        //justifyContent: 'center',
        alignItems: 'center'
    },
    buttonBorderContainer: {
        padding: 5,
        //justifyContent: 'left',
        alignItems: 'center',
        height: 50,
        flexWrap: 'wrap',
        flexDirection: 'row',
        borderRadius: 5,
        borderColor: "bisque",
        borderBottomWidth: 1,
        borderTopWidth: 1
        //margin: 2

    },
    buttonBorderContainer2: {
        alignItems: 'center',
        borderWidth: 1,
        height: 50,
        width: 100,
        flexDirection: 'row',
        backgroundColor: 'teal'
    },
    card1: {
        borderWidth: 1, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 2, maxWidth: 422
    },
    card2: {
        borderWidth: 1, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 2
    },
    h1: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        fontSize: Platform.OS == "web" ? 30 : 15,
        fontWeight: 'bold',
        margin: 10
    },
    h1v: {
        borderStyle: 'solid',
        borderColor: '#008080',
        borderBottomWidth: 2,
        margin: 10
    },
    h2b: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        //borderBottomWidth: 2,
        fontSize: 26,
        fontWeight: 'bold'
    },
    h2: {
        textAlign: 'left',
        borderStyle: 'solid',
        borderColor: '#008080',
        //borderBottomWidth: 2,
        fontSize: 20,
        fontWeight: 'bold',
        color: '#008080'
    },
    h2c:
    {
        textAlign: 'center',
        borderStyle: 'solid',
        color: '#008080',
        //borderBottomWidth: 2,
        fontSize: 20,
        fontWeight: 'bold'
    },
    h3: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        //borderBottomWidth: 2,
        fontSize: 20
    },
    docText:
    {
        color: 'white'
    },
    buttonText0:
    {
        textAlign: 'center',
        fontWeight: "500",
    },
    button0:
    {
        padding: 10,
        margin: 5,
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: "#008080",
        borderRadius: 10,
    },
    button1:
    {
        borderRadius: 5,
        padding: 5,
        fontSize: 17,
        fontWeight: "bold",
        color: "white",
        backgroundColor: "teal"
    },
    button2:
    {
        padding: 10,
        margin: 5,
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: "#D3D3D3",
        borderRadius: 10,
    }

})