
import autodux from "autodux";
import * as apiHelper from "../../features/common/apiHelper";
// import {join, o, adjust, toUpper} from 'ramda'
import * as helper from "../../features/common/generalHelper";
import * as reduxHelper from '../helper'


// const autodux = () => {return {actions: {}, selectors: {}, reducer: (state, action) => {return state || {}}}}


const initialState = {
    allUsers: [],
    isFetchingAllUsers: false,
    errorFetchingAllUsers: false,
    saveUserSuccessResponse: undefined,
    isSaveUserInProgress: false,
    errorSavingUser: false,
    deleteUserSuccessResponse: undefined,
    isDeleteUserInProgress: false,
    errorDeletingUser: false,
    changePasswordResponse: undefined,
    isChangePasswordInProgress: false,
    errorChangingPassword: false,
    forgotPasswordResponse: undefined,
    isForgotPasswordInProgress: false,
    errorForgotPassword: false,
    signupUserResponse: undefined,
    isSignupInProgress: false,
    errorSignup: false,
}
const userReduxTypes = autodux({
    slice: 'userReducer',
    initial: initialState
})

userReduxTypes.actions = {
    ...userReduxTypes.actions,
    fetchAllUsers: (dispatch, requestParams, successCallback, failureCallBack) => {
        const getAllUsersUrl = '/users/all';
        const apiFunc = (getAllUsersUrl, requestParams) => () => apiHelper.get(getAllUsersUrl, { params: requestParams })
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(getAllUsersUrl, requestParams),
            successCallback,
            failureCallBack,
            userReduxTypes.actions,
            helper.getPropertyName(() => initialState.allUsers),
            "data.data.users",
            helper.getPropertyName(() => initialState.isFetchingAllUsers),
            helper.getPropertyName(() => initialState.errorFetchingAllUsers)
        )

    },
    signupUser: (dispatch, userDetails, successCallback, failureCallBack) => {
        const signupUrl = `/users/signup`;
        const apiFunc = (signupUrl, userDetails) => () => apiHelper.post(signupUrl, userDetails, { skipToken: true })
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(signupUrl, userDetails),
            successCallback,
            failureCallBack,
            userReduxTypes.actions,
            helper.getPropertyName(() => initialState.signupUserResponse),
            "data",
            helper.getPropertyName(() => initialState.isSignupInProgress),
            helper.getPropertyName(() => initialState.errorSignup)
        )

    },
    saveUser: (dispatch, userDetails, successCallback, failureCallBack, isSignup) => {
        const saveUserUrl = isSignup ? `/users/signup` : `/users/save/${userDetails.userId || ''}`;
        const apiFunc = (saveUserUrl, userDetails) => () => apiHelper.post(saveUserUrl, userDetails)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(saveUserUrl, userDetails),
            successCallback,
            failureCallBack,
            userReduxTypes.actions,
            helper.getPropertyName(() => initialState.saveUserSuccessResponse),
            "data",
            helper.getPropertyName(() => initialState.isSaveUserInProgress),
            helper.getPropertyName(() => initialState.errorSavingUser)
        )

    },
    resetSaveUserResponse: (dispatch) => {
        userReduxTypes.actions.setSaveUserSuccessResponse(undefined)
    },
    deleteUser: (dispatch, userDetails, successCallback, failureCallBack) => {
        const deleteUserUrl = `/users/${userDetails.userId || ''}`;
        const apiFunc = (deleteUserUrl, userDetails) => () => apiHelper.deleteApi(deleteUserUrl)

        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(deleteUserUrl, userDetails),
            successCallback,
            failureCallBack,
            userReduxTypes.actions,
            helper.getPropertyName(() => initialState.deleteUserSuccessResponse),
            "data",
            helper.getPropertyName(() => initialState.isDeleteUserInProgress),
            helper.getPropertyName(() => initialState.errorDeletingUser)
        )

    },

    changePassword: (dispatch, passwordDetails, successCallback, failureCallBack) => {
        const changePasswordUrl = `/users/change-password`;
        const apiFunc = (changePasswordUrl, userDetails) => () => apiHelper.post(changePasswordUrl, userDetails, { skipToken: true })

        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(changePasswordUrl, passwordDetails),
            successCallback,
            failureCallBack,
            userReduxTypes.actions,
            helper.getPropertyName(() => initialState.changePasswordResponse),
            "",
            helper.getPropertyName(() => initialState.isChangePasswordInProgress),
            helper.getPropertyName(() => initialState.errorChangingPassword)
        )

    },
    forgotPassword: (dispatch, userDetails, successCallback, failureCallBack) => {
        const forgotPasswordUrl = `/users/forgot-password`;
        const apiFunc = (forgotPasswordUrl, userDetails) => () => apiHelper.post(forgotPasswordUrl, userDetails)

        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(forgotPasswordUrl, userDetails),
            successCallback,
            failureCallBack,
            userReduxTypes.actions,
            helper.getPropertyName(() => initialState.forgotPasswordResponse),
            "data",
            helper.getPropertyName(() => initialState.isForgotPasswordInProgress),
            helper.getPropertyName(() => initialState.errorForgotPassword)
        )

    },
}


export { userReduxTypes }
