
import autodux from "autodux";
import * as apiHelper from "../../features/common/apiHelper";
// import {join, o, adjust, toUpper} from 'ramda'
import * as helper from "../../features/common/generalHelper";
import * as reduxHelper from '../helper'

// const autodux = () => {return {actions: {}, selectors: {}, reducer: (state, action) => {return state || {}}}}


const initialState = {
    downloadCurrentPlacementResponse: {},
    isFetchingCurrentPlacementDoc: false,
    errorFetchingCurrentPlacementDoc: false,
    uploadCurrentPlacementResponse: {},
    isUploadingCurrentPlacementDoc: false,
    errorUploadingCurrentPlacementDoc: false,
    downloadDefClientUploadCurrPlDoc: {},
    isFetchingDefClientUploadCurrPlDoc: false,
    errorFetchingDefClientUploadCurrPlDoc: false,

}
const fileUploadDownloadReduxTypes = autodux({
    slice: 'fileUploadDownloadReducer',
    initial: initialState
})

fileUploadDownloadReduxTypes.actions = {
    ...fileUploadDownloadReduxTypes.actions,
    uploadCurrentPlacementDocument: (dispatch, requestParams, successCallback, failureCallBack) => {
        const uploadCurrentPlacementDocUrl = '/document/upload/current-placements';
        const apiFunc = (uploadCurrentPlacementDocUrl, requestParams) => () => apiHelper.post(uploadCurrentPlacementDocUrl, requestParams)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(uploadCurrentPlacementDocUrl, requestParams),
            successCallback,
            failureCallBack,
            fileUploadDownloadReduxTypes.actions,
            helper.getPropertyName(() => initialState.uploadCurrentPlacementResponse),
            "data",
            helper.getPropertyName(() => initialState.isUploadingCurrentPlacementDoc),
            helper.getPropertyName(() => initialState.errorUploadingCurrentPlacementDoc)
        )

    },
    downloadCurrentPlacementDocument: (dispatch, requestParams, config, successCallback, failureCallBack) => {
        const downloadCurrentPlacementDocUrl = '/document/download/current-placements';
        const apiFunc = (downloadCurrentPlacementDocUrl, requestParams) => () => apiHelper.post(downloadCurrentPlacementDocUrl, requestParams, config)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(downloadCurrentPlacementDocUrl, requestParams),
            successCallback,
            failureCallBack,
            fileUploadDownloadReduxTypes.actions,
            helper.getPropertyName(() => initialState.downloadCurrentPlacementResponse),
            "data",
            helper.getPropertyName(() => initialState.isFetchingCurrentPlacementDoc),
            helper.getPropertyName(() => initialState.errorFetchingCurrentPlacementDoc)
        )

    },
    downloadDefaultCurrentPlacementClientUploadDocument: (dispatch, requestParams, config, successCallback, failureCallBack) => {
        const downloadCurrentPlacementClientUploadDefaultDocUrl = '/document/download/current-placements/upload-type/default';
        const apiFunc = (downloadCurrentPlacementClientUploadDefaultDocUrl, requestParams) => () => apiHelper.post(downloadCurrentPlacementClientUploadDefaultDocUrl, requestParams, config)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(downloadCurrentPlacementClientUploadDefaultDocUrl, requestParams),
            successCallback,
            failureCallBack,
            fileUploadDownloadReduxTypes.actions,
            helper.getPropertyName(() => initialState.downloadDefClientUploadCurrPlDoc),
            "data",
            helper.getPropertyName(() => initialState.isFetchingDefClientUploadCurrPlDoc),
            helper.getPropertyName(() => initialState.errorFetchingDefClientUploadCurrPlDoc)
        )
    },
}


export { fileUploadDownloadReduxTypes }
