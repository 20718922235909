import React from "react";
import { View, Text, TouchableOpacity, ActivityIndicator, SafeAreaView, ScrollView, Dimensions, Platform, FlatList, KeyboardAvoidingView } from "react-native";
import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import { connect } from 'react-redux';
import styles from './AppStyles';
import * as configHelper from "./../common/configHelper";
import maskedInputTemplate from "./../common/maskedInputTemplate";
import t from 'tcomb-form-native'
import * as moment from 'moment'
import * as generalHelper from '../common/generalHelper'
import * as apiHelper from '../common/apiHelper'
import { NavigationContainer } from '@react-navigation/native';
import { values } from "pdf-lib";
const apiBaseUrl = configHelper.getConfigValue('apiBaseUrl');

const Form = t.form.Form;
//t.form.Form.i18n.required = ' *'
Form.i18n.optional = ''
Form.i18n.required = ' *'
const dateFormat = (format, date) => {
    console.log(moment(date).format(format))
    return moment(date).format(format)
}
const Email = t.refinement(t.String, email => {
    const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return reg.test(email);
});
const extendedFormGroupStyle = {
    ...Form.stylesheet,
    formGroup: {
        ...Form.stylesheet.formGroup,
        normal: {
            ...Form.stylesheet.formGroup.normal,
            zIndex: -1,
        },
        error: {
            ...Form.stylesheet.formGroup.error,
            zIndex: -1
        },
    },
    controlLabel: {
        ...Form.stylesheet.controlLabel,
        normal: {
            ...Form.stylesheet.controlLabel.normal,
            zIndex: -1
        },
        error: {
            ...Form.stylesheet.controlLabel.error,
            zIndex: -1
        },
    }
};
var formKS = [
    t.struct({
        //todaysDate: t.Date,
        personCompletingQuestionnaire: t.String,
        familyInterest: t.String,
        referral: t.String,
        app1Name: t.String,
        app1Gender: t.String,
        app1Cell: t.String,
        app1Birthday: t.Date,
        app1Email: Email,
        app1ContactTime: t.String,
    }), t.struct({
        app2Name: t.maybe(t.String),
        app2Gender: t.maybe(t.String),
        app2Cell: t.maybe(t.String),
        app2Birthday: t.maybe(t.Date),
        app2Email: t.maybe(Email),
        //app2SSN: t.maybe(t.String),
        app2ContactTime: t.maybe(t.String),
    }), t.struct({
        address: t.String,
        maritalStatus: t.String,
        homePhone: t.String,
        otherStates: t.maybe(t.String),
        noOfBedrooms: t.Integer,
        noOfChildrenToAdopt: t.String,
        //ageOfChildrenToAdopt: t.Integer,
        typeOfHome: t.String,
        typeOfHome_OtherText: t.maybe(t.String),
        pets: t.String,
        pets_YesDescription: t.maybe(t.String)
    }),
    t.struct({
        conviction: t.String,
        convictionDetails: t.maybe(t.String),
        daycare: t.String,
        TIPS: t.String,
        TIPSDetails: t.maybe(t.String),
        licenseStatus: t.String,
        licenseAgency: t.maybe(t.String),
        reasonTransferring: t.maybe(t.String),
        licenseHistory: t.String,
        //oldAgency: t.maybe(t.String),
        trainingAvailability: t.maybe(t.String),
        trainingAvailability_reason: t.maybe(t.String)
    }),
    t.struct({
        adlt1_Name: t.maybe(t.String),
        adlt1_Age: t.maybe(t.Number),
        adlt1_Gender: t.maybe(t.String),
        adlt2_Name: t.maybe(t.String),
        adlt2_Age: t.maybe(t.Number),
        adlt2_Gender: t.maybe(t.String),
        adlt3_Name: t.maybe(t.String),
        adlt3_Age: t.maybe(t.Number),
        adlt3_Gender: t.maybe(t.String),
        adlt4_Name: t.maybe(t.String),
        adlt4_Age: t.maybe(t.Number),
        adlt4_Gender: t.maybe(t.String),
        adlt5_Name: t.maybe(t.String),
        adlt5_Age: t.maybe(t.Number),
        adlt5_Gender: t.maybe(t.String),
        adlt6_Name: t.maybe(t.String),
        adlt6_Age: t.maybe(t.Number),
        adlt6_Gender: t.maybe(t.String),
        adlt7_Name: t.maybe(t.String),
        adlt7_Age: t.maybe(t.Number),
        adlt7_Gender: t.maybe(t.String),
        adlt8_Name: t.maybe(t.String),
        adlt8_Age: t.maybe(t.Number),
        adlt8_Gender: t.maybe(t.String),
    }),
    t.struct({
        //otherResidents: t.String,
        specificChild: t.String,
        specificChildRelationship: t.maybe(t.String),
        additionalComments: t.maybe(t.String)
    })]
var formOK =
    [
        t.struct({
            //todaysDate: t.Date,
            //personCompletingQuestionnaire: t.String,
            //familyInterest: t.String,

            referral: t.String,
            app1Name: t.String,
            app1Gender: t.String,
            app1Cell: t.String,
            app1Birthday: t.Date,
            app1Email: Email,
            app1SSN: t.String
            //app1ContactTime: t.String,
        }), t.struct({
            app2Name: t.maybe(t.String),
            app2Gender: t.maybe(t.String),
            app2Cell: t.maybe(t.String),
            app2Birthday: t.maybe(t.Date),
            app2Email: t.maybe(Email),
            app2SSN: t.maybe(t.String)
            //app2ContactTime: t.maybe(t.String),
        }), t.struct({
            address: t.String,
            addressCityState: t.String,
            addressZip: t.String,
            addressCounty: t.String,
            maritalStatus: t.String,
            monthlyHouseholdIncome: t.String,
            //homePhone: t.String,
            livedInOklahoma: t.String,
            otherStateResidence: t.maybe(t.String),
            noOfBedrooms: t.Integer,
            noOfChildrenToAdopt: t.String,
            retireFromMilitary: t.String,
            currentlyInMilitary: t.String,
            militaryBranch: t.maybe(t.String),
            //ageOfChildrenToAdopt: t.Integer,
            //typeOfHome: t.String,
            //typeOfHome_OtherText: t.maybe(t.String),
            pets: t.String,
            pets_YesDescription: t.maybe(t.String)
        }),
        t.struct({
            conviction: t.String,
            convictionDetails: t.maybe(t.String),
            daycare: t.String,
            guidedPrincipalTraining: t.String,
            appliedForDHS: t.String,
            appliedForDHSDetail: t.maybe(t.String),
            guidedPrincipalTrainingDetails: t.maybe(t.String),
            isCurrOpenHome: t.String,
            currentOpenHomeRsn: t.maybe(t.String),


        }),
        t.struct({
            adlt1_Name: t.maybe(t.String),
            adlt1_Age: t.maybe(t.Number),
            adlt1_Gender: t.maybe(t.String),
            adlt2_Name: t.maybe(t.String),
            adlt2_Age: t.maybe(t.Number),
            adlt2_Gender: t.maybe(t.String),
            adlt3_Name: t.maybe(t.String),
            adlt3_Age: t.maybe(t.Number),
            adlt3_Gender: t.maybe(t.String),
            adlt4_Name: t.maybe(t.String),
            adlt4_Age: t.maybe(t.Number),
            adlt4_Gender: t.maybe(t.String),
            adlt5_Name: t.maybe(t.String),
            adlt5_Age: t.maybe(t.Number),
            adlt5_Gender: t.maybe(t.String),
            adlt6_Name: t.maybe(t.String),
            adlt6_Age: t.maybe(t.Number),
            adlt6_Gender: t.maybe(t.String),
            adlt7_Name: t.maybe(t.String),
            adlt7_Age: t.maybe(t.Number),
            adlt7_Gender: t.maybe(t.String),
            adlt8_Name: t.maybe(t.String),
            adlt8_Age: t.maybe(t.Number),
            adlt8_Gender: t.maybe(t.String),
        })]
var formTX =
    [
        t.struct({
            applicantInfo: t.String,
            famInterest: t.String,
            famReadiness: t.String,
            famReadinessDetail: t.String
        }), t.struct({
            app1Name: t.String,
            app1NameAlias: t.maybe(t.String),
            app1Gender: t.String,
            app1ConNum: t.String,
            app1Email: Email,
            app2Name: t.maybe(t.String),
            app2NameAlias: t.maybe(t.String),
            app2Gender: t.maybe(t.String),
            app2ConNum: t.maybe(t.String),
            app2Email: t.maybe(Email),
        }), t.struct({
            address: t.String,
            city: t.String,
            state: t.String,
            zip: t.String,
            county: t.String,
        }),
        t.struct({
            isFosParTrainCompleted: t.String,
            fosParTrainCompletedAgency: t.maybe(t.String),
            isCurrLicensed: t.String,
            currLicAgency: t.maybe(t.String),
            isPrevLicensed: t.String,
            prevLicAgency: t.maybe(t.String),
            isdayCare: t.String,
        }),
        t.struct({
            isLicSpecificChild: t.String,
            licSpcChldRelation: t.maybe(t.String),
            isBothAppl21Old: t.String,
            heardFromAgencyEvent: t.String,
            agencyEventTown: t.String,
        }),
        t.struct({
            comments: t.String,
        })]


class FormFiller extends React.Component {
    constructor(props) {
        super(props);
        //this.openClientDetails = this.openClientDetails.bind(this);
        //this.updateInterest = this.updateInterest.bind(this);
        let options = [{
            //1
            fields: {
                todaysDate: {
                    mode: 'date',
                    error: '* required',
                    stylesheet: {
                        zIndex: 2,
                        ...Form.stylesheet,
                        datepicker: {
                            ...Form.stylesheet.datepicker,
                            zIndex: 2,
                            normal: {
                                ...Form.stylesheet.datepicker.normal,
                                backgroundColor: 'red',
                                zIndex: 2
                            },
                        }
                    },
                    placeholder: "test",
                    config: {
                        dateFormat: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
                        format: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
                        // format: (date) => {
                        //     if (Platform.OS === 'web') {
                        //         return new Date(date);
                        //     }
                        //     else {
                        //         return moment.default(date).format("MM-DD-YYYY").toString()

                        //     }
                        // },
                        defaultValueText: Platform.OS === 'web' ? new Date() : ''//moment.default(new Date()).format("MM-DD-YYYY").toString()
                    },
                },
                familyInterest: {
                    options: [
                        { text: "Foster Care", value: "FosterCare" },
                        { text: "Adoption", value: "Adoption" },
                        { text: "Both", value: "Both" },
                    ],
                    text: "Foster Parent",
                    factory: t.form.Select,
                    label: 'Family is interested in',
                    error: '* required',
                    stylesheet: extendedFormGroupStyle,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                referral: {
                    options: [
                        { text: "Agency Event", value: "AgencyEvt" },
                        { text: "Bulletin/Flyer", value: "Bulletin" },
                        { text: "Media", value: "Media" },
                        { text: "Resource Parent", value: "ResPrnt" },
                        { text: "Other Agency", value: "OtherAg" },
                        { text: "Website", value: "Website" },
                        { text: "Ambassador", value: "Ambassador" },
                        { text: "Other", value: "Other" }
                    ],
                    text: "Foster Parent",
                    factory: t.form.Select,
                    label: 'How did you hear about us?',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    stylesheet: extendedFormGroupStyle
                },
                phoneNumber: {
                    error: '* required'
                },
                personCompletingQuestionnaire: {
                    error: "* required",
                    stylesheet: extendedFormGroupStyle
                },
                lastName: {
                    error: "* required"
                },
                // loginName: t.String, 
                // userUniqueCode: t.String,
                email: {
                    error: "* enter a valid email"
                },
                app1Name: {
                    label: "Applicant 1 Name (Last First MI)"
                },
                app1Birthday: {
                    label: 'Applicant 1 Birthday',
                    mode: 'date',
                    error: "* required",
                    order: ['D', 'M', 'YY'],
                    config: {
                        dateFormatWeb: "MM-dd-yyyy",
                        dateFormat: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
                        format: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
                        defaultValueText: Platform.OS === 'web' ? new Date("01/01/1970") : ''//moment.default(new Date()).format("MM-DD-YYYY").toString()
                    },
                },
                familyInterest: {
                    options: [
                        { text: "Foster Care", value: "FosterCare" },
                        { text: "Adoption", value: "Adoption" },
                        { text: "Both", value: "Both" },
                    ],
                    text: "Foster Parent",
                    factory: t.form.Select,
                    label: 'Family is interested in',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                app1Cell: {
                    label: "Applicant 1 Cell Phone #",
                    error: "* required",
                    stylesheet: extendedFormGroupStyle,
                    template: maskedInputTemplate,
                    config: generalHelper.extTCombCompTypes.cellMaskedFormatConfig,
                },
                app1Email: {
                    label: "Applicant 1 Email",
                    error: "* enter a valid email",
                    stylesheet: extendedFormGroupStyle
                },
                app1ContactTime: {
                    error: "* required",
                    label: "Best time to contact",
                    stylesheet: extendedFormGroupStyle
                },
                app1Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    error: "* required",
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Applicant 1 Gender"
                },
                app1SSN: {
                    error: "* required",
                    label: "Applicant 1 Social Security Number",
                    stylesheet: extendedFormGroupStyle
                },
                applicantInfo: {
                    options: [
                        { text: "Single", value: "Single" },
                        { text: "Married", value: "Married" }
                    ],
                    text: "select",
                    factory: t.form.Select,
                    label: "Applicant's marital status",
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                famInterest: {
                    options: [
                        { text: "Foster Care", value: "FosterCar" },
                        { text: "Adoption", value: "Adoption" },
                        { text: "Both", value: "Both" },
                    ],
                    text: "Foster Parent",
                    factory: t.form.Select,
                    label: 'Family is interested in',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                famReadiness: {
                    options: [
                        { text: "Ready to Apply", value: "ReadyToApply" },
                        { text: "Info Only", value: "InfoOnly" }
                    ],
                    text: "Foster Parent",
                    factory: t.form.Select,
                    label: 'Family readiness',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                famReadinessDetail: {
                    options: [
                        { text: "Days", value: "Days" },
                        { text: "Months", value: "Months" }
                    ],
                    factory: t.form.Select,
                    label: 'If info only, follow up on request in',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                }
            }
        }, {
            fields: {
                //2
                app2Name: {
                    label: "Applicant 2 Name (Last First MI)",
                    error: "* required",
                },
                app1Name: {
                    label: "Applicant 1 Name (Last First MI)",
                    error: "* required",
                },
                app2Birthday: {
                    label: 'Applicant 2 Birthday',
                    mode: 'date',
                    error: '* required',
                    config: {
                        dateFormatWeb: "MM-dd-yyyy",
                        dateFormat: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
                        format: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
                        defaultValueText: Platform.OS === 'web' ? new Date("01/01/1970") : ''//moment.default(new Date()).format("MM-DD-YYYY").toString()
                    },
                },
                app2Cell: {
                    label: "Applicant 2 Cell Phone #",
                    error: "* required",
                    stylesheet: extendedFormGroupStyle,
                    template: maskedInputTemplate,
                    config: generalHelper.extTCombCompTypes.cellMaskedFormatConfig,
                },
                app2Email: {
                    label: "Applicant 2 Email",
                    error: "* enter a valid email",
                    stylesheet: extendedFormGroupStyle
                },
                app2ContactTime: {
                    label: "Best time to contact",
                    error: "* required",
                    stylesheet: extendedFormGroupStyle
                },
                app1NameAlias: {
                    label: "Applicant 1 Also Known As or Alias",
                    error: "* required"
                },
                app1ConNum: {
                    label: "Applicant 1 Cell Phone # ",
                    error: "* required",
                    //stylesheet: extendedFormGroupStyle,
                    //template: maskedInputTemplate,
                    //config: generalHelper.extTCombCompTypes.cellMaskedFormatConfig
                },
                app1Email: {
                    label: "Applicant 1 Email",
                    error: "* enter a valid email",
                    stylesheet: extendedFormGroupStyle
                },
                app1Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    error: "* required",
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Applicant 1 Gender"
                },
                app2NameAlias: {
                    label: "Best time to contact",
                    stylesheet: extendedFormGroupStyle
                },
                app2ConNum: {
                    label: "Applicant 2 Cell Phone #",
                    error: "* required",
                    stylesheet: extendedFormGroupStyle,
                    template: maskedInputTemplate,
                    config: generalHelper.extTCombCompTypes.cellMaskedFormatConfig
                },
                app2SSN: {
                    error: "* required",
                    label: "Applicant 2 Social Security Number",
                    stylesheet: extendedFormGroupStyle
                },
                app2Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    error: "* required",
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Applicant 2 Gender"
                }
            }
        }, {
            //3
            fields: {
                address: {
                    label: "Address (Street, City, State, Zipcode)",
                    error: "* required"
                },
                addressCityState: {
                    label: "City, State",
                    error: "* required"
                },
                addressCounty: {
                    label: "County",
                    error: "* required"
                },
                addressZip: {
                    label: "Zip Code",
                    error: "* required"
                },
                maritalStatus: {
                    options: [
                        { text: "Married", value: "Married" },
                        { text: "Single", value: "Single" },
                        { text: "Separated", value: "Separated" },
                        { text: "Divorced", value: "Divorced" },
                        { text: "Widowed", value: "Widowed" },
                        { text: "Partnership", value: "Partnership" }
                    ],
                    factory: t.form.Select,
                    label: 'Marital Status',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },

                maritalStatus: {
                    options: [
                        { text: "Married", value: "Married" },
                        { text: "Single", value: "Single" },
                        { text: "Separated", value: "Separated" },
                        { text: "Divorced", value: "Divorced" },
                        { text: "Widowed", value: "Widowed" },
                        { text: "Partnership", value: "Partnership" }
                    ],
                    factory: t.form.Select,
                    label: 'Marital Status',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                otherStates: {
                    label: "If you have lived in Kansas for less than 5 years, please list other states of residence"
                },
                noOfBedrooms: {
                    label: "Number of bedrooms in your home",
                    error: "* required"
                },
                noOfChildrenToAdopt: {
                    label: "Number of children you'd like to foster or adopt",
                    error: "* required"
                },
                ageOfChildrenToAdopt: {
                    label: "Preferred age of children you'd be interested in adopting",
                    error: "* required"
                },
                typeOfHome: {
                    options: [
                        { text: "House", value: "House" },
                        { text: "Apartment", value: "Aptmnt" },
                        { text: "Mobile Home", value: "MobHom" },
                        { text: "Other", value: "Other" },
                    ],
                    factory: t.form.Select,
                    label: 'Home type',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                typeOfHome_OtherText: {
                    label: 'Type of home (if other)'
                },
                pets: {
                    nullOption: { value: '', text: 'Select' },
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    label: 'Do you have pets?',
                },
                pets_YesDescription: {
                    label: 'If yes, please describe'
                },
                retireFromMilitary: {
                    nullOption: { value: '', text: 'Select' },
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    label: 'Ever retired or discharged from the military?',
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    }
                },
                currentlyInMilitary: {
                    nullOption: { value: '', text: 'Select' },
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    label: 'Currently in the Military?',
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    }
                },
                militaryBranch: {
                    label: 'If yes, what military branch and date of discharge, if applicable?',
                    error: '* required',
                },
                otherStateResidence: {
                    label: 'If yes, list other states of residence:'
                },
                livedInOklahoma:
                {
                    label: 'Have you lived in Oklahoma for less than 5 years?',
                    nullOption: { value: '', text: 'Select' },
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    }
                }

            }
        }, {
            //4
            fields: {

                isCurrLicensed: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" }
                    ],
                    factory: t.form.Select,
                    label: 'Currently licensed/certified/verified?',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                currLicAgency: {
                    label: 'If yes, which agency?'
                },
                isPrevLicensed: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" }
                    ],
                    factory: t.form.Select,
                    label: 'Previously licensed/certified/verified?',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                prevLicAgency: {
                    label: 'If yes, which agency?'
                },
                isdayCare: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" }
                    ],
                    factory: t.form.Select,
                    label: 'Licensed or registered daycare in the home?',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                daycare: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    label: 'Do you have a licensed daycare in the home in which you are currently living?',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                conviction: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    label: 'Has anyone in your household been convicted of a crime?',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                convictionDetails: {
                    label: "If convicted, describe the crime(s), the date and location of the crime(s), and the name of the convicting court",
                    multiline: true,
                    stylesheet: {
                        ...Form.stylesheet,
                        textbox: {
                            ...Form.stylesheet.textbox,
                            normal: {
                                ...Form.stylesheet.textbox.normal,
                                height: 150
                            },
                            error: {
                                ...Form.stylesheet.textbox.error,
                                height: 150
                            }
                        }
                    }
                },
                TIPS: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        console.log(value)
                        this.setState({ value: value, test: "TEST" })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Have you ever taken TIPS-MAPP/DT Training"
                },
                TIPSDetails: {
                    label: "If yes, when & what agency?",
                },
                licenseStatus: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Are you currently licensed?"
                },
                licenseHistory: {
                    label: "Were you previously licensed with another agency?",
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                licenseAgency: {
                    label: "If yes, what agency?"
                },
                reasonTransferring: {
                    label: "Reason for transferring?"
                },
                oldAgency: {
                    label: "If yes, what agency?"
                },
                trainingAvailability: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "If not trained, able to attend evening classes weekly?"
                },
                trainingAvailability_reason: {
                    label: "If no, when are you available for training?"
                },
                guidedPrincipalTraining: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Have you ever taken Guiding Principles Training?"
                },
                appliedForDHS: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Have you ever applied with DHS or any other agency to provide foster care, kinship care, or adoption?"
                },
                appliedForDHSDetail: {
                    label: 'Have you ever applied with DHS or any other agency to provide foster care, kinship care, or adoption?'
                },
                guidedPrincipalTrainingDetails: {
                    label: 'If yes please provide details of when, with who, and steps completed'
                },
                isCurrOpenHome: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Are you currently an open home?"
                },
                currentOpenHomeRsn:
                {
                    label: "If yes, what agency and reason for transferring?"
                },
                isFosParTrainCompleted: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" }
                    ],
                    factory: t.form.Select,
                    label: 'Completed required foster parent training for your state? ',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                fosParTrainCompletedAgency: {
                    label: 'If yes, which agency?'
                }

            }

        }, {
            //5

            fields: {
                isFosParTrainCompleted: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" }
                    ],
                    factory: t.form.Select,
                    label: 'Completed required foster parent training for your state? ',
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' }
                },
                fosParTrainCompletedAgency: {
                    label: 'If yes, which agency?'
                },
                isLicSpecificChild: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Are you licensing for a specific child?"
                },
                licSpcChldRelation: {
                    label: "If yes, what is your relationship for the specific child?"
                },
                isBothAppl21Old: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Both applicants aged 21 or older?"
                },
                heardFromAgencyEvent: {
                    label: "How did you hear about TFI? ",
                    options: [
                        { text: "Agency event", value: "heardFromAgencyEvent" },
                        { text: "Agency staff", value: "heardFromAgencyStaff" },
                        { text: "Church Bulletin / Faith Based Campaign:", value: "heardFromChurchOrCmaign" },
                        { text: "TV/Newspaper Ads", value: "heardFromTVNewPaperAds" },
                        { text: "TFI Resource Parent", value: "heardFromResourceParent" },
                        { text: "Other Agency", value: "heardFromOtherAgency" },
                        { text: "Other (Please indicate where or how)", value: "heardFromOtherSource" },
                        { text: "Website/Internet", value: "heardFromInternet" }
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                },
                agencyEventTown: { label: "Name of the place/person where you heard about TFI: " },
                adlt1_Name: {
                    label: "Other Resident 1 Name",
                },
                adlt1_Age: {
                    label: "Other Resident 1 Age",
                },
                adlt1_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 1 Gender"
                },
                adlt2_Name: {
                    label: "Other Resident 2 Name",
                },
                adlt2_Age: {
                    label: "Other Resident 2 Age",
                },
                adlt2_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 2 Gender"
                },
                adlt3_Name: {
                    label: "Other Resident 3 Name",
                },
                adlt3_Age: {
                    label: "Other Resident 3 Age",
                },
                adlt3_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 3 Gender"
                },
                adlt4_Name: {
                    label: "Other Resident 4 Name"
                },
                adlt4_Age: {
                    label: "Other Resident 4 Age",
                },
                adlt4_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 4 Gender"
                },
                adlt5_Name: {
                    label: "Other Resident 5 Name",
                },
                adlt5_Age: {
                    label: "Other Resident 5 Age",
                },
                adlt5_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 5 Gender"
                },
                adlt6_Name: {
                    label: "Other Resident 6 Name",
                },
                adlt6_Age: {
                    label: "Other Resident 6 Age",
                },
                adlt6_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 6 Gender"
                },
                adlt7_Name: {
                    label: "Other Resident 7 Name",
                },
                adlt7_Age: {
                    label: "Other Resident 7 Age",
                },
                adlt7_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 7 Gender"
                },
                adlt8_Name: {
                    label: "Other Resident 8 Name",
                },
                adlt8_Age: {
                    label: "Other Resident 8 Age",
                },
                adlt8_Gender: {
                    options: [
                        { text: "Male", value: "Male" },
                        { text: "Female", value: "Female" }
                    ],
                    factory: t.form.Select,
                    onChange: (value) => {
                        console.log(this)
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Other Resident 8 Gender"
                }
            }
        }, {

            fields: {
                otherResidents: {
                    label: "Please list any children and/or additional adults living in the home."
                },
                specificChild: {
                    options: [
                        { text: "Yes", value: "Yes" },
                        { text: "No", value: "No" },
                    ],
                    factory: t.form.Select,
                    error: '* required',
                    onChange: (value) => {
                        this.setState({ value: value })
                    },
                    nullOption: { value: '', text: 'Select' },
                    label: "Are you licensing for a specific child?"
                },
                specificChildRelationship:
                {
                    label: "If yes, what is your relationship for the specific child?"
                },
                additionalComments:
                {
                    multiline: true,
                    stylesheet: {
                        ...Form.stylesheet,
                        textbox: {
                            ...Form.stylesheet.textbox,
                            normal: {
                                ...Form.stylesheet.textbox.normal,
                                height: 150
                            },
                            error: {
                                ...Form.stylesheet.textbox.error,
                                height: 150
                            }
                        }
                    }
                }
            }
        }
        ]
        var deviceWidth = Dimensions.get("window").width;
        var deviceHeight = Dimensions.get("window").height;
        this.state = {
            nav: 0,
            value: undefined,
            options: options,
            currentValue: undefined,
            values: [0, 0, 0],
            page: formKS,
            height: deviceHeight,
            weidth: deviceWidth,
            formIsAltered: false,
            isSubmitting: false,
            submitStatus: ''
        };
    }
    async getFields() {
        console.log(this.props.req)
        let data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode +
            "\",\n	\"RequirementCode\": \"" + this.props.req.requirementCode +
            "\",\n \"FormCode\": \"" + this.props.req.formCode + "\"\n	\n}";
        var res = await apiHelper.post(`/licensing/form/get-fields`, data)
        console.log(res)
        this.mapFieldsToForm(res.data.fields)
        this.adjustOptions()
    }
    async saveFields() {
        let fieldValue = this.mapFormToField()
        let data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode +
            "\",\n	\"RequirementCode\": \"" + this.props.req.requirementCode +
            "\",\n \"FormCode\": \"" + this.props.req.formCode +
            "\",\n \"Fields\": " + fieldValue + "\n	\n}";
        console.log(data)
        var test = await apiHelper.post(`/licensing/form/save-fields`, data)
        console.log(test)
    }
    async submitForm() {
        if (this.state.submitStatus != "Success") {
            if (this.refs.form.getValue()) {

                this.setState({ isSubmitting: true })
                let fieldValue = this.mapFormToField()
                let data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode +
                    "\",\n	\"RequirementCode\": \"" + this.props.req.requirementCode +
                    "\",\n \"FormCode\": \"" + this.props.req.formCode +
                    "\",\n \"Fields\": " + fieldValue + "\n	\n}";
                var test = await apiHelper.post(`/licensing/form/submit`, data)
                console.log(test)
                this.setState({ isSubmitting: false })
                if (test.data.isSuccess) {
                    this.setState({ submitStatus: "Success" })
                }
                else    {
                    if(test && test.data && test.data.errors && test.data.errors.length) {
                        this.setState({ submitStatus: test.data.errors[0].message })
                    }
                }
            }
        }
    }
    mapFieldsToForm(fields) {

        let value = {};

        //fields[]
        console.log(fields)

        for (let i = 0; i < this.state.page.length; i++) {
            let currentPage = this.state.page[i].meta.props
            let newValue;
            //console.log(value)
            //console.log(currentPage)
            for (let j = 0; j < fields.length; j++) {
                //console.log(fields[j].fieldCode)
                //if (currentPage[fields[j].fieldCode]) {
                //let element = {};
                if (fields[j].fieldCode.includes("Date") || fields[j].fieldCode.includes("Birthday")) {
                    value[fields[j].fieldCode] = new Date(fields[j].fieldValue)
                }
                else {
                    value[fields[j].fieldCode] = fields[j].fieldValue
                }
                //value.push(element)
                //}
            }
        }
        //value.agencyEventTown == 
        console.log(value)
        if (this.props.userDetails.stateCode == "TX") {
            if (value.heardFromAgencyEvent) {
                value.heardFromAgencyEvent = "heardFromAgencyEvent"
                value.agencyEventTown = value.agencyEventTown
            }
            else if (value.heardFromAgencyStaff) {
                value.heardFromAgencyEvent = "heardFromAgencyStaff"
                value.agencyEventTown = value.agencyStaffName
                delete value.heardFromAgencyStaff
                delete value.agencyStaffName
            }
            else if (value.heardFromTVNewPaperAds) {
                value.heardFromAgencyEvent = "heardFromTVNewPaperAds"
                value.agencyEventTown = value.tvStationOrPaper
                delete value.heardFromTVNewPaperAds
                delete value.tvStationOrPaper
            }
            else if (value.heardFromChurchOrCmaign) {
                value.heardFromAgencyEvent = "heardFromChurchOrCmaign"
                delete value.heardFromChurchOrCmaign
            }
            else if (value.heardFromResourceParent) {
                value.heardFromAgencyEvent = "heardFromResourceParent"
                value.agencyEventTown = value.resourceParentName
                delete value.heardFromResourceParent
                delete value.resourceParentName
            }
            else if (value.heardFromOtherAgency) {
                value.heardFromAgencyEvent = "heardFromOtherAgency"
                delete value.heardFromOtherAgency
                value.agencyEventTown = value.otherAgencyName
                delete value.otherAgencyName
            }
            else if (value.heardFromInternet) {
                value.heardFromAgencyEvent = "heardFromInternet"
                delete value.heardFromInternet
                //value.agencyEventTown = value.heardFromOtherSource
                //delete value.heardFromOtherSource
            }
            else if (value.heardFromOtherSource) {
                value.heardFromAgencyEvent = "heardFromOtherSource"
                delete value.heardFromOtherSource
            }
        }
        console.log(value)
        if ((!value["todaysDate"] || value["todaysDate"] == "Invalid Date" || value["todaysDate"] == "No Date") && this.props.userDetails.stateCode != "TX") {
            var today = new Date()
            var dd = today.getMonth() + 1 + " " + today.getDate() + " " + today.getFullYear()
            value["todaysDate"] = dd
        }
        console.log(value)
        this.setState({ value: value })
    }
    mapFormToField() {

        let fieldValue = this.state.value;
        let str = '[';
        if (this.props.userDetails.stateCode == "TX" && fieldValue.heardFromAgencyEvent && this.state.nav == 4) {
            //console.log(fieldKeys)
            //console.log(fieldValue)
            let selectedValue = fieldValue.heardFromAgencyEvent;
            //console.log(selectedValue)
            if (selectedValue == "heardFromAgencyEvent" || selectedValue == "Yes" && fieldValue.heardFromAgencyEvent || selectedValue == "No" && fieldValue.heardFromAgencyEvent) {
                fieldValue.agencyEventTown = fieldValue.agencyEventTown
                fieldValue.heardFromAgencyEvent = "Yes"
            }
            else {
                if (selectedValue == "heardFromChurchOrCmaign") {
                    fieldValue.heardFromChurchOrCmaign = "Yes"
                }
                else if (selectedValue == "heardFromAgencyStaff") {
                    fieldValue.heardFromAgencyStaff = "Yes"
                    fieldValue.agencyStaffName = fieldValue.agencyEventTown
                }
                else if (selectedValue == "heardFromTVNewPaperAds") {
                    fieldValue.heardFromTVNewPaperAds = "Yes"
                    fieldValue.tvStationOrPaper = fieldValue.agencyEventTown
                }
                else if (selectedValue == "heardFromResourceParent") {
                    fieldValue.heardFromResourceParent = "Yes"
                    fieldValue.resourceParentName = fieldValue.agencyEventTown
                }
                else if (selectedValue == "heardFromOtherAgency") {
                    fieldValue.heardFromOtherAgency = "Yes"
                    fieldValue.otherAgencyName = fieldValue.agencyEventTown
                }
                else if (selectedValue == "heardFromInternet") {
                    fieldValue.heardFromInternet = "Yes"
                    //fieldValue.heardFromOtherSource = fieldValue.agencyEventTown
                }
                else if (selectedValue == "heardFromOtherSource") {
                    fieldValue.heardFromOtherSource = fieldValue.agencyEventTown
                }
                delete fieldValue.agencyEventTown
                delete fieldValue.heardFromAgencyEvent
            }
        }
        if (this.props.userDetails.stateCode == "TX" && fieldValue.agencyEventTown == undefined)
            fieldValue.agencyEventTown = ""
        let fieldKeys = Object.keys(fieldValue)
        //console.log(fieldKeys)
        console.log(fieldValue)
        for (let i = 0; i < fieldKeys.length; i++) {
            let value = fieldValue[fieldKeys[i]].toString();
            if (fieldKeys[i].includes("Date") || fieldKeys[i].includes("Birthday")) {
                value = value.slice(0, 15) //if value is a date, crop the time out because it's unnecesary
            }
            str = str + "{\"FieldCode\": \"" + fieldKeys[i] + "\",\"FieldValue\": \"" + value + "\"},\n"
        }
        str = str + "]"
        console.log(str)

        return str
    }
    alterForState() {
        let statePage;
        console.log(this.state)
        if (this.props.userDetails.stateCode == "OK")
            statePage = formOK
        else if (this.props.userDetails.stateCode == "TX")
            statePage = formTX
        else
            statePage = formKS
        this.setState({ page: statePage })

    }
    adjustOptions() {
        if (this.props.userDetails.stateCode == "NE") {
            let opt = this.state.options
            opt[2].fields.otherStates.label = "If you have lived in Nebraska for less than 5 years, please list other states of residence"
            this.setState({ options: opt })
        }
    }
    componentDidMount() {

        this.alterForState()
        this.getFields()
    }
    change(direction) {
        let nav = this.state.nav
        //const newValue = this.state.values.slice() //copy the array
        //newValue[this.state.nav] = this.state.value //execute the manipulations
        //this.setState({ values: newValue }) //set the new state


        if (direction === "next") {
            if (this.refs.form.getValue()) {
                this.saveFields()
                nav++;
            }
        }
        if (direction === "back")
            nav--;
        this.setState({ nav: nav })
    }
    formChange(value) {
        if (value.TIPS == "Yes") {
            /*let op = this.state.options.slice()
            op[this.state.nav].fields.TIPSDetails.hidden = !op[this.state.nav].fields.TIPSDetails.hidden;
            console.log(op[this.state.nav].fields.TIPSDetails.hidden)
            this.setState({ options: op, formIsAltered: true })
            this.forceUpdate();*/
        }
        else if (value.familyInterest) {
            /*let op = this.state.options[this.state.nav].fields;
            op = t.update(op, { familyInterest: { label: { '$set': value.familyInterest } } });
            //op[this.state.nav].fields.familyInterest.label = "YES";
            //console.log(op[this.state.nav].fields.familyInterest)
            let options = { ...this.state.options }
            options[this.state.nav].fields = op
            this.setState({ options: options, formIsAltered: true, value: value })*/
        }
        //console.log(value)
        generalHelper.onFormChange(value, this)
    }
    render() {
        return (
            <KeyboardAvoidingView style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }} behavior={Platform.OS == "ios" ? "padding" : null} keyboardVerticalOffset={20}>
                <ScrollView style={styles.container}>

                    <Text style={styles.h2b}>Page {this.state.nav + 1}</Text>
                    {this.state.nav == 4 && <Text style={styles.h2c}>If not applicable, leave field blank</Text>}
                    <Text style={{ fontSize: 10, alignSelf: 'flex-end' }}>* required fields</Text>

                    <Form
                        ref="form"
                        type={this.state.page[this.state.nav]}
                        options={this.state.options[this.state.nav]}
                        value={this.state.value}
                        onChange={(value) => {
                            this.formChange(value);

                        }}
                    />
                    {this.state.nav + 1 != this.state.page.length ?
                        <View style={{ flexDirection: 'row-reverse', justifyContent: 'space-between', zIndex: -1, paddingRight: 10, paddingLeft: 10 }}>
                            <TouchableOpacity onPressOut={() => this.change("next")} style={{ alignSelf: 'flex-end' }}>
                                <Text style={styles.button1}>Next Page</Text>
                            </TouchableOpacity>
                            {this.state.nav != 0 && <TouchableOpacity onPressOut={() => this.change("back")} style={{ alignSelf: 'flex-start' }}>
                                <Text style={styles.button1}>Prev Page</Text>
                            </TouchableOpacity>}
                        </View> :
                        <View style={{ flexDirection: 'row-reverse', justifyContent: 'space-between', paddingRight: 10, paddingLeft: 10}}>
                            <TouchableOpacity onPressOut={() => this.submitForm()} style={{ alignSelf: 'flex-end', minWidth: 100 }}>
                                <Text style={styles.button1}>Submit</Text>
                            </TouchableOpacity>
                            {this.state.isSubmitting && <ActivityIndicator />}
                            <Text style={this.state.submitStatus == "Success" ? { fontSize: 20, color: '#20F366' } : { fontSize: 20, color: 'red' }}>{this.state.submitStatus}</Text>
                            <TouchableOpacity onPressOut={() => this.change("back")} style={{ alignSelf: 'flex-start' }}>
                                <Text style={[styles.button1]}>Prev Page</Text>
                            </TouchableOpacity>
                        </View>
                    }
                </ScrollView>
            </KeyboardAvoidingView>
        )
    }
}

const mapStateToProps = (state) => {
    //console.log(state)
    // Redux Store --> Component
    return {
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state)
    };
};

export default connect(mapStateToProps)(FormFiller)
