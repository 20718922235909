import * as appConfig from "../../config.json";
import {resolvePath } from './generalHelper'

function getConfigValue(propName) {
    console.log(appConfig.default);
    console.log('logging')
    return resolvePath(appConfig.default, propName);
}

export {
    getConfigValue
} 