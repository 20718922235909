import { AsyncStorage } from "@react-native-community/async-storage";

async function getItem(itemName) {
    try {
        return await AsyncStorage.getItem(itemName);
    }
    catch {
        console.error('error getting Item')
    }
}

async function setItem(itemName, itemValue) {
    try {
        return await AsyncStorage.setItem(itemName, itemValue)
    }
    catch {
        console.error('error setting Item')
    }
}

async function deleteItem(itemName) {
    try {
        return await AsyncStorage.removeItem(itemName)
    }
    catch (ex) {
        console.error(ex)
        console.error('error deleting Item')
    }
}

async function clear() {
    try {
        return await AsyncStorage.clear()
    }
    catch (ex) {
        console.error(ex)
        console.error('error Clearing all Items')
    }
}

export {
    getItem,
    setItem,
    deleteItem,
    clear
}