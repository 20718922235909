import {StyleSheet, Platform} from 'react-native'

var normalTextFontSize = Platform.OS == "web" ? 20 : 10
var paddingSize = Platform.OS == "web" ? 20 : 10
var mainHeadingFontSize = Platform.OS == "web" ? 35 : 20
var headingFontSize = Platform.OS == "web" ? 30 : 18

export default StyleSheet.create({
    container: { paddingTop: 30, fontSize: normalTextFontSize },
    head: {  margin: 'auto', fontSize: headingFontSize, padding: 10 },
    mainHead: { margin: 'auto', backgroundColor: 'rgba(0, 138, 252, 1)', fontSize: mainHeadingFontSize, padding: 10, textAlign: 'center' },
    text: { margin: 6, fontSize: normalTextFontSize },
    cpTableHead: { margin: 6, fontSize: headingFontSize },
    linkText: { margin: 6, fontSize: normalTextFontSize, color: 'blue', textAlign: 'center' },
    linkStyle: {margin: 10, backgroundColor: 'rgba(241, 238, 167, 1)', borderRadius: 20},
    paddingTopBottom: {paddingBottom: paddingSize, paddingTop: paddingSize},
    boldText: {fontWeight: 'bold'},
    childInRow: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' },
    childInCol: { flexDirection: 'column' },

    basicBorder: { borderWidth: 1 },
    icon: {alignSelf: 'center', fontSize: 20},

    //Margins
    allMargin10: { margin: 10 },
    lrMargin10: { marginLeft: 10, marginRight: 10 },
    tbMargin10: { marginTop: 10, marginBottom: 10 },
    topMargin10: { marginTop: 10 },
    botMargin10: { marginBottom: 10 },
    leftMargin10: { marginLeft: 10 },
    rightMargin10: { marginRight: 10 },

    //Padding
    allPadding10: { padding: 10 },
    lrPadding10: { paddingLeft: 10, paddingRight: 10 },
    tbPadding10: { paddingTop: 10, paddingBottom: 10 },
    topPadding10: { paddingTop: 10 },
    botPadding10: { paddingBottom: 10 },
    leftPadding10: { paddingLeft: 10 },
    rightPadding10: { marginRight: 10 },
    h2b: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        //borderBottomWidth: 2,
        fontSize: 26,
        fontWeight: 'bold'
    },
    h1: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        fontSize: 30,
        fontWeight: 'bold',
        margin: 10
    },
    
    card1: {
        borderWidth: 1, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 2, maxWidth: 422
    },
    jusFlexSt: {justifyContent: 'flex-start'}
  });