import React from 'react'
import { TouchableOpacity, Alert, Text, View, AppState, Platform } from "react-native";
import * as AsyncStorage from "../common/AsyncStorage/storageHelper";
import FFPAlert from '../common/Alert/alert';
import { withNavigation } from 'react-navigation';


class SignoutScreen extends React.Component {
    constructor(props) {

        super(props);

        this.state = { displayModal: false }

        this.logoutClick = this.logoutClick.bind(this)
        this.successLogoutClick = this.successLogoutClick.bind(this)
        this.cancelLogoutClick = this.cancelLogoutClick.bind(this)
    }


    logoutClick() {
        this.setState({ displayModal: true });
        // FFPAlert("Log out", "Do you want to logout?", this.successLogoutClick, this.cancelLogoutClick);
    }

    async successLogoutClick() {
        await AsyncStorage.clear();
        this.props.navigation.navigate('AuthLoading')
        this.setState({ displayModal: false });
    }

    cancelLogoutClick() {
        this.setState({ displayModal: false });
    }

    render() {
        return (
            <View style={{ justifyContent: 'flex-start' }}>
                {this.state.displayModal === true &&
                    <FFPAlert title="Logout" desc="Are you Sure?"
                        onPositivePress={this.successLogoutClick}
                        onNegativePress={this.cancelLogoutClick}
                    />}
                <TouchableOpacity onPress={this.logoutClick}>

                    <Text style={{ margin: 15 }}>Sign out</Text>
                </TouchableOpacity>
            </View>
        )
    }
}

export default withNavigation(SignoutScreen)