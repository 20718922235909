import React from 'react';
import { userAuthReduxTypes } from '../../../redux/users/auth.redux';
import { TouchableHighlight, Text } from 'react-native'
import { connect } from 'react-redux';
import * as AsyncStorage from "../../common/AsyncStorage/storageHelper";
import styles from './../../Admin/styles'
import { ScrollView } from 'react-native-gesture-handler';
import { userReduxTypes } from "../../../redux/users/user.redux";
import * as generalHelper from './../../common/generalHelper'
import t from 'tcomb-form-native'
import { ActivityIndicator } from 'react-native-paper';

const Form = t.form.Form;

var options = {
  fields: {
    userId: {
      hidden: true
    },
    email: {
      hidden: true
    },
    oldPassword: generalHelper.extTCombCompTypes.pwdOption,
    newPassword: { ...generalHelper.extTCombCompTypes.pwdOption, error: 'New password does not meet requirements' },
    confirmNewPassword: generalHelper.extTCombCompTypes.pwdOption, error: 'New passwords must match.'
  }
};


class ChangePasswordScreen extends React.Component {
  constructor(props) {
    super(props);

    this.changePassword = this.changePassword.bind(this)



    const SamePassword = t.refinement(t.String, (s) => {
      return s == this.state.value.newPassword;
    });

    SamePassword.getValidationErrorMessage = function (value, path, context) {
      return 'New passwords must match.';
    };

    const changePwdSchema = t.struct({
      userId: t.Number,
      email: t.String,
      oldPassword: t.String,
      newPassword: generalHelper.extTCombCompTypes.pwdPolicyType,
      confirmNewPassword: SamePassword
    });
    this.state = { options: options, changePwdSchema }
  }

  componentDidMount() {
    AsyncStorage.getItem('loggedInUserDetails').then((userDetailsStr) => {
      try {
        var userDetails = JSON.parse(userDetailsStr);
        if (userDetails) {
          this.setState({
            value: { userId: userDetails.userId, email: userDetails.email },
          });
        }
      }
      catch {
        console.error("error fetching Logged in details")
      }

    })

  }

  componentDidUpdate(prevProps, newProps) {
    console.log(prevProps)
    console.log('new props')
    console.log(newProps)
  }

  static navigationOptions = {
    // header: null,
    headerTitle: "Change Password"
  }

  userInputChange(fieldName, fieldValue) {
    var currentState = this.state;
    currentState[fieldName] = fieldValue
    this.setState(currentState)
  }
  changePassword() {
    // call getValue() to get the values of the form
    var value = this.refs.form.getValue();
    if (value) { // if validation fails, value will be null
      console.log(value); // value here is an instance of Person
      this.props.changePassword(value, (resp) => {
        AsyncStorage.clear('loggedInUserDetails')
        this.props.navigation.navigate('SignIn')
      })
    }
  }

  render() {
    return (
      <ScrollView style={styles.container}>
        <Text>You are logging in for the first time. Please change password before proceeding.</Text>
        <Form
          ref="form"
          type={this.state.changePwdSchema}
          options={this.state.options}
          value={this.state.value}
          onChange={(value) => generalHelper.onFormChange(value, this)}
        />
        <Text>Note: Password should be atleast 8 characters long with a capital letter, small letter, number and any one of special character #?!@$%^&*-</Text>
        <TouchableHighlight style={styles.button} onPress={this.changePassword}
          underlayColor='#99d9f4' disabled={this.props.isChangePasswordInProgress}>
          
          <Text style={styles.buttonText}>
            Change Password {this.props.isChangePasswordInProgress}
          </Text>
        </TouchableHighlight>
        {this.props.isChangePasswordInProgress && <ActivityIndicator />}
        {this.props.errorChangingPassword &&
          <Text style={{ color: 'red' }}>
            {this.props.errorChangingPassword.error}
          </Text>}
      </ScrollView>
    );
  }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
  console.log(state)
  // Redux Store --> Component
  return {
    userDetails: userAuthReduxTypes.selectors.getAuthDetails(state),
    isChangePasswordInProgress: userReduxTypes.selectors.getIsChangePasswordInProgress(state),
    errorChangingPassword: userReduxTypes.selectors.getErrorChangingPassword(state),
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  // Action
  return {
    // Login
    changePassword: (passwordDetails, callback) => userReduxTypes.actions.changePassword(dispatch, passwordDetails, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordScreen)
