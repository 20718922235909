import React from "react";
import { View, Text, ActivityIndicator, ScrollView, SafeAreaView, Dimensions, Platform, TouchableOpacity, Modal } from "react-native";
import { Button } from "react-native"
import { licensingReduxTypes } from './../../redux/licensing/licensing.redux';
import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import { connect } from 'react-redux';
import FFPModal from './../common/Modal/modal'
import FFPFileUpload from "../common/FileUpload/FileUpload";
import FFPAlert from '../common/Alert/alert'
import axiosInst from './../common/axiosHelper'
import FormFiller from './formFiller'

import Icon from 'react-native-vector-icons/FontAwesome';
import styles from './AppStyles';
import DocumentPicker from 'react-native-document-picker';
import FileViewer from 'react-native-file-viewer';
//import RNFS from 'react-native-fs';
//import RNFetchBlob from 'react-native-fetch-blob'
import axios from 'axios';
import * as configHelper from "./../common/configHelper";
import * as apiHelper from "./../common/apiHelper"
const apiBaseUrl = configHelper.getConfigValue('apiBaseUrl');

class LicensingScreen extends React.Component {
  constructor(props) {
    super(props);
    var deviceWidth = Dimensions.get("window").width;
    var deviceHeight = Dimensions.get("window").height;
    this.openRequirementDetails = this.openRequirementDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onRequirementModalOpen = this.onRequirementModalOpen.bind(this);
    this.state = {
      archiveModalVisible: false,
      modalVisible: false,
      formVisible: false,
      deviceWidth: deviceWidth,
      deviceHeight: deviceHeight,
      isInitial: true,
      wasInitial: false,
      selectedLicensePeriod: undefined,
      loaded: false,
      isOnGoingDocs: true,
      isFetchingLicensePeriods: true
    };

  }

  async selectMultipleFile(multiple) {
    //Opening Document Picker for selection of multiple file
    try {
      if (multiple) {
        const results = await DocumentPicker.pickMultiple({
          type: [DocumentPicker.types.images],
          //There can me more options as well find above
        });
        for (const res of results) {
          //Printing the log realted to the file
          /*console.log('res : ' + JSON.stringify(res));
          console.log('URI : ' + res.uri);
          console.log('Type : ' + res.type);
          console.log('File Name : ' + res.name);
          console.log('File Size : ' + res.size);*/
        }
        //Setting the state to show multiple file attributes
        this.setState({ selectedFiles: results })
        //this.fileSelected(results);
      }
      else {
        const results = await DocumentPicker.pick({
          type: [DocumentPicker.types.images],
          //There can me more options as well find above
        });
        const res = [];
        res.push(results); //need to put it in an array because logic in the display requires it to be one
        this.setState({ selectedFiles: res })
      }
    } catch (err) {
      //Handling any exception (If any)
      if (DocumentPicker.isCancel(err)) {
        //If user canceled the document selection
        alert('Canceled from multiple doc picker');
      } else {
        //For Unknown Error
        alert('Unknown Error: ' + JSON.stringify(err));
        throw err;
      }
    }
  }
  formatTime(prop, format) {
    if (!prop) {
      return "Just Uploaded";
    }
    var day, month, year, time;
    var whole;
    var date = new Date();
    //var offset = date.getTimezoneOffset();
    //offset = offset / 60;
    whole = prop.split("-");
    year = whole[0];
    year = year.slice(2);
    month = whole[1];
    day = whole[2];
    whole = day.split("T");
    day = whole[0];
    time = whole[1].split(":", 2);
    time[0] = Math.abs(time[0]);
    if (time[0] > 12) {
      time[0] = time[0] - 12;
      time[1] = time[1] + "PM";
    }
    else
      time[1] = time[1] + "AM";
    time = time.join(":");
    if (format == "day")
      return month + "/" + day + "/" + year;
    else
      return time + " " + month + "/" + day + "/" + year;
  }
  componentDidMount() {
    this.props.fetchUserRequirements({ userUniqueCode: this.props.userDetails.userUniqueCode });
    this.fetchLicensePeriods();
  }
  componentDidUpdate() {
    //console.log(this.state);
    //console.log(this.state.selectedLicensePeriod);
    if (this.props.userRequirements != null && this.state.selectedLicensePeriod == null) {
      this.filterDocsFromReqs(this.props.userRequirements)
      console.log("UPDATED STATE");
    }
    /*if (this.state.selectedLicensePeriod && !this.state.loaded) {
      //if (this.state.licensePeriod < 2)
      //this.setState({ i: true })
      if (this.state.selectedLicensePeriod.licensePeriodLevelRequirements) {
        //console.log(this.props.userRequirements);


        if (this.state.licensePeriod) {
          if (this.state.licensePeriod.length > 1) {
            this.setState({ isInitial: false, loaded: true })
          }
          else
            this.setState({ loaded: true })
        }
      }
    }*/
  }
  openRequirementDetails(memberReq, req) {
    this.setState({ selectedMemberReq: memberReq, selectedReq: req, selectedFiles: null, selectionErrorMessage: null });
    this.setModalVisible(!this.state.modalVisible)
  }
  openArchive() {
    this.setArchiveModalVisible(!this.state.archiveModalVisible);
  }
  setArchiveModalVisible(archiveModalVisibility) {
    this.setState({ archiveModalVisible: archiveModalVisibility })
  }
  setModalVisible(modalVisibility) {
    this.setState({ modalVisible: modalVisibility });
    this.onArchiveModalOpen();
  }
  setFormVisible(formVisibility) {
    if (!formVisibility)
      this.fetchSelectedLicensePeriod(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].licenseCode)
    this.setState({ formVisible: formVisibility, modalVisible: false })
  }

  fileSelected(e) {
    var newFileNamesToAdd = [...e.target.files].map(x => x.name);
    var errorMessages = [];
    if (this.state.selectedFiles && this.state.selectedFiles.some(x => newFileNamesToAdd.indexOf(x.name) > -1)) {
      errorMessages.push("You are trying to upload a file with the same name. Please rename and upload.")
    }
    if (this.state.selectedReq.userUploads && this.state.selectedReq.userUploads.some(x => newFileNamesToAdd.indexOf(x.documentName) > -1)) {
      errorMessages.push("There is already a file uploaded to server with this name. Please rename and upload.")
    }

    if (errorMessages && errorMessages.length) {
      this.setState({ selectionErrorMessage: errorMessages });
      e.preventDefault();
      e.target.value = '';
    }
    else {
      this.setState({ selectedFiles: [...e.target.files], selectionErrorMessage: [] })
    }
  }
  filterDocsFromReqs(reqs) {
    let reqsCopy = JSON.parse(JSON.stringify(reqs))
    let onGoingReqs = JSON.parse(JSON.stringify(reqsCopy))
    let membersToPop = []
    let memberTypeToPop = []
    let onGoingMembersToPop = []
    let onGoingMemberTypeToPop = []
    let isOnGoingDocs = false;
    if (reqs.licensePeriodLevelRequirements.length === 1) {

      //For each member type
      reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.map((memberReq, k) => {
        //For each member
        for (let i = 0; i < memberReq.memberLevelRequirements.length; i++) {
          //For each category
          let joffset = 0;
          for (let j = 0; j < memberReq.memberLevelRequirements[i].licenseCategoryLevelRequirement.length; j++) {
            if (memberReq.memberLevelRequirements[i].licenseCategoryLevelRequirement[j].categoryDescription == "Ongoing Updates") {
              isOnGoingDocs = true;
              reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[k].memberLevelRequirements[i].licenseCategoryLevelRequirement.splice(j, 1)
              if (memberReq.memberLevelRequirements[i].licenseCategoryLevelRequirement.length == 1) {
                //console.log(memberReq.memberLevelRequirements[i].personCode)
                membersToPop.push(memberReq.memberLevelRequirements[i].personCode)
              }
            }

            else if (memberReq.memberLevelRequirements[i].licenseCategoryLevelRequirement[j].categoryDescription != "Ongoing Updates") {
              //console.log(memberReq.memberLevelRequirements[i].licenseCategoryLevelRequirement[j].categoryDescription)
              //console.log(onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[k].memberLevelRequirements[i].licenseCategoryLevelRequirement[j])
              onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[k].memberLevelRequirements[i].licenseCategoryLevelRequirement.splice(j - joffset, 1)
              if (memberReq.memberLevelRequirements[i].licenseCategoryLevelRequirement.length == 1) {

                onGoingMembersToPop.push(memberReq.memberLevelRequirements[i].personCode)
              }
              joffset++
            }
          }
          if (memberReq.memberLevelRequirements[i].licenseCategoryLevelRequirement.length == 0) {
            membersToPop.push(memberReq.memberLevelRequirements[i].personCode)
            onGoingMembersToPop.push(memberReq.memberLevelRequirements[i].personCode)
          }
        }

      })


      //Trim regular reqs
      let offset = 0;
      //console.log(membersToPop)
      for (let j = 0; j < reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.length; j++) {
        //console.log(reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j])
        for (let k = 0; k < reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j].memberLevelRequirements.length; k++) {
          for (let i = 0; i < membersToPop.length; i++) {
            //console.log(reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j].memberLevelRequirements[k].personCode == undefined)
            if (reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j].memberLevelRequirements[k].personCode == membersToPop[i]) {
              reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j].memberLevelRequirements.splice(k - offset, 1);
              //console.log(membersToPop[i] + " offset " + offset + " k " + k)
              //console.log(reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements)
              offset++
            }
          }



        }
        offset = 0
      }
      for (let i = 0; i < reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.length; i++) {
        if (reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[i].memberLevelRequirements.length == 0) {
          memberTypeToPop.push(i)
          //console.log(reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[i])
        }
      }
      //console.log(reqsCopy)
      //console.log(memberTypeToPop)

      offset = 0;
      for (let i = 0; i < memberTypeToPop.length; i++) {
        reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.splice(memberTypeToPop[i - offset], 1);
        offset++;
      }
      //console.log(reqsCopy)
      //Trim Ongoing Reqs
      for (let i = 0; i < onGoingMembersToPop.length; i++) {
        for (let j = 0; j < onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.length; j++) {
          //console.log(reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j])
          for (let k = 0; k < reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j].memberLevelRequirements.length; k++) {
            if (reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j].memberLevelRequirements[k].personCode == onGoingMembersToPop[i]) {
              onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[j].memberLevelRequirements.splice(onGoingMembersToPop[i], 1);

            }
          }

        }
      }
      for (let i = 0; i < onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.length; i++) {
        if (onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[i].memberLevelRequirements.length == 0) {
          onGoingMemberTypeToPop.push(i)
          //console.log(onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements[i])
        }
      }
      //console.log(reqsCopy)
      //console.log(memberTypeToPop)

      offset = 0;
      for (let i = 0; i < onGoingMemberTypeToPop.length; i++) {
        onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.splice(onGoingMemberTypeToPop[i - offset], 1);
        offset++;
      }
      //console.log(reqsCopy.licensePeriodLevelRequirements[0])
      //console.log(onGoingReqs.licensePeriodLevelRequirements[0])
    }
    //console.log(onGoingReqs)
    this.setState({ onGoingReqs: onGoingReqs, selectedLicensePeriod: reqsCopy, isOnGoingDocs: isOnGoingDocs })
    return reqsCopy
  }
  async fetchSelectedLicensePeriod(selectedPeriod) {
    /*var name;
    var data;
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    this.setState({ archiveModalVisible: false, isFetchingLicensePeriods: true })
    data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode +
      "\",\n	\"licensePeriodCode\": \"" + selectedPeriod + "\"\n	\n}";
    xhr.addEventListener("readystatechange", () => {
      if (xhr.readyState == 4) {
        this.setState({ isFetchingLicensePeriods: false })
        //console.log(JSON.parse(xhr.response).licensePeriodLevelRequirements[0].licenseCode)
        if (this.state.licensePeriod.length > 1) {
          if (JSON.parse(xhr.response).licensePeriodLevelRequirements[0].licenseCode.charAt(0) != "R") //if the original license period
            this.setState({ selectedLicensePeriod: this.filterDocsFromReqs(JSON.parse(xhr.response)), isInitial: false });
          else //if both not empty or a renewal period, it's the original licensing period
            this.setState({ selectedLicensePeriod: this.filterDocsFromReqs(JSON.parse(xhr.response)), isInitial: false });
        }
        else
          this.setState({ selectedLicensePeriod: this.filterDocsFromReqs(JSON.parse(xhr.response)), isInitial: true });
      }
    });
    xhr.open("POST", `${apiBaseUrl}/licensing/family/requirements/search`);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + this.props.userDetails.token);
    xhr.send(data);*/
    this.setState({ archiveModalVisible: false, isFetchingLicensePeriods: true })
    let data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode +
      "\",\n	\"licensePeriodCode\": \"" + selectedPeriod + "\"\n	\n}";
    var response = await apiHelper.post(`/licensing/family/requirements/search`, data)
    console.log(response)
    if (response.status == "200") {
      this.setState({ isFetchingLicensePeriods: false })
      //console.log(JSON.parse(xhr.response).licensePeriodLevelRequirements[0].licenseCode)
      if (this.state.licensePeriod.length > 1) {
        if (response.data.licensePeriodLevelRequirements[0].licenseCode.charAt(0) != "R") //if the original license period
          this.setState({ selectedLicensePeriod: this.filterDocsFromReqs(response.data), isInitial: false });
        else //if both not empty or a renewal period, it's the original licensing period
          this.setState({ selectedLicensePeriod: this.filterDocsFromReqs(response.data), isInitial: false });
      }
      else
        this.setState({ selectedLicensePeriod: this.filterDocsFromReqs(response.data), isInitial: true });
    }
  }

  async fetchLicensePeriods() {
    /*var name;
    var data;
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", () => {
      if (xhr.readyState == 4) {
        let periods = JSON.parse(xhr.response)
        if (periods.licensePeriods.length > 1)
          this.setState({ licensePeriod: periods.licensePeriods, isFetchingLicensePeriods: false, isInitial: false, wasInitial: true });
        else
          this.setState({ licensePeriod: periods.licensePeriods, isFetchingLicensePeriods: false, isInitial: true });
      }
    });
    xhr.open("GET", `${apiBaseUrl}/licensing/family/requirements/overall-status?UserUniqueCode=` + this.props.userDetails.userUniqueCode);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + this.props.userDetails.token);
    xhr.send();*/
    let response = await apiHelper.get(`/licensing/family/requirements/overall-status?UserUniqueCode=` + this.props.userDetails.userUniqueCode)

    if (response.status == 200) {
      let periods = response.data
      if (periods.licensePeriods.length > 1)
        this.setState({ licensePeriod: periods.licensePeriods, isFetchingLicensePeriods: false, isInitial: false, wasInitial: true });
      else
        this.setState({ licensePeriod: periods.licensePeriods, isFetchingLicensePeriods: false, isInitial: true });
    }

  }
  handleChange(event) {
    var currentState = this.state;
    currentState[event.target.name] = event.target.value
    this.setState({ ...currentState });
  }
  onRequirementModalOpen() {
    this.setState({ uploadErrorMessage: [], selectionErrorMessage: [], uploadFileInProgress: false, errorDeletingFileOfaRequirement: false })
  }
  onArchiveModalOpen() {
    this.setState({ uploadErrorMessage: [], selectionErrorMessage: [], uploadFileInProgress: false, errorDeletingFileOfaRequirement: false })
  }

  uploadFile() {

    var errorMessages = [];
    if (this.state.selectedFiles && this.state.selectedFiles.length) {
      if (!this.state.selectedReq.canFFPUserUpload) {
        errorMessages.push("This requirement requires a document to be uploaded to save.");
      }
    }
    else {
      if (!this.state.selectedReq.canFFPUserUpload) {
        errorMessages.push("This requirement does not allow user to upload doc");
      }
    }
    if (errorMessages && errorMessages.length) {
      this.setState({ uploadErrorMessage: errorMessages });
    }
    else {
      this.setState({ uploadFileInProgress: true })
      let formData = new FormData();

      // formData.append('Files', this.state.selectedFiles);
      this.state.selectedFiles.forEach((x, ind) => {
        formData.append('Files', x);
      })

      formData.append('notes', this.state.notes);
      if (this.state.selectedMemberReq.personCode)
        formData.append('personCode', this.state.selectedMemberReq.personCode);
      formData.append('UserUniqueCode', this.props.userDetails.userUniqueCode);
      formData.append('RequirementCode', this.state.selectedReq.requirementCode);

      /*
        Make the request to the POST /single-file URL
      */
      var that = this;

      axiosInst.post(`${apiBaseUrl}/licensing/family/requirement/document/add`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          }.bind(this)
        }
      ).then(function (response) {
        if (response.data.isSuccess == false && response.data.errors && response.data.errors.length) {
          that.setState({ uploadErrorMessage: response.data.errors.map(x => x.message) });
        }
        else {

          let userFiles = that.state.selectedReq.userUploads.slice();
          let selectedReqCopy = that.state.selectedReq;
          that.state.selectedFiles.forEach((x, i) => {
            let upload = {
              documentName: x.name,
              userUploadId: response.data.fileUploadDetails[i].fileUploadId,
              notes: "",
              createdDate: response.data.fileUploadDetails[i].createdDate
            };
            userFiles.push(upload);
          })
          selectedReqCopy.userUploads = userFiles
          selectedReqCopy.requirementStatusCode = "START"
          that.setState({
            uploadFileInProgress: false,
            selectedReq: selectedReqCopy,
            selectedFiles: null,

          })
        }

      })
        .catch(function (error) {
          console.log(error);
          that.setState({ uploadFileInProgress: false })
        });
    }
  }
  deleteFile(userUploadId, docName) {
    var deleteDocDetails = {
      UserUniqueCode: this.props.userDetails.userUniqueCode,
      RequirementCode: this.state.selectedReq.requirementCode,
      PersonCode: this.state.selectedMemberReq.personCode,
      UserUploadId: userUploadId,
      FileName: docName
    }
    this.props.deleteFile(deleteDocDetails, (response) => {
      if (response.data.isSuccess) {
        const items = this.state.selectedReq.userUploads.filter(x => x.userUploadId !== userUploadId);
        const selectedReq = this.state.selectedReq;
        selectedReq.userUploads = items;
        this.setState({
          selectedReq: selectedReq,
          deletePressed: false
        })
      }
      else {
        this.setState({ errorDeletingFileOfaRequirement: response.data, deletePressed: false })
      }
    })
  }
  /*async modifyPdf(url) {
    //const url = 'https://pdf-lib.js.org/assets/with_update_sections.pdf'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { width, height } = firstPage.getSize()
    firstPage.drawText('This text was added with JavaScript!', {
      x: 5,
      y: height / 2 + 300,
      size: 20,
      font: helveticaFont,
      color: rgb(0.1, 0.95, 0.1),
      rotate: degrees(-45),
    })

    const pdfBytes = await pdfDoc.save()
    const url2 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement('a');
    link.href = url2;
    link.setAttribute('download', "test.pdf");
    document.body.appendChild(link);
    link.click();
  }*/
  async viewFile(fileType, uploadFileIndex) {
    let data, personcode, name;
    if (this.state.selectedMemberReq.personCode) //if personCode is undefined (if for whole family) set it to empty string
      personcode = this.state.selectedMemberReq.personCode;
    else
      personcode = "";
    var xhr = new XMLHttpRequest();
    var self = this;
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (fileType == "formFill") {
          //console.log(xhr.response);
          const url = window.URL.createObjectURL(new Blob([xhr.response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          //link.click();
          //console.log(link)
          self.modifyPdf(link.href);
        }
        else if (Platform.OS == "web") {
          console.log(xhr.response);
          console.log(xhr)
          const url = window.URL.createObjectURL(new Blob([xhr.response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
        }
        else {
          console.log(xhr.response)
          /*const localFile = `${RNFS.DocumentDirectoryPath}/` + name;
          const url = window.URL.createObjectURL(new Blob([xhr.response]));
          const options = {
            fromUrl: url,
            toFile: localFile
          };
          RNFS.downloadFile(options).promise
            .then(() => FileViewer.open(localFile))
            .then(() => {
              // success
            })
            .catch(error => {
              console.log(error)
            });*/
        }
      }
    });
    if (fileType == "userUpload") {
      name = this.state.selectedReq.userUploads[uploadFileIndex].documentName;
      data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode + "\",\n	\"RequirementCode\": \"" +
        this.state.selectedReq.requirementCode + "\",\n	\"personCode\": \"" + personcode +
        "\",\n	\"FileName\": \"" + name + "\", \"IsUserUploaded\": true\n	\n}";
      xhr.open("POST", `${apiBaseUrl}/document/download/requirement`);
    }
    else if (fileType == "blankDoc") {
      name = this.state.selectedReq.blankDocs[0];
      console.log(name);
      var stateCode = this.state.selectedReq.requirementCode.slice(0, 2);
      data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode + "\",\n	\"RequirementCode\": \"" +
        this.state.selectedReq.requirementCode + "\",\n	\"StateCode\": \"" + stateCode +
        "\",\n	\"FileName\": \"" + name + "\"	\n}";
      xhr.open("POST", `${apiBaseUrl}/document/download/requirement/blank-document`);
    }
    else if (fileType == "formFill") {
      name = this.state.selectedReq.blankDocs[0];
      //console.log(name);
      var stateCode = this.state.selectedReq.requirementCode.slice(0, 2);
      data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode + "\",\n	\"RequirementCode\": \"" +
        this.state.selectedReq.requirementCode + "\",\n	\"StateCode\": \"" + stateCode +
        "\",\n	\"FileName\": \"" + name + "\"	\n}";
      xhr.open("POST", `${apiBaseUrl}/document/download/requirement/blank-document`);
    }
    else if (fileType == "returnedDoc") {
      name = this.state.selectedReq.responseToUploads[uploadFileIndex].documentName
      let personCode = this.state.selectedMemberReq.personCode
      if (personCode == undefined)
        personCode = ""
      var stateCode = this.state.selectedReq.requirementCode.slice(0, 2);
      data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode + "\",\n	\"RequirementCode\": \"" +
        this.state.selectedReq.requirementCode + "\",\n	\"PersonCode\": \"" + personCode +
        "\",\n	\"FileName\": \"" + name + "\", \"IsUserUploaded\": false\n	\n}";
      xhr.open("POST", `${apiBaseUrl}/document/download/requirement`);
      //console.log(data);
    }
    if (Platform.OS == "web") {
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", "Bearer " + this.props.userDetails.token);
      xhr.responseType = 'arraybuffer';
      xhr.send(data);

    }
  }
  render() {
    return (
      <SafeAreaView style={Platform.OS == "web" ? { height: this.state.deviceHeight - 65, marginBottom: 0 } : { height: this.state.deviceHeight }}>
        <ScrollView showsVerticalScrollIndicator={false} style={{ maxWidth: this.state.deviceWidth }}>
          {this.props.isFetchingUserRequirements || this.state.isFetchingLicensePeriods ?
            <ActivityIndicator animating={true} size="large" color="teal" style={{ padding: 200 }} /> : <View>
              <View style={styles.h1v}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={styles.h1}>{this.state.wasInitial && this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].licenseCode.charAt(0) != "R"
                    || this.state.isInitial ? "Licensing" :
                    "Renewal "}
                  </Text>
                  {this.state.licensePeriod && this.state.licensePeriod.length > 1
                    ? <TouchableOpacity onPress={() => this.openArchive()} style={{ maxWidth: 240, padding: 2, alignSelf: 'flex-end', marginBottom: 10 }}>
                      <Text style={styles.button1}>{"Archive of past periods"}</Text>
                    </TouchableOpacity> : <Text></Text>}
                </View>
                <Text style={styles.h2c}>{this.state.isInitial ? "Initial Licensing Period" :
                  !this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length ? "" : !this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate ?
                    "Renew by " + this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length != 0 ?
                      this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].nextRenewalDate :
                      this.state.licensePeriod[this.state.licensePeriod.length - 1].licenseEndDate, "day") : this.state.wasInitial ?
                      "Initial License Completed " + this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].startDate, "day") : "Period from " +
                      this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].startDate, "day") + "-" +
                      this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate, "day")}</Text>

              </View>
              <View style={{ /*flexWrap: 'wrap', flex: 1*/ }}>
                {!this.props.isFetchingUserRequirements && this.state.selectedLicensePeriod && this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length ?
                  this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.map((memberTypeReq, index) => {
                    //console.log(memberTypeReq)
                    return (


                      <View key={!memberTypeReq.length ? index + "f" : !memberTypeReq[0].memberTypeDescription ? index + "f" : memberTypeReq[0].memberTypeDescription} style={{ maxWidth: 1500, }}>
                        <Text style={styles.h2b}>{memberTypeReq.memberTypeDescription ? memberTypeReq.memberTypeDescription : "Whole Family"}</Text>
                        <View style={{ maxWidth: 1500, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                          {memberTypeReq.memberLevelRequirements && memberTypeReq.memberLevelRequirements.length
                            ? memberTypeReq.memberLevelRequirements.map((memberReq) => {
                              //console.log(memberReq)
                              return (
                                <View key={index + memberReq.personCode} style={{ borderWidth: 2, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 2, maxWidth: 433, minWidth: 315, flex: 1 }}>
                                  <Text style={styles.h2}>{memberReq.personCode ? memberReq.memberName : ''}</Text>
                                  {memberReq.licenseCategoryLevelRequirement && memberReq.licenseCategoryLevelRequirement.length
                                    ? memberReq.licenseCategoryLevelRequirement.map((category) => {
                                      return (
                                        <View key={memberReq.personCode + category.categoryDescription}>
                                          <View style={{ backgroundColor: "#FEFCF3", borderRadius: 5, padding: 1 }}>
                                            <Text style={styles.h3}>{category.categoryDescription}</Text>
                                            {category.requirements && category.requirements.length
                                              ? category.requirements.map((req) => {
                                                return (
                                                  <View key={memberReq.personCode + req.requirementCode} style={styles.buttonBorderContainer} >
                                                    <View style={{ flex: 1, minWidth: 150 }}>

                                                      <Text style={{ flex: 1, flexWrap: 'wrap' }}>{req.requirementName}</Text>
                                                    </View>
                                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', maxWidth: 120, minWidth: 110, flex: 1 }}>

                                                        <View style={{ flex: 1, alignContent: 'center', paddingHorizontal: 5 }}>
                                                          <View style={category.categoryDescription == "Ongoing Updates" ? req.userUploads.length ? { borderColor: '#FFD500', borderWidth: 2, borderRadius: 5, padding: 2 } : {} :
                                                            req.requirementStatusCode == 'APPROVED'
                                                              ? { borderColor: '#20F366', borderWidth: 2, borderRadius: 5, padding: 2 } : req.requirementStatusCode == 'REJECTED' ?
                                                                { borderColor: '#EC4911', borderWidth: 2, borderRadius: 5, padding: 2 } : req.userUploads.length ?
                                                                  { borderColor: '#FFD500', borderWidth: 2, borderRadius: 5, padding: 2 } : {}
                                                          } >
                                                            <Text style={{ fontSize: 15 }}> {category.categoryDescription == "Ongoing Updates" ? req.userUploads.length ? 'Pending' : '' :
                                                              req.requirementStatusCode == 'APPROVED'
                                                                ? 'Completed' : req.requirementStatusCode == 'REJECTED' ? 'Rejected' : req.userUploads.length ? 'Pending' : ''
                                                            }</Text>
                                                            <Text style={{ fontSize: 10 }}> {req.requirementStatusCode == 'APPROVED'
                                                              ? this.formatTime(req.completedDate) : req.requirementStatusCode == 'REJECTED' ? this.formatTime(req.rejectedDate) :
                                                                req.userUploads.length ? this.formatTime(req.userUploads[0].createdDate) : ''}</Text>
                                                          </View>
                                                        </View>
                                                      </View>
                                                    </View>
                                                    <TouchableOpacity onPress={() => this.openRequirementDetails(memberReq, req)} style={{
                                                      padding: 10, marginBottom: 0, height: 42,
                                                      backgroundColor: '#008080', borderRadius: 10, alignSelf: 'stretch'
                                                    }}>
                                                      <Icon name="edit" style={{ color: 'white', alignSelf: 'center', fontSize: 30 }} />
                                                    </TouchableOpacity>
                                                  </View>
                                                );
                                              })
                                              : (<Text>'There are no requirements for this member'</Text>)}
                                          </View>
                                        </View>
                                      );
                                    }

                                    )

                                    : (<Text>'There are no requirements for this member'</Text>)}

                                </View>
                              )
                            }) : "No members Listed"}
                        </View>
                      </View>

                    )
                  })
                  : <Text>There are no requirements for this user</Text>}
              </View>
              {this.state.isOnGoingDocs && <View style={{}}>
                <Text style={styles.h2d}>Documents to keep updated</Text>
                <View style={{ backgroundColor: 'whitesmoke', borderRadius: 10, borderWidth: 2, borderColor: 'teal' }}>
                  {!this.props.isFetchingUserRequirements && this.state.selectedLicensePeriod && this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length ?
                    this.state.onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.map((memberTypeReq, index) => {
                      //console.log(memberTypeReq)
                      return (


                        <View key={!memberTypeReq.length ? index + "f" : !memberTypeReq[0].memberTypeDescription ? index + "f" : memberTypeReq[0].memberTypeDescription} style={{ maxWidth: 1500 }}>
                          <Text style={styles.h2b}>{memberTypeReq.memberTypeDescription ? memberTypeReq.memberTypeDescription : "Whole Family"}</Text>
                          <View style={{ maxWidth: 1500, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                            {memberTypeReq.memberLevelRequirements && memberTypeReq.memberLevelRequirements.length
                              ? memberTypeReq.memberLevelRequirements.map((memberReq) => {
                                //console.log(memberReq)
                                return (
                                  <View key={index + memberReq.personCode} style={{ borderWidth: 2, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 2, maxWidth: 433, minWidth: 315, flex: 1, backgroundColor: 'white' }}>
                                    <Text style={styles.h2}>{memberReq.personCode ? memberReq.memberName : ''}</Text>
                                    {memberReq.licenseCategoryLevelRequirement && memberReq.licenseCategoryLevelRequirement.length
                                      ? memberReq.licenseCategoryLevelRequirement.map((category) => {
                                        return (
                                          <View key={memberReq.personCode + category.categoryDescription}>
                                            {category.categoryDescription == "Ongoing Updates" && <View style={{ backgroundColor: "#FEFCF3", borderRadius: 5, padding: 1 }}>
                                              {category.requirements && category.requirements.length
                                                ? category.requirements.map((req) => {
                                                  return (
                                                    <View key={memberReq.personCode + req.requirementCode} style={styles.buttonBorderContainer} >
                                                      <View style={{ flex: 1, minWidth: 150 }}>

                                                        <Text style={{ flex: 1, flexWrap: 'wrap' }}>{req.requirementName}</Text>
                                                      </View>
                                                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', maxWidth: 120, minWidth: 110, flex: 1 }}>

                                                          <View style={{ flex: 1, alignContent: 'center', paddingHorizontal: 5 }}>
                                                            <View style={req.userUploads.length ? { borderColor: '#20F366', borderWidth: 2, borderRadius: 5, padding: 2 } : {}
                                                            } >
                                                              <Text style={{ fontSize: 15 }}> {req.userUploads.length ? 'Updated' : ''
                                                              }</Text>
                                                              <Text style={{ fontSize: 10 }}> {req.requirementStatusCode == 'APPROVED'
                                                                ? this.formatTime(req.completedDate) : req.requirementStatusCode == 'REJECTED' ? this.formatTime(req.rejectedDate) :
                                                                  req.userUploads.length ? this.formatTime(req.userUploads[0].createdDate) : ''}</Text>
                                                            </View>
                                                          </View>
                                                        </View>
                                                      </View>
                                                      <TouchableOpacity onPress={() => this.openRequirementDetails(memberReq, req)} style={{
                                                        padding: 10, marginBottom: 0, height: 42,
                                                        backgroundColor: '#008080', borderRadius: 10, alignSelf: 'stretch'
                                                      }}>
                                                        <Icon name="edit" style={{ color: 'white', alignSelf: 'center', fontSize: 30 }} />
                                                      </TouchableOpacity>
                                                    </View>
                                                  );
                                                })
                                                : (<Text>'There are no requirements for this member'</Text>)}
                                            </View>}
                                          </View>
                                        );
                                      }

                                      )

                                      : (<Text>'There are no requirements for this member'</Text>)}

                                  </View>
                                )
                              }) : "No members Listed"}
                          </View>
                        </View>

                      )
                    })
                    : <Text>There are no requirements for this user</Text>}
                </View>
              </View>}
              <FFPModal visible={this.state.archiveModalVisible}
                onModalToggle={() => this.setArchiveModalVisible(!this.state.archiveModalVisible)}
                onRequestClose={() => this.setArchiveModalVisible(!this.state.archiveModalVisible)}
                onShow={() => this.onArchiveModalOpen}>
                <View style={{ margin: 20 }}>
                  <Text style={styles.h2b}>All Licensing Periods</Text>
                  <Text style={styles.h3}>Select a period to view</Text>
                  <View style={{ padding: 10 }} >
                    {
                      this.state.licensePeriod ? this.state.licensePeriod.map((licensePeriod, periodIndex) => {
                        let beginTime = this.formatTime(licensePeriod.fromDate, "day");
                        let endTime = this.formatTime(licensePeriod.licenseEndDate, "day");
                        if (this.state.licensePeriod.length && periodIndex + 1 < this.state.licensePeriod.length)
                          endTime = this.formatTime(this.state.licensePeriod[periodIndex + 1].fromDate, "day");
                        else
                          endTime = this.formatTime(licensePeriod.licenseEndDate, "day");

                        let periodType = periodIndex + 1 == this.state.licensePeriod.length ? "Current Renewal: " : licensePeriod.licenseTypeCode == "RENEW" ? "Renewal: " : "Initial: ";
                        let selected = false;
                        //console.log(this.state.selectedLicensePeriod.licensePeriodLevelRequirements)
                        if (this.state.selectedLicensePeriod && this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length && licensePeriod.licensePeriodCode == this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].licenseCode)
                          selected = true;


                        return (
                          <View key={periodIndex} style={selected ? { borderWidth: 1, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 5 }
                            : { padding: 2, margin: 5 }}>
                            <TouchableOpacity onPress={() => this.fetchSelectedLicensePeriod(licensePeriod.licensePeriodCode)}>
                              <Text style={styles.button1}>{periodType}{periodType == "Initial: " ? "Completed" : beginTime + " -"} {endTime}</Text>
                            </TouchableOpacity>
                          </View>
                        )
                      }) : <Text>TEXT</Text>
                    }
                  </View>
                </View>

              </FFPModal>

              <FFPModal visible={this.state.modalVisible}
                onModalToggle={() => this.setModalVisible(!this.state.modalVisible)}
                onRequestClose={() => this.setModalVisible(!this.state.modalVisible)}
                onShow={() => this.onRequirementModalOpen}>
                <SafeAreaView style={{}}>
                  <ScrollView style={{}}>
                    <View style={{}}>
                      {this.state.deletePressed &&
                        <FFPAlert title="Delete upload" desc="Are you sure you want to delete this upload?"
                          onPositivePress={() => this.deleteFile(this.state.deleteId, this.state.deleteName)}
                          onNegativePress={() => this.setState({ deletePressed: false, deleteId: null, deleteName: null })} />
                      }
                      <View>
                        <Text style={styles.h3}>
                          {this.state.selectedMemberReq && this.state.selectedMemberReq.personCode
                            ? this.state.selectedMemberReq.memberName
                            : 'Whole Family'}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.h1}>
                          {this.state.selectedReq && this.state.selectedReq.requirementCode
                            ? this.state.selectedReq.requirementName
                            : 'Empty requirement'}
                        </Text>
                      </View>
                      {/*<View >
                {this.state.selectedReq && this.state.selectedReq.userUploads.length > 0
                  ? console.log("Uploaded")
                  : <Text>Not yet uploaded</Text>}
                </View>*/}
                      <View>

                        {this.state.selectedReq && this.state.selectedReq.userUploads &&
                          <View>
                            {this.state.selectedReq.userUploads.map((userUpload, uploadFileIndex) => {
                              return (<View key={uploadFileIndex} style={{ flexDirection: 'row', padding: 5 }}>
                                <TouchableOpacity onPress={() => this.viewFile("userUpload", uploadFileIndex)}>
                                  <Text style={styles.button1}>
                                    {userUpload.documentName}
                                  </Text>
                                </TouchableOpacity>

                                <Icon name="trash" style={{ padding: 5, fontSize: 18, color: '#008080' }}
                                  onPress={() => this.setState({ deleteId: userUpload.userUploadId, deleteName: userUpload.documentName, deletePressed: true })} />
                              </View>)
                            })
                            }

                            <View>
                              {this.state.errorDeletingFileOfaRequirement && this.state.errorDeletingFileOfaRequirement.errors && this.state.errorDeletingFileOfaRequirement.errors.length &&
                                this.state.errorDeletingFileOfaRequirement.errors.map((error, errorInd) => {
                                  return (<Text style={{ color: 'red' }} key={errorInd}>{error.message}</Text>)
                                })
                              }
                            </View>
                          </View>
                        }
                      </View>
                      {this.state.selectedReq && this.state.selectedReq.canFFPUserUpload && !this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate && !this.state.selectedReq.formCode && <View>
                        
                        <View style={{ margin: 5 }}>
                          {Platform.OS == "web" ?
                            <FFPFileUpload type="file" fileChange={(e) => this.fileSelected(e)}
                              multiple={this.state.selectedReq.canHaveMultipleDocs} /> : <Text></Text>}
                          {Platform.OS != "web" ?
                            <TouchableOpacity style={{
                              padding: 0,
                              margin: 0,
                              flexDirection: 'row',
                              backgroundColor: "#008080",
                              borderRadius: 10,
                              maxWidth: 120, alignItems: 'center', justifyContent: 'center'
                            }} onPress={() => { this.selectMultipleFile(this.state.selectedReq.canHaveMultipleDocs) }}>
                              <Text style={{ color: "white", fontWeight: "bold" }}>Select File</Text>
                              <Icon name="edit" style={{ fontSize: 20, color: 'white', margin: 10 }}  ></Icon>
                            </TouchableOpacity> : <Text></Text>}
                        </View>
                        <View style={{ padding: 5 }}>
                          <Text>Selected Files: {(!this.state.selectedFiles || !this.state.selectedFiles.length) && 'There are no files selected yet.'} </Text>
                        </View>
                        {this.state.selectedFiles && this.state.selectedFiles.length &&
                          (
                            this.state.selectedFiles.map((x, selectedFileInd) => {
                              return <Text key={selectedFileInd}>{x.name}</Text>
                            })
                          )
                        }
                        <View>
                          {this.state.selectionErrorMessage
                            && this.state.selectionErrorMessage.map(msg => (<Text style={{ color: 'red' }}>{msg}</Text>))
                          }

                        </View>
                        <View style={{ padding: 5, maxWidth: 100 }}>
                          <Button style={{ borderRadius: 10, justifyContent: 'center' }} onPress={() => this.uploadFile()}
                            disabled={!this.state.selectedFiles ||
                              !this.state.selectedFiles.length ||
                              this.state.uploadFileInProgress}
                              title="Upload">
                            
                          </Button>
                        </View>
                        <View>
                          {this.state.uploadErrorMessage
                            && this.state.uploadErrorMessage.map(msg => (<Text style={{ color: 'red' }}>{msg}</Text>))
                          }
                        </View>
                      </View>}
                      {this.state.selectedReq && <View style={{ flexDirection: 'row' }}>

                        {this.state.selectedReq.hasBlankDocument && !this.state.selectedReq.formCode && <TouchableOpacity style={styles.button0} onPress={() => this.viewFile("blankDoc")}>
                          <View style={{ paddingRight: 10 }}>
                            <Text style={styles.docText}>Download</Text>
                            <Text style={styles.docText}>template</Text>
                          </View>
                          <Icon name="file" size={40} color={"white"} />
                        </TouchableOpacity>}

                        {/*!this.state.selectedReq.responseToUploads.length ? <Text></Text> : <TouchableOpacity style={styles.button0} onPress={() => this.viewFile("returnedDoc")}>
                      <View style={{ paddingRight: 10 }}>
                        <Text style={styles.docText}>Returned</Text>
                        <Text style={styles.docText}>Documents</Text>
                      </View>
                      <Icon name="file-text" size={40} color={"white"} />
                    </TouchableOpacity>*/}
                        {this.state.selectedReq.userFormIdInProgress && !this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate &&
                          <TouchableOpacity style={styles.button0} onPress={() => this.setFormVisible(!this.state.formVisible)}>
                            <View style={{ paddingRight: 10 }}>
                              <Text style={styles.docText}>Complete</Text>
                              <Text style={styles.docText}>Form</Text>
                            </View>
                            <Icon name="file-text" size={40} color={"white"} />
                          </TouchableOpacity>}
                      </View>}
                      {this.state.selectedReq && this.state.selectedReq.responseToUploads.length != 0 &&
                        <View>
                          <Text style={styles.h2b}>Returned Documents</Text>
                          <View style={{ backgroundColor: 'whitesmoke', borderRadius: 10, borderWidth: 2, borderColor: 'teal' }}>
                            {this.state.selectedReq.responseToUploads.map((facesUpload, uploadFileIndex) => {
                              return (<View key={uploadFileIndex} style={{ flexDirection: 'row', padding: 5 }}>
                                <TouchableOpacity onPress={() => this.viewFile("returnedDoc", uploadFileIndex)}>
                                  <Text style={styles.button1}>
                                    {facesUpload.documentName}
                                  </Text>
                                </TouchableOpacity>
                              </View>)
                            })
                            }

                            <View>
                              {this.state.errorDeletingFileOfaRequirement && this.state.errorDeletingFileOfaRequirement.errors && this.state.errorDeletingFileOfaRequirement.errors.length &&
                                this.state.errorDeletingFileOfaRequirement.errors.map((error, errorInd) => {
                                  return (<Text style={{ color: 'red' }} key={errorInd}>{error.message}</Text>)
                                })
                              }
                            </View>
                          </View>
                        </View>
                      }
                    </View>
                  </ScrollView>
                </SafeAreaView>
              </FFPModal>
              <FFPModal visible={this.state.formVisible}
                onModalToggle={() => this.setFormVisible(!this.state.formVisible)}
                onRequestClose={() => this.setFormVisible(!this.state.formVisible)}>
                <FormFiller req={this.state.selectedReq} />
              </FFPModal>
            </View>}
        </ScrollView >
      </SafeAreaView >
    );
  }
}
// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
  //console.log(state)
  // Redux Store --> Component
  return {
    userRequirements: licensingReduxTypes.selectors.getUserRequirements(state),
    //licensePeriods: licensingReduxTypes.selectors.getUserRequirements(state),
    isFetchingUserRequirements: licensingReduxTypes.selectors.getIsFetchingUserRequirements(state),
    errorFetchingUserRequirements: licensingReduxTypes.selectors.getErrorFetchingUserRequirements(state),
    errorDeletingFileOfaRequirement: licensingReduxTypes.selectors.getErrorDeletingDocument(state),
    userDetails: userAuthReduxTypes.selectors.getAuthDetails(state)
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  // Action
  return {
    fetchUserRequirements: (searchDetails, callback) => licensingReduxTypes.actions.fetchUserRequirements(dispatch, searchDetails, callback),
    deleteFile: (deleteDetails, callback) => licensingReduxTypes.actions.deleteUserUploadOfRequirement(dispatch, deleteDetails, callback),
    //fetchLicensePeriods: (familyDetails, callback) => licensingReduxTypes.actions.fetchOverallLicenseStatus(dispatch, familyDetails, callback),
  };
};

LicensingScreen.defaultProps = {
  userDetails: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LicensingScreen)
