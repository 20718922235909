
import { getActionCreatorName } from "autodux/src/helpers";
import * as generalHelper from "../features/common/generalHelper";

// const getActionCreatorName = () => {}


function asyncActionGenerator(dispatch, apiCallFunc, successCallback, failureCallBack, actionsToActOn, 
    dataPropName, dataValueInResp, isFetchingPropName, errorPropName) {

    const dataActionCreatorName = getActionCreatorName(dataPropName);
    const dataFetchActionCreatorName = getActionCreatorName(isFetchingPropName);
    const errorActionCreatorName = getActionCreatorName(errorPropName);

    dispatch(actionsToActOn[dataActionCreatorName](undefined));
    dispatch(actionsToActOn[dataFetchActionCreatorName](true));
    dispatch(actionsToActOn[errorActionCreatorName](false));
    
    apiCallFunc()
        .then((resp) => {
            dispatch(actionsToActOn[dataActionCreatorName](dataValueInResp ? generalHelper.resolvePath(resp, dataValueInResp) : resp));
            dispatch(actionsToActOn[dataFetchActionCreatorName](false));
            if(successCallback) {
                successCallback(resp);
            }
        })
        .catch((err) => {
            dispatch(actionsToActOn[errorActionCreatorName](err && err.response && err.response.data ? err.response.data : false));
            dispatch(actionsToActOn[dataFetchActionCreatorName](false));
            if(failureCallBack) {
                failureCallBack(err);
            }
        });

}

export {
    asyncActionGenerator
}