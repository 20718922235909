async function downloadFile(resp, fileName, successCallBack) {
    var blob = new Blob([resp.data], { type: resp.headers["content-type"] }),
        url = window.URL.createObjectURL(blob),
        a = document.createElement('a')
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);

    if (successCallBack) {
        successCallBack();
    }
}
export {downloadFile }