import React from 'react';
// import { userReduxTypes } from '../../../redux/users/user.redux';
import { TouchableHighlight, Text, TouchableOpacity, Linking, Platform, View, Image } from 'react-native'
import { connect } from 'react-redux';
import styles from '../../Admin/styles'
import { ScrollView } from 'react-native-gesture-handler';
import { userReduxTypes } from "../../../redux/users/user.redux";
import t from 'tcomb-form-native'
import { ActivityIndicator } from 'react-native-paper';
import * as moment from 'moment'
import * as apiHelper from '../../common/apiHelper'
import maskedInputTemplate from "../../common/maskedInputTemplate";
import * as generalHelper from '../../common/generalHelper'
import FFPAlert from '../../common/Alert/alert';
import DatePicker from 'react-datepicker'
//import ValidationComponent from 'react-native-form-validator';

const Form = t.form.Form;

const Email = t.refinement(t.String, email => {
    const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return reg.test(email);
});

const PhoneNumber = t.refinement(t.String, number => {
    const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    return reg.test(number);
});

const Zip = t.refinement(t.String, number => {
    const reg = /^\d{5}(-\d{4})?(?!-)$/
    return reg.test(number);
});

var Signup = t.struct({
    firstName: t.String,
    lastName: t.String,
    email: Email,
    stateCode: t.String,
    phoneNumber: PhoneNumber,
    gender: t.String,  // an optional string
    isAnotherParent: t.String,
});

var Signup2 = t.struct({

    //stateCode: t.String,
    address: t.String,
    city: t.String,
    zipCode: Zip,
    ExperienceCode: t.String,
    LicenseTypeCode: t.maybe(t.String),
    HasFFPAccount: t.String
});

var Signup3 = t.struct({

})
// const myDatePickerTemplate = (locals) => {
//     return <View><Text style={locals.stylesheet.formGroup.normal}>{locals.label}</Text><Text>Some</Text></View>
//   }
//const [startDate, setStartDate] = React.useState(null);
var options = {
    fields: {
        stateCode: {
            options: [],
            factory: t.form.Select,
            label: 'State',
            error: '* required',
            onChange: (value) => {
            },
            nullOption: { value: '', text: 'Choose your State' }
        },
        phoneNumber: {
            error: '* required. Enter a valid phone number',
            template: maskedInputTemplate,
            config: generalHelper.extTCombCompTypes.cellMaskedFormatConfig,
        },
        firstName: {
            error: "First name is required"
        },
        lastName: {
            error: "Last name is required"
        },
        // loginName: t.String, 
        // userUniqueCode: t.String,
        email: {
            label: "Email (to be used to login to this account)",
            error: "* required. Enter a valid email."
        },
        gender: {
            options: [
                { text: "Male", value: "Male" },
                { text: "Female", value: "Female" }
            ],
            factory: t.form.Select,
            error: "* required",
            onChange: (value) => {
                console.log(this)
                this.setState({ value: value })
            },
            nullOption: { value: '', text: 'Select' },
            label: "Gender"
        },
        isAnotherParent: {
            options: [
                { text: "Yes", value: "Yes" },
                { text: "No", value: "No" }
            ],
            factory: t.form.Select,
            error: "* required",
            onChange: (value) => {
                console.log(this)
                this.setState({ value: value })
            },
            nullOption: { value: '', text: 'Select' },
            label: "Is there a second adult applying to foster/adopt in this home?"
        },
        firstName2: {
            label: "First name of applicant 2",
            error: "* required"
        },
        lastName2: {
            label: "Last name of applicant 2",
            error: "* required"
        },
        email2: {
            label: "Is there another email address you would like to use to access this family account? If so, please enter here.",
            error: "* required. Enter a valid email."
        },
        phoneNumber2: {
            label: "Phone Number of applicant 2",
            error: 'Enter a valid phone number',
            template: maskedInputTemplate,
            config: generalHelper.extTCombCompTypes.cellMaskedFormatConfig,
        },
        gender2: {
            options: [
                { text: "Male", value: "Male" },
                { text: "Female", value: "Female" }
            ],
            factory: t.form.Select,
            error: "* required",
            onChange: (value) => {
                console.log(this)
                this.setState({ value: value })
            },
            nullOption: { value: '', text: 'Select' },
            label: "Gender of applicant 2"
        },
        address: { error: "* required" },
        city: { error: "* required" },
        zipCode: { error: "* required. Enter a valid zip code" },
        ExperienceCode: {
            options: [
                { text: "New", value: "New" },
                { text: "Trained", value: "Trained" },
                { text: "Transfer", value: "Transfer" },
                { text: "Licensed with TFI/PFS", value: "TFI/PFS" }
            ],
            factory: t.form.Select,
            error: "* required",
            onChange: (value) => {
                console.log(this)
                this.setState({ value: value })
            },
            nullOption: { value: '', text: 'Select' },
            label: "Experience"
        },
        IsCurrentlyLicensed: {
            options: [
                { text: "Yes", value: "true" },
                { text: "No", value: "false" }
            ],
            factory: t.form.Select,
            error: "* required",
            onChange: (value) => {
                console.log(this)
                this.setState({ value: value })
            },
            nullOption: { value: '', text: 'Select' },
            label: "Are you currently a licensed TFI or PFS Foster home?"
        },
        LicenseTypeCode: {
            options: [
                { text: "TFI", value: "TFI" },
                { text: "PFS", value: "PFS" }
            ],
            factory: t.form.Select,
            error: "* required",
            onChange: (value) => {
                console.log(this)
                this.setState({ value: value })
            },
            nullOption: { value: '', text: 'Select' },
            label: "If licensed with TFI/PFS, which one?"
        },
        HasFFPAccount: {
            options: [
                { text: "Yes", value: "true" },
                { text: "No", value: "false" }
            ],
            factory: t.form.Select,
            error: "* required",
            onChange: (value) => {
                console.log(this)
                this.setState({ value: value })
            },
            nullOption: { value: '', text: 'Select' },
            label: "Does your family already have an FFP account?"
        }
    }
}; // optional rendering options (see documentation)


class SignupScreen extends React.Component {
    constructor(props) {
        super(props);

        this.signup = this.signup.bind(this)
        this.state = {
            signupSuccess: false,
            isMobile: false,
            nav: 0,
            Signup2: undefined,
            hideDate: false
        }
        // this.state = {options: options}



    }

    componentDidMount() {
        this.detectMobileBrowser()
        apiHelper.get('/state').then((resp) => {
            console.log(resp)
            var updatedOptions = { ...options };
            updatedOptions.fields.stateCode.options = resp.data.map(x => { return { text: x.stateName, value: x.stateCode } });
            this.setState({ stateCode: 1, dateOfBirth: new Date(), signupSuccess: undefined, options: updatedOptions, Signup2: Signup2 })
        })
    }

    static navigationOptions = {
        // header: null,
        headerTitle: "Sign up"
    }

    userInputChange(fieldName, fieldValue) {
        var currentState = this.state;
        currentState[fieldName] = fieldValue
        this.setState(currentState)
    }
    validate() {
        let dateValidate = true;
        if (this.state.nav == 0) {
            if (!this.state.Date) {
                this.setState({ dateEmpty: true })
                dateValidate = false
            }
        }
        else {
            if (this.state.value.isAnotherParent == "Yes" && !this.state.Date2) {
                this.setState({ date2Empty: true })
                dateValidate = false
            }
        }
        let signupDetails = this.refs.form.getValue();
        if (signupDetails && dateValidate)
            return true
        else
            return false
    }
    signup() {

        let code;
        let signupDetails = JSON.parse(JSON.stringify(this.state.value))

        if (this.validate()) { // if validation fails, value will be null
            if (!signupDetails.stateCode)
                console.log("State Code Empty")
            else if (signupDetails.stateCode == "Kansas")
                code = "KS";
            else if (signupDetails.stateCode == "Texas")
                code = "TX";
            else if (signupDetails.stateCode == "Nebraska")
                code = "NE";
            else if (signupDetails.stateCode == "Oklahoma")
                code = "OK";
            signupDetails.dateOfBirth = this.state.Date
            signupDetails.stateCode = code
            if (signupDetails.isAnotherParent == "Yes") {
                /*signupDetails.members = "[{\"firstName\": \"" + signupDetails.firstName2 +
                    "\",	\"lastName\": \"" + signupDetails.lastName2 + "\",	\"email\": \"" + signupDetails.email2 +
                    "\",	\"gender\": \"" + signupDetails.gender2 + "\",	\"dateOfBirth\": \"" + this.state.Date2 + "\"}]";*/
                let memberArray = []
                let members = {}
                members.firstName = signupDetails.firstName2 ? signupDetails.firstName2.trim() : signupDetails.firstName2
                members.lastName = signupDetails.lastName2 ? signupDetails.lastName2.trim() : signupDetails.lastName2
                members.email = signupDetails.email2 ? signupDetails.email2.trim() : signupDetails.email2
                members.gender = signupDetails.gender2
                members.phoneNumber = signupDetails.phoneNumber2
                members.dateOfBirth = moment.default(this.state.Date2).format("MM-DD-YYYY").toString()

                
                
                memberArray.push(members)
                signupDetails.members = memberArray
                delete signupDetails.firstName2
                delete signupDetails.lastName2
                delete signupDetails.email2
                delete signupDetails.gender2
                delete signupDetails.phoneNumber2
            }
            delete signupDetails.isAnotherParent

            console.log(signupDetails)
            var signupDetToAPI = { ...signupDetails };
            signupDetToAPI.firstName = signupDetToAPI.firstName ? signupDetToAPI.firstName.trim() : signupDetToAPI.firstName;
            signupDetToAPI.lastName = signupDetToAPI.lastName ? signupDetToAPI.lastName.trim() : signupDetToAPI.lastName;
            signupDetToAPI.email = signupDetToAPI.email ? signupDetToAPI.email.trim() : signupDetToAPI.email;
            signupDetToAPI.dateOfBirth = moment.default(signupDetToAPI.dateOfBirth).format("MM-DD-YYYY").toString()
            

            this.props.signup(signupDetToAPI, (resp) => {
                console.log(resp)
                this.setState({ signupSuccess: true })
            })
        }
    }
    navigate(direction) {
        let nav = this.state.nav


        if (direction === "next") {
            if (this.validate()) {
                nav++;
                this.alterForm()
            }
        }
        if (direction === "back") {
            this.setState({ hideDate: false })
            nav--;
        }
        this.setState({ nav: nav })
    }
    alterForm() {
        let f = this.state.Signup2
        if (this.state.value.isAnotherParent === "No") {
            f = t.struct({
                address: t.String,
                city: t.String,
                zipCode: Zip,
                ExperienceCode: t.String,
                //IsCurrentlyLicensed: t.String,
                LicenseTypeCode: t.maybe(t.String),
                HasFFPAccount: t.String
            });
            this.setState({ hideDate: true, Signup2: f })
        }
        else {
            f = t.struct({
                firstName2: t.String,
                lastName2: t.String,
                phoneNumber2: PhoneNumber,
                email2: t.maybe(Email),
                gender2: t.String,
                address: t.String,
                city: t.String,
                zipCode: Zip,
                ExperienceCode: t.String,
                LicenseTypeCode: t.maybe(t.String),
                HasFFPAccount: t.String
            });
            this.setState({ hideDate: false, Signup2: f })
        }
    }
    detectMobileBrowser() {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        this.setState({ isMobile: check })
    }

    render() {
        return (

            < ScrollView style={styles.container} >
                {
                    this.state.isMobile ?
                        <View>
                            <Text style={{ fontSize: 18 }}>To signup for FFP, visit fosterfamilyportal.com on your desktop or download the app on your mobile device</Text>
                            <Text style={{ fontSize: 18, paddingTop: 10 }}>Please contact <a href="mailto:tfi-ffpsupport@tfifamily.org">tfi-ffpsupport@tfifamily.org</a> if you need assistance or support with this portal.</Text>
                            <View style={{ paddingBottom: 10, alignContent: 'center', alignSelf: 'center' }}>
                                <Image
                                    source={require('../../common/assets/tfi-ffp-app-link-logo.png')
                                    }
                                    style={{ width: 80, height: 80, margin: 5, alignSelf: 'center' }}
                                />
                                <TouchableOpacity style={{ width: 200, alignSelf: 'center' }} onPressOut={() => Linking.openURL('https://apps.apple.com/us/app/id1519723790')}>
                                    <Image source={require('../../common/assets/apple-app-store.png')
                                    } style={{ width: 200, height: 60, alignSelf: 'center' }} />
                                </TouchableOpacity>
                                <TouchableOpacity style={{ width: 180, alignSelf: 'center' }} onPressOut={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.fosterfamilyportal')}>
                                    <Image source={require('../../common/assets/get-it-on-google-play.png')
                                    } style={{ width: 235, height: 85, alignSelf: 'center' }} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        : this.state.signupSuccess != true ? <View>{!this.state.hideDate && < View style={{ paddingBottom: 15, zIndex: 1 }}>
                            <Text style={{ fontSize: 17, fontWeight: '500', paddingBottom: 5 }}>{this.state.nav == 0 ? "Date of birth *" : "Date of birth for applicant 2 *"}</Text>
                            <DatePicker style={{ zIndex: 2 }} placeholderText="Click to select a date" selected={this.state.nav == 0 ? this.state.Date : this.state.Date2}
                                showMonthDropdown
                                showYearDropdown openToDate={new Date("1970/01/01")} onChange={date => this.state.nav == 0 ? this.setState({ Date: date }) : this.setState({ Date2: date })} />
                            {this.state.nav == 0 ? this.state.dateEmpty && < Text style={{ fontSize: 17, paddingBottom: 5, color: "#af4d52" }}>
                                Date of Birth is required</Text> : this.state.date2Empty && <Text style={{ fontSize: 17, paddingBottom: 5, color: "#af4d52" }}>
                                    Date of Birth is required</Text>}
                        </View>}
                            {this.state && this.state.options && <Form
                                ref="form"
                                type={this.state.nav == 0 ? Signup : this.state.Signup2}
                                options={this.state.options}
                                value={this.state.value}
                                onChange={(value) => generalHelper.onFormChange(value, this)}
                            />}

                            {/*<Form ref="form" type={t.String}
                        value={this.state.Number} options={{ label: "Phone Number" }}
                    onChange={(value) => this.setState({ Number: value })} />*/}
                            {this.state.nav == 0 ? <View style={{ flexDirection: 'row-reverse', justifyContent: 'space-between', zIndex: -1 }}>
                                <TouchableOpacity onPressOut={() => this.navigate("next")} style={{ alignSelf: 'flex-end' }}>
                                    <Text style={styles.button1}>Next Page</Text>
                                </TouchableOpacity>
                            </View> :
                                <View style={{ flexDirection: 'row-reverse', justifyContent: 'space-between', zIndex: -1 }}>
                                    <TouchableHighlight style={styles.button} onPress={this.signup}
                                        underlayColor='#99d9f4' disabled={this.props.isSignupInProgress}>
                                        <Text style={styles.button1}>
                                            Sign up
                        </Text>
                                    </TouchableHighlight>
                                    <TouchableOpacity onPressOut={() => this.navigate("back")} style={{ alignSelf: 'flex-start' }}>
                                        <Text style={styles.button1}>Go back</Text>
                                    </TouchableOpacity>
                                </View>}
                            {this.props.isSignupInProgress && <ActivityIndicator />}
                            {this.props.errorSigningUp &&
                                <Text style={{ color: 'red' }}>
                                    {generalHelper.getErrorMessageFromErrorResp(this.props.errorSigningUp, 'Error Logging in. Please contact administrator.')}
                                </Text>}
                            <Text style={{ fontSize: 18 }}>Please contact <a href="mailto:tfi-ffpsupport@tfifamily.org">tfi-ffpsupport@tfifamily.org</a> if you need assistance or support with this portal.</Text>

                            {this.state && this.state.signupSuccess === true &&
                                <FFPAlert title="Success" desc="Successfully Signed up. Please wait until TFI processes your request"
                                    onPositivePress={() => {
                                        this.setState({ signupSuccess: true });
                                    }}
                                    onNegativePress={() => { }}
                                />
                            }</View>
                            : <View style={{ padding: 20 }}>
                                <Image
                                    source={require('../../common/assets/logo.png')
                                    }
                                    style={{ width: 375, height: 200, alignSelf: 'center' }}
                                />

                                <View style={{ padding: 0 }}>
                                    <Text style={{ textAlign: 'left', fontSize: 18 }}>
                                        Thank you for submitting your registration for the Foster Family Portal, the best way to collaborate with your agency to manage your foster parenting experience. 
                                        {"\n\n"}
                                        Your agency, {this.state.value.stateCode == "Texas" ? " Texas Family Initiative" :
                                                                                    this.state.value.stateCode == "Oklahoma" ? " TFI Family Connections" : " TFI Family Services"}, is in the process of approving your registration information so that you can start submitting and receiving important information regarding your Foster Care license as well as potential and current placements in your home. (please allow 1 to 2 business days for us to verify your account).
                                        {"\n\n"}
                                        Once your account is verified, a temporary password will be sent to the email address you used to register. This email will come from <a href="mailto:fosterfamilyportal@fostercaretech.com">fosterfamilyportal@fostercaretech.com</a>.
                                    </Text>
                                    <Text style={{ textAlign: 'left', fontSize: 18, textDecorationLine: "underline", fontWeight: "bold" }}>
                                        {"\n"}Please check your spam box for this email or make them an approved sender.   
                                    </Text>
                                    <Text style={{ textAlign: 'left', fontSize: 18 }}>
                                        {"\n"}Once you have received your temporary password, you can log in to the mobile app or go to the website at <a href="www.fosterfamilyportal.com" target="_blank">www.fosterfamilyportal.com</a>.
                                    </Text>
                                    <Text style={{ textAlign: 'left', fontSize: 18 }}>
                                        {"\n"}If you encounter any issues please contact <a href="mailto:tfi-ffpsupport@tfifamily.org">tfi-ffpsupport@tfifamily.org</a>.

                                    </Text>
                                </View>
                                <View style={{ margin: 50 }}>
                                    <TouchableHighlight style={styles.button} onPress={() => { this.props.navigation.goBack() }}
                                        underlayColor='#99d9f4' >
                                        <Text style={styles.buttonText}>
                                            Back
              </Text>
                                    </TouchableHighlight>
                                </View>

                            </View>
                }
            </ScrollView >
        );
    }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
    console.log(state)
    // Redux Store --> Component
    return {
        isSignupInProgress: userReduxTypes.selectors.getIsSignupInProgress(state),
        errorSigningUp: userReduxTypes.selectors.getErrorSignup(state),
        signupResponse: userReduxTypes.selectors.getSignupUserResponse(state),
    };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
    // Action
    return {
        // Login
        signup: (signupDetails, callback) => userReduxTypes.actions.signupUser(dispatch, signupDetails, callback, null, true),
        resetSaveUserResponse: () => userReduxTypes.actions.resetSaveUserResponse(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreen)
