import axios from 'axios';
import promise from 'promise';
import * as AsyncStorage from "./AsyncStorage/storageHelper"
import * as generalHelper from './generalHelper'

// Add a request interceptor 
var axiosInstance = axios.create();
axiosInstance.validateStatus = (status) => {
  return true; // I'm always returning true, you may want to do it depending on the status received
};

axiosInstance.interceptors.request.use(async function (config) {
  // Do something before request is sent 
  //If the header does not contain the token and the url not public, redirect to login  
  var accessToken = await getAccessToken();

  //if token is found add it to the header
  if (accessToken) {
    if (config.method !== 'OPTIONS' && config.skipToken !== true) {
          config.headers.authorization = 'Bearer ' + accessToken;
        }
  }
  return config;
}, function (error) {
   // Do something with request error 
   return promise.reject(error);
});

axiosInstance.interceptors.response.use(async function (response) {
  return response;
}, async function (error, a) {
  // console.clear();
  if(error.response) {
    if (401 === error.response.status || 403 === error.response.status) {
      AsyncStorage.deleteItem('loggedInUserDetails');
      window.location = '/AuthLoading'
    }
    else if (460 === error.response.status) {
      AsyncStorage.deleteItem('loggedInUserDetails');
      window.location = '/app/ChangePassword'
    }
  }
  return Promise.reject(error);
});

async function getAccessToken() {
  var userDetails = await AsyncStorage.getItem('loggedInUserDetails');
  if(userDetails && generalHelper.isJsonString(userDetails)) {
    userDetails = JSON.parse(userDetails)
  }
  else {
    userDetails = {};
  }
  // console.log(userDetails.token)
  return (userDetails && userDetails.token) ? userDetails.token : undefined;
}

export default axiosInstance;