
import autodux from "autodux";
import * as apiHelper from "../../features/common/apiHelper";
// import {join, o, adjust, toUpper} from 'ramda'
import * as helper from "../../features/common/generalHelper";
import * as reduxHelper from '../helper'

// const autodux = () => {return {actions: {}, selectors: {}, reducer: (state, action) => {return state || {}}}}


const initialState = {
    fosterFamilyInfoDetails: {},
    isFetchingFosterFamilyInfoDetails: false,
    errorFetchingFosterFamilyInfoDetails: false,
    
}
const fosterFamilyInfoReduxTypes = autodux({
    slice: 'fosterFamilyInfoReducer',
    initial: initialState
})

fosterFamilyInfoReduxTypes.actions = {
    ...fosterFamilyInfoReduxTypes.actions,
    fetchFosterFamilyInfoDetails: (dispatch, requestParams, successCallback, failureCallBack) => {
        const getfetchFosterFamilyInfoDetailsUrl = '/familyinfo/info';
        const apiFunc = (getfetchFosterFamilyInfoDetailsUrl, requestParams) => () => apiHelper.get(getfetchFosterFamilyInfoDetailsUrl, { params: requestParams })
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(getfetchFosterFamilyInfoDetailsUrl, requestParams),
            successCallback,
            failureCallBack,
            fosterFamilyInfoReduxTypes.actions,
            helper.getPropertyName(() => initialState.fosterFamilyInfoDetails),
            "data",
            helper.getPropertyName(() => initialState.isFetchingFosterFamilyInfoDetails),
            helper.getPropertyName(() => initialState.errorFetchingFosterFamilyInfoDetails)
        )

    },
}


export { fosterFamilyInfoReduxTypes }
