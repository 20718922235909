
import autodux from "autodux";
import * as apiHelper from "../../features/common/apiHelper";
// import { getActionCreatorName } from "autodux/src/helpers";
// import {join, o, adjust, toUpper} from 'ramda'
import * as helper from "../../features/common/generalHelper";
import * as reduxHelper from '../helper'

// const autodux = () => {return {actions: {}, selectors: {}, reducer: (state, action) => {return state || {}}}}

const initialState = {
    authDetails: undefined,
    isFetchingUserDetails: false,
    errorFetchingUserDetails: false,
  }
const userAuthReduxTypes = autodux({
    slice: 'authReducer',
    initial: initialState
  })

//   const capitalizeFirstWord = o(join(''), adjust(0, toUpper));

//   const getActionCreatorName = (key) => `set${capitalizeFirstWord(key)}`

  

  userAuthReduxTypes.actions = {
    ...userAuthReduxTypes.actions,
    fetchUserDetails: (dispatch, loginDetails, callback) => {
        const authenticateUrl = '/users/authenticate';
        const apiFunc = (authenticateUrl, loginDetails) => () => apiHelper.post(authenticateUrl, loginDetails)
        reduxHelper.asyncActionGenerator(dispatch, 
            apiFunc(authenticateUrl, loginDetails), 
            callback, 
            null,
            userAuthReduxTypes.actions,
            helper.getPropertyName(() =>  initialState.authDetails),
            "data",
            helper.getPropertyName(() => initialState.isFetchingUserDetails),
            helper.getPropertyName(() => initialState.errorFetchingUserDetails)
        )
        
    }
}


  export {userAuthReduxTypes}
  