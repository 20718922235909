import React from "react";
import { View, Text, ActivityIndicator, ScrollView, SafeAreaView, Dimensions, Platform, TouchableOpacity, Modal } from "react-native";
import { Button } from "react-native"
import { licensingReduxTypes } from './../../redux/licensing/licensing.redux';
import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import { connect } from 'react-redux';
import FFPModal from './../common/Modal/modal'
import FFPFileUpload from "../common/FileUpload/FileUpload";
import FFPAlert from '../common/Alert/alert'
import axiosInst from './../common/axiosHelper'
import FormFiller from './formFiller'
import * as moment from 'moment'
import * as generalHelper from './../common/generalHelper';

import Icon from 'react-native-vector-icons/FontAwesome';
import styles from './AppStyles';
import DocumentPicker from 'react-native-document-picker';
import FileViewer from 'react-native-file-viewer';
//import RNFS from 'react-native-fs';
//import RNFetchBlob from 'react-native-fetch-blob'
import axios from 'axios';
import * as configHelper from "./../common/configHelper";
import * as apiHelper from "./../common/apiHelper"
const apiBaseUrl = configHelper.getConfigValue('apiBaseUrl');

class LicensingScreenRedesign extends React.Component {
  constructor(props) {
    super(props);
    var deviceWidth = Dimensions.get("window").width;
    var deviceHeight = Dimensions.get("window").height;
    this.openRequirementDetails = this.openRequirementDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onRequirementModalOpen = this.onRequirementModalOpen.bind(this);
    this.loadData = this.loadData.bind(this);
    
    this.state = {
      archiveModalVisible: false,
      modalVisible: false,
      formVisible: false,
      deviceWidth: deviceWidth,
      deviceHeight: deviceHeight,
      isInitial: true,
      isSelectedLicensePeriodRenewal: false,
      wasInitial: false,
      selectedLicensePeriod: undefined,
      loaded: false,
      isOnGoingDocs: true,
      isFetchingLicensePeriods: true
    };

  }

  async selectMultipleFile(multiple) {
    //Opening Document Picker for selection of multiple file
    try {
      if (multiple) {
        const results = await DocumentPicker.pickMultiple({
          type: [DocumentPicker.types.images],
          //There can me more options as well find above
        });
        //Setting the state to show multiple file attributes
        this.setState({ selectedFiles: results })
        //this.fileSelected(results);
      }
      else {
        const results = await DocumentPicker.pick({
          type: [DocumentPicker.types.images],
          //There can me more options as well find above
        });
        const res = [];
        res.push(results); //need to put it in an array because logic in the display requires it to be one
        this.setState({ selectedFiles: res })
      }
    } catch (err) {
      //Handling any exception (If any)
      if (DocumentPicker.isCancel(err)) {
        //If user canceled the document selection
        alert('Canceled from multiple doc picker');
      } else {
        //For Unknown Error
        alert('Unknown Error: ' + JSON.stringify(err));
        throw err;
      }
    }
  }
  formatTime(prop, format) {
    if (!prop) {
      return "Just Uploaded";
    }
    if (format == "day")
      return moment.default(prop).format("MM/DD/YYYY");
    else
      return moment.default(prop).format("hh:mmA MM/DD/YYYY");
  }
  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.fetchLicensingPeriods({userUniqueCode: this.props.userDetails.userUniqueCode}, (fetchLicPerResp) => {
      const licensePeriods = fetchLicPerResp.data.licensePeriods;
      const hasMultiplePeriods  = licensePeriods &&  licensePeriods.length > 1;
      this.props.fetchUserRequirements({ userUniqueCode: this.props.userDetails.userUniqueCode }, (userRequirementsResp) => {
        this.filterDocsFromReqs(userRequirementsResp.data);
        this.setState({isInitial: !hasMultiplePeriods, isSelectedLicensePeriodRenewal: hasMultiplePeriods});
      });
    });
  }
  
  openRequirementDetails(memberReq, req) {
    this.setState({ selectedMemberReq: memberReq, selectedReq: req, selectedFiles: null, selectionErrorMessage: null });
    this.setModalVisible(!this.state.modalVisible)
  }
  openArchive() {
    this.setArchiveModalVisible(!this.state.archiveModalVisible);
  }
  setArchiveModalVisible(archiveModalVisibility) {
    this.setState({ archiveModalVisible: archiveModalVisibility })
  }
  setModalVisible(modalVisibility) {
    this.setState({ modalVisible: modalVisibility });
    this.onArchiveModalOpen();
  }
  setFormVisible(formVisibility) {
    if (!formVisibility)
      this.fetchSelectedLicensePeriod(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].licenseCode)
    this.setState({ formVisible: formVisibility, modalVisible: false })
  }

  fileSelected(e) {
    var newFileNamesToAdd = [...e.target.files].map(x => x.name);
    var errorMessages = [];
    if (this.state.selectedFiles && this.state.selectedFiles.some(x => newFileNamesToAdd.indexOf(x.name) > -1)) {
      errorMessages.push("You are trying to upload a file with the same name. Please rename and upload.")
    }
    if (this.state.selectedReq.userUploads && this.state.selectedReq.userUploads.some(x => newFileNamesToAdd.indexOf(x.documentName) > -1)) {
      errorMessages.push("There is already a file uploaded to server with this name. Please rename and upload.")
    }

    if (errorMessages && errorMessages.length) {
      this.setState({ selectionErrorMessage: errorMessages });
      e.preventDefault();
      e.target.value = '';
    }
    else {
      this.setState({ selectedFiles: [...e.target.files], selectionErrorMessage: [] })
    }
  }

  filterDocsFromReqs(reqs) {
    let reqsCopy = JSON.parse(JSON.stringify(reqs))
    let onGoingReqs = JSON.parse(JSON.stringify(reqsCopy))
    let membersToPop = []
    let memberTypeToPop = []
    let onGoingMembersToPop = []
    let onGoingMemberTypeToPop = []
    let isOnGoingDocs = false;
    if (reqs.licensePeriodLevelRequirements.length === 1) {

      //For each member type
      reqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.forEach((memberTypeReq, k) => {

        //Get corresponding Member type Level Rqwuirements from copy obj
        var cpMemberTypeLevelReqs = reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.find(memTypeCp => memTypeCp.memberTypeDescription == memberTypeReq.memberTypeDescription);
        //Get corresponding Member type Level Rqwuirements from ongoign updates obj
        var ogMemberTypeLevelReqs = onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.find(memTypeCp => memTypeCp.memberTypeDescription == memberTypeReq.memberTypeDescription);
        memberTypeReq.memberLevelRequirements.forEach(memberReq => {
          //Get corresponding Member Level Rqwuirements from member type level Requirement copy updates obj
          var cpMemberLevelReqs = cpMemberTypeLevelReqs.memberLevelRequirements.find(memLevelCp => memLevelCp.personCode == memberReq.personCode);
          //Get corresponding Member Level Rqwuirements from member type level Requirement ongoign updates obj
          var ogMemberLevelReqs = ogMemberTypeLevelReqs.memberLevelRequirements.find(memLevelCp => memLevelCp.personCode == memberReq.personCode);
          
          //Get all license categories other than Ongoing updates
          cpMemberLevelReqs.licenseCategoryLevelRequirement = memberReq.licenseCategoryLevelRequirement.filter(memberReqLicCat => memberReqLicCat.categoryDescription != "Ongoing Updates");
          
          //Get all license categories that are Ongoing updates
          var ongoingDocs = memberReq.licenseCategoryLevelRequirement.filter(memberReqLicCat => memberReqLicCat.categoryDescription == "Ongoing Updates");
          
          ogMemberLevelReqs.licenseCategoryLevelRequirement = ongoingDocs; 
          if(ongoingDocs && ongoingDocs.length) {
            isOnGoingDocs =  true;  
          }

          //Set licenseCategoryLevelRequirement from copy object to empty array if there are no requirements under that category.
          cpMemberLevelReqs.licenseCategoryLevelRequirement = cpMemberLevelReqs.licenseCategoryLevelRequirement.some(x => x.requirements && x.requirements.length) ? cpMemberLevelReqs.licenseCategoryLevelRequirement.filter(x => x.requirements && x.requirements.length) : [];
          // //Set licenseCategoryLevelRequirement from ongoing object to empty array if there are no requirements under that category.
          // ogMemberLevelReqs.licenseCategoryLevelRequirement = ogMemberLevelReqs.licenseCategoryLevelRequirement.some(x => x.requirements && x.requirements.length) ? ogMemberLevelReqs.licenseCategoryLevelRequirement : [];

        });

        //Set memberLevelRequirements from copy object to empty array if there all licenseCategoryLevel requirements under that member are empty.
        cpMemberTypeLevelReqs.memberLevelRequirements = cpMemberTypeLevelReqs.memberLevelRequirements.some(cpMemLevelReq => cpMemLevelReq.licenseCategoryLevelRequirement && cpMemLevelReq.licenseCategoryLevelRequirement.length) ? cpMemberTypeLevelReqs.memberLevelRequirements.filter(cpMemLevelReq => cpMemLevelReq.licenseCategoryLevelRequirement && cpMemLevelReq.licenseCategoryLevelRequirement.length) : [];
        // //Set memberLevelRequirements from ongoing object to empty array if there all licenseCategoryLevel requirements under that member are empty.
        // ogMemberTypeLevelReqs.memberLevelRequirements = ogMemberTypeLevelReqs.memberLevelRequirements.some(ogMemLevelReq => ogMemLevelReq.licenseCategoryLevelRequirement && ogMemLevelReq.licenseCategoryLevelRequirement.length) ? ogMemberTypeLevelReqs.memberLevelRequirements : [];
      });

      //remove all member type level requirements if there are no member under that member type from copy object.
      reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements = reqsCopy.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.filter(cpMemType => cpMemType.memberLevelRequirements && cpMemType.memberLevelRequirements.length);
      // ////remove all member type level requirements if there are no member under that member type from ongoing object.
      // onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements = onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.filter(cpMemType => cpMemType.memberLevelRequirements && cpMemType.memberLevelRequirements.length);
    }     
    this.setState({ onGoingReqs: onGoingReqs, selectedLicensePeriod: reqsCopy, isOnGoingDocs: isOnGoingDocs })
    return reqsCopy
  }

  async fetchSelectedLicensePeriod(selectedPeriod) {
    const isSelectedLicensePeriodRenewal = selectedPeriod.charAt(0) == "R"
    this.setState({ archiveModalVisible: false, isSelectedLicensePeriodRenewal: isSelectedLicensePeriodRenewal });
    let data = {
      UserUniqueCode: this.props.userDetails.userUniqueCode,
      licensePeriodCode: selectedPeriod
    };

    this.props.fetchUserRequirements(data, (reqsData) => {
      this.filterDocsFromReqs(reqsData.data);
    });
    
  }

  handleChange(event) {
    var currentState = this.state;
    currentState[event.target.name] = event.target.value
    this.setState({ ...currentState });
  }
  onRequirementModalOpen() {
    this.setState({ uploadErrorMessage: [], selectionErrorMessage: [], uploadFileInProgress: false, errorDeletingFileOfaRequirement: false })
  }
  onArchiveModalOpen() {
    this.setState({ uploadErrorMessage: [], selectionErrorMessage: [], uploadFileInProgress: false, errorDeletingFileOfaRequirement: false })
  }

  uploadFile() {

    var errorMessages = [];
    if (this.state.selectedFiles && this.state.selectedFiles.length) {
      if (!this.state.selectedReq.canFFPUserUpload) {
        errorMessages.push("This requirement requires a document to be uploaded to save.");
      }
    }
    else {
      if (!this.state.selectedReq.canFFPUserUpload) {
        errorMessages.push("This requirement does not allow user to upload doc");
      }
    }
    if (errorMessages && errorMessages.length) {
      this.setState({ uploadErrorMessage: errorMessages });
    }
    else {
      this.setState({ uploadFileInProgress: true })
      let formData = new FormData();

      // formData.append('Files', this.state.selectedFiles);
      this.state.selectedFiles.forEach((x, ind) => {
        formData.append('Files', x);
      })

      formData.append('notes', this.state.notes);
      if (this.state.selectedMemberReq.personCode)
        formData.append('personCode', this.state.selectedMemberReq.personCode);
      formData.append('UserUniqueCode', this.props.userDetails.userUniqueCode);
      formData.append('RequirementCode', this.state.selectedReq.requirementCode);

      /*
        Make the request to the POST /single-file URL
      */
      var that = this;

      axiosInst.post(`${apiBaseUrl}/licensing/family/requirement/document/add`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          }.bind(this)
        }
      ).then(function (response) {
        if (response.data.isSuccess == false && response.data.errors && response.data.errors.length) {
          that.setState({ uploadErrorMessage: response.data.errors.map(x => x.message) });
        }
        else {

          let userFiles = that.state.selectedReq.userUploads.slice();
          let selectedReqCopy = that.state.selectedReq;
          that.state.selectedFiles.forEach((x, i) => {
            let upload = {
              documentName: x.name,
              userUploadId: response.data.fileUploadDetails[i].fileUploadId,
              notes: "",
              createdDate: response.data.fileUploadDetails[i].createdDate
            };
            userFiles.push(upload);
          })
          selectedReqCopy.userUploads = userFiles
          selectedReqCopy.requirementStatusCode = "START"
          that.setState({
            uploadFileInProgress: false,
            selectedReq: selectedReqCopy,
            selectedFiles: null,

          })
        }

      })
        .catch(function (error) {
          console.log(error);
          that.setState({ uploadFileInProgress: false })
        });
    }
  }
  deleteFile(userUploadId, docName) {
    var deleteDocDetails = {
      UserUniqueCode: this.props.userDetails.userUniqueCode,
      RequirementCode: this.state.selectedReq.requirementCode,
      PersonCode: this.state.selectedMemberReq.personCode,
      UserUploadId: userUploadId,
      FileName: docName
    }
    this.props.deleteFile(deleteDocDetails, (response) => {
      if (response.data.isSuccess) {
        const items = this.state.selectedReq.userUploads.filter(x => x.userUploadId !== userUploadId);
        const selectedReq = this.state.selectedReq;
        selectedReq.userUploads = items;
        this.setState({
          selectedReq: selectedReq,
          deletePressed: false
        })
      }
      else {
        this.setState({ errorDeletingFileOfaRequirement: response.data, deletePressed: false })
      }
    })
  }
  async viewFile(fileType, uploadFileIndex) {
    let data, personcode, name;
    var isWeb = (Platform.OS == "web");
    
    var requestConfig = isWeb ? { responseType: 'blob' } : {};
    if (this.state.selectedMemberReq.personCode) //if personCode is undefined (if for whole family) set it to empty string
      personcode = this.state.selectedMemberReq.personCode;
    else
      personcode = "";
    if (fileType == "userUpload") {
      name = this.state.selectedReq.userUploads[uploadFileIndex].documentName;
      data = {
        UserUniqueCode: this.props.userDetails.userUniqueCode,
        RequirementCode: this.state.selectedReq.requirementCode,
        personCode: personcode,
        IsUserUploaded: true,
        FileName: name,
        RequireBase64: !isWeb
      };
      this.props.downloadRequirementFile(data, requestConfig, (resp) => generalHelper.saveDownloadedFile(resp, name));
    }
    else if (fileType == "blankDoc") {
      name = this.state.selectedReq.blankDocs[0];
      data = {
        UserUniqueCode: this.props.userDetails.userUniqueCode,
        RequirementCode: this.state.selectedReq.requirementCode,
        StateCode: this.props.userDetails.stateCode,
        FileName: name,
        RequireBase64: !isWeb
      };
      this.props.downloadRequirementBlankDocFile(data, requestConfig, (resp) => generalHelper.saveDownloadedFile(resp, name));
    }
    else if (fileType == "returnedDoc") {
      name = this.state.selectedReq.responseToUploads[uploadFileIndex].documentName;
      data = {
        UserUniqueCode: this.props.userDetails.userUniqueCode,
        RequirementCode: this.state.selectedReq.requirementCode,
        personCode: personcode,
        IsUserUploaded: false,
        FileName: name,
        RequireBase64: !isWeb
      };
      this.props.downloadRequirementFile(data, requestConfig, (resp) => generalHelper.saveDownloadedFile(resp, name));
    }
  }
  convertResponseToFileWeb(response, name) {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  }

  render() {
    
    return (
      <SafeAreaView style={Platform.OS == "web" ? { height: this.state.deviceHeight - 100, marginBottom: 0 } : { height: this.state.deviceHeight -120 }}>
        <ScrollView showsVerticalScrollIndicator={Platform.OS != "web"} style={{ maxWidth: this.state.deviceWidth, flex: 1 }}>
          {(this.props.isFetchingUserRequirements || this.props.isFetchingLicensePeriods) ?
            <ActivityIndicator animating={true} size="large" color="teal" style={{ padding: 200 }} /> : <View>
              <View style={styles.h1v}>
                <View style={{ flexDirection: 'row', justifyContent: Platform.OS == "web" ? 'space-between' : 'center', width: '100%' }}>
                  {Platform.OS == "web" && <Text style={styles.h1}>{(this.state.isInitial || !this.state.isSelectedLicensePeriodRenewal )  ? "Licensing" : "Renewal "}
                  </Text>}
                  {this.props.licensePeriods && this.props.licensePeriods.length > 1
                    ? <TouchableOpacity onPress={() => this.openArchive()} style={{  padding: 2, alignSelf: 'flex-end', marginBottom: 10 }}>
                      <Text style={styles.button1}>{"Archive of past periods"}</Text>
                    </TouchableOpacity> : <Text></Text>}
                </View>
                <Text style={styles.h2c}>{this.state.isInitial ? "Initial Licensing Period" :
                  !this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length ? "" : !this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate ?
                    "Renew by " + this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length != 0 ?
                      this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].nextRenewalDate :
                      this.props.licensePeriods[this.props.licensePeriods.length - 1].licenseEndDate, "day") : this.props.licensePeriods.length > 1 ?
                      "Initial License Completed " + this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].startDate, "day") : "Period from " +
                      this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].startDate, "day") + "-" +
                      this.formatTime(this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate, "day")}</Text>

              </View>
              <View style={{ /*flexWrap: 'wrap', flex: 1*/ }}>
                {!this.props.isFetchingUserRequirements && this.state.selectedLicensePeriod && this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length ?
                  this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.map((memberTypeReq, index) => {
                    //console.log(memberTypeReq)
                    return (


                      <View key={!memberTypeReq.length ? index + "f" : !memberTypeReq[0].memberTypeDescription ? index + "f" : memberTypeReq[0].memberTypeDescription} style={{ maxWidth: 1500, }}>
                        <Text style={styles.h2b}>{memberTypeReq.memberTypeDescription ? memberTypeReq.memberTypeDescription : "Whole Family"}</Text>
                        <View style={{ maxWidth: 1500, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                          {memberTypeReq.memberLevelRequirements && memberTypeReq.memberLevelRequirements.length
                            ? memberTypeReq.memberLevelRequirements.map((memberReq) => {
                              //console.log(memberReq)
                              return (
                                <View key={index + memberReq.personCode} style={{ borderWidth: 2, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 2, maxWidth: 433, minWidth: 315, flex: 1 }}>
                                  <Text style={styles.h2}>{memberReq.personCode ? memberReq.memberName : ''}</Text>
                                  {memberReq.licenseCategoryLevelRequirement && memberReq.licenseCategoryLevelRequirement.length
                                    ? memberReq.licenseCategoryLevelRequirement.map((category) => {
                                      return (
                                        <View key={memberReq.personCode + category.categoryDescription}>
                                          <View style={{ backgroundColor: "#FEFCF3", borderRadius: 5, padding: 1 }}>
                                            <Text style={styles.h3}>{category.categoryDescription}</Text>
                                            {category.requirements && category.requirements.length
                                              ? category.requirements.map((req) => {
                                                return (
                                                  <View key={memberReq.personCode + req.requirementCode} style={styles.buttonBorderContainer, {flexDirection: 'column', borderWidth: 1, borderColor: 'green', borderStyle: 'solid', paddingBottom: 3}} >
                                                    <View style={{ flex: 1, minWidth: Platform.OS == 'web' ? 150: undefined }}>

                                                      <Text style={{ flex: 1, flexWrap: 'wrap' }}>{req.requirementName}</Text>
                                                    </View>
                                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>

                                                        <View style={{ flex: 1, alignContent: 'center', paddingHorizontal: 5 }}>
                                                          <View style={[{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }, 
                                                            req.requirementStatusCode == 'APPROVED'
                                                              ? { borderColor: '#20F366', borderWidth: 2, borderRadius: 5, padding: 2 } : req.requirementStatusCode == 'REJECTED' ?
                                                                { borderColor: '#EC4911', borderWidth: 2, borderRadius: 5, padding: 2 } : req.userUploads.length ?
                                                                  { borderColor: '#FFD500', borderWidth: 2, borderRadius: 5, padding: 2 } : {}
                                                          ]} >
                                                            <Text style={{ fontSize: 15 }}> {
                                                              req.requirementStatusCode == 'APPROVED'
                                                                ? 'Completed' : req.requirementStatusCode == 'REJECTED' ? 'Rejected' : req.userUploads.length ? 'Pending' : ''
                                                            }</Text>
                                                            <Text style={{ fontSize: 10, paddingTop: 2, paddingLeft: 3 }}> {req.requirementStatusCode == 'APPROVED'
                                                              ? this.formatTime(req.completedDate) : req.requirementStatusCode == 'REJECTED' ? this.formatTime(req.rejectedDate) :
                                                                req.userUploads.length ? this.formatTime(req.userUploads[0].createdDate) : ''}</Text>
                                                          </View>
                                                        </View>
                                                      </View>
                                                    <TouchableOpacity onPress={() => this.openRequirementDetails(memberReq, req)} style={{
                                                      padding: 10, marginBottom: 0, height: 50,
                                                      backgroundColor: '#008080', borderRadius: 10, alignSelf: 'stretch'
                                                    }}>
                                                      <Icon name="edit" style={{ color: 'white', alignSelf: 'center', fontSize: 30 }} />
                                                    </TouchableOpacity>
                                                    </View>
                                                  </View>
                                                );
                                              })
                                              : (<Text>'There are no requirements for this member'</Text>)}
                                          </View>
                                        </View>
                                      );
                                    }

                                    )

                                    : (<Text>'There are no requirements for this member'</Text>)}

                                </View>
                              )
                            }) : <Text>No members Listed</Text>}
                        </View>
                      </View>

                    )
                  })
                  : <Text>There are no requirements for this user</Text>}
              </View>
              {this.state.isOnGoingDocs && <View style={{}}>
                <Text style={styles.h2d}>Documents to keep updated</Text>
                <View style={{ backgroundColor: 'whitesmoke', borderRadius: 10, borderWidth: 2, borderColor: 'teal' }}>
                  {!this.props.isFetchingUserRequirements && this.state.selectedLicensePeriod && this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length ?
                    this.state.onGoingReqs.licensePeriodLevelRequirements[0].memberTypeLevelRequirements.map((memberTypeReq, index) => {
                      //console.log(memberTypeReq)
                      return (


                        <View key={!memberTypeReq.length ? index + "f" : !memberTypeReq[0].memberTypeDescription ? index + "f" : memberTypeReq[0].memberTypeDescription} style={{ maxWidth: 1500 }}>
                          <Text style={styles.h2b}>{memberTypeReq.memberTypeDescription ? memberTypeReq.memberTypeDescription : "Whole Family"}</Text>
                          <View style={{ maxWidth: 1500, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                            {(memberTypeReq.memberLevelRequirements && memberTypeReq.memberLevelRequirements.length)
                              ? memberTypeReq.memberLevelRequirements.map((memberReq) => {
                                //console.log(memberReq)
                                return (
                                  <View key={index + memberReq.personCode} style={{ borderWidth: 2, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 2, maxWidth: 433, minWidth: 315, flex: 1, backgroundColor: 'white' }}>
                                    <Text style={styles.h2}>{memberReq.personCode ? memberReq.memberName : ''}</Text>
                                    {memberReq.licenseCategoryLevelRequirement && memberReq.licenseCategoryLevelRequirement.length
                                      ? memberReq.licenseCategoryLevelRequirement.map((category) => {
                                        return (
                                          <View key={memberReq.personCode + category.categoryDescription}>
                                            {category.categoryDescription == "Ongoing Updates" && <View style={{ backgroundColor: "#FEFCF3", borderRadius: 5, padding: 1 }}>
                                              {category.requirements && category.requirements.length
                                                ? category.requirements.map((req) => {
                                                  return (
                                                    <View key={memberReq.personCode + req.requirementCode} style={[styles.buttonBorderContainer, {flexDirection: 'column', borderWidth: 1, borderColor: 'green', borderStyle: 'solid', paddingBottom: 3, alignItems: 'flex-start'}]} >
                                                      <View style={{ flex: 1, minWidth: Platform.OS == 'web' ? 150: undefined }}>

                                                        <Text style={{ flex: 1, flexWrap: 'wrap' }}>{req.requirementName}</Text>
                                                      </View>
                                                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>

                                                          <View style={{ flex: 1, alignContent: 'center', paddingHorizontal: 5 }}>
                                                            <View style={[{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}, req.userUploads.length ? { borderColor: '#20F366', borderWidth: 2, borderRadius: 5, padding: 2 } : {}
                                                            ]} >
                                                              <Text style={{ fontSize: 15 }}> {req.userUploads.length ? 'Updated' : ''
                                                              }</Text>
                                                              <Text style={{ fontSize: 10, paddingTop: 2, paddingLeft: 3 }}> {req.requirementStatusCode == 'APPROVED'
                                                                ? this.formatTime(req.completedDate) : req.requirementStatusCode == 'REJECTED' ? this.formatTime(req.rejectedDate) :
                                                                  req.userUploads.length ? this.formatTime(req.userUploads[0].createdDate) : ''}</Text>
                                                            </View>
                                                          </View>
                                                        </View>
                                                      <TouchableOpacity onPress={() => this.openRequirementDetails(memberReq, req)} style={{
                                                        padding: 10, marginBottom: 0, height: 50,
                                                        backgroundColor: '#008080', borderRadius: 10, alignSelf: 'stretch'
                                                      }}>
                                                        <Icon name="edit" style={{ color: 'white', alignSelf: 'center', fontSize: 30 }} />
                                                      </TouchableOpacity>
                                                      </View>
                                                    </View>
                                                  );
                                                })
                                                : (<Text>There are no requirements for this member</Text>)}
                                            </View>}
                                          </View>
                                        );
                                      }

                                      )

                                      : (<Text>There are no requirements for this member</Text>)}

                                  </View>
                                )
                              }) : <Text>No members Listed</Text>}
                          </View>
                        </View>

                      )
                    })
                    : <Text>There are no requirements for this user</Text>}
                </View>
              </View>}
              <FFPModal visible={this.state.archiveModalVisible}
                onModalToggle={() => this.setArchiveModalVisible(!this.state.archiveModalVisible)}
                onRequestClose={() => this.setArchiveModalVisible(!this.state.archiveModalVisible)}
                onShow={() => this.onArchiveModalOpen}>
                <View style={{ margin: 20 }}>
                  <Text style={styles.h2b}>All Licensing Periods</Text>
                  <Text style={styles.h3}>Select a period to view</Text>
                  <View style={{ padding: 10 }} >
                    {
                      this.props.licensePeriods ? this.props.licensePeriods.map((licensePeriod, periodIndex) => {
                        let beginTime = this.formatTime(licensePeriod.fromDate, "day");
                        let endTime = this.formatTime(licensePeriod.licenseEndDate, "day");
                        if (this.props.licensePeriods.length && periodIndex + 1 < this.props.licensePeriods.length)
                          endTime = this.formatTime(this.props.licensePeriods[periodIndex + 1].fromDate, "day");
                        else
                          endTime = this.formatTime(licensePeriod.licenseEndDate, "day");
                        const isRenewal = licensePeriod.licenseTypeCode == "RENEW";

                        let periodType = periodIndex + 1 == this.props.licensePeriods.length ? "Current Renewal: " : isRenewal ? "Renewal: " : "Initial: ";
                        let selected = false;
                        //console.log(this.state.selectedLicensePeriod.licensePeriodLevelRequirements)
                        if (this.state.selectedLicensePeriod && this.state.selectedLicensePeriod.licensePeriodLevelRequirements.length && licensePeriod.licensePeriodCode == this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].licenseCode)
                          selected = true;


                        return (
                          <View key={periodIndex} style={selected ? { borderWidth: 1, borderColor: "#008080", borderRadius: 5, padding: 2, margin: 5 }
                            : { padding: 2, margin: 5 }}>
                            <TouchableOpacity onPress={() => this.fetchSelectedLicensePeriod(licensePeriod.licensePeriodCode)}>
                              <Text style={styles.button1}>{periodType}{!isRenewal ? "Completed" : beginTime + " -"} {endTime}</Text>
                            </TouchableOpacity>
                          </View>
                        )
                      }) : <Text>TEXT</Text>
                    }
                  </View>
                </View>

              </FFPModal>

              <FFPModal visible={this.state.modalVisible}
                onModalToggle={() => this.setModalVisible(!this.state.modalVisible)}
                onRequestClose={() => this.setModalVisible(!this.state.modalVisible)}
                onShow={() => this.onRequirementModalOpen}>
                <SafeAreaView style={{margin: Platform.OS == 'web' ? undefined : 5}}>
                  <ScrollView style={{height: Platform.OS == 'web' ? undefined : this.state.deviceHeight - 125}}>
                    <View style={{}}>
                      {this.state.deletePressed &&
                        <FFPAlert title="Delete upload" desc="Are you sure you want to delete this upload?"
                          onPositivePress={() => this.deleteFile(this.state.deleteId, this.state.deleteName)}
                          onNegativePress={() => this.setState({ deletePressed: false, deleteId: null, deleteName: null })} />
                      }
                      <View>
                        <Text style={styles.h3}>
                          {this.state.selectedMemberReq && this.state.selectedMemberReq.personCode
                            ? this.state.selectedMemberReq.memberName
                            : 'Whole Family'}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.h1}>
                          {this.state.selectedReq && this.state.selectedReq.requirementCode
                            ? this.state.selectedReq.requirementName
                            : 'Empty requirement'}
                        </Text>
                      </View>
                      {/*<View >
                {this.state.selectedReq && this.state.selectedReq.userUploads.length > 0
                  ? console.log("Uploaded")
                  : <Text>Not yet uploaded</Text>}
                </View>*/}
                      <View>

                        {this.state.selectedReq && this.state.selectedReq.userUploads &&
                          <View>
                            {this.state.selectedReq.userUploads.map((userUpload, uploadFileIndex) => {
                              return (<View key={uploadFileIndex} style={{ flexDirection: 'row', padding: 5, maxWidth: '90%' }}>
                                <TouchableOpacity onPress={() => this.viewFile("userUpload", uploadFileIndex)}>
                                  <Text style={styles.button1}>
                                    {userUpload.documentName}
                                  </Text>
                                </TouchableOpacity>

                                <Icon name="trash" style={{ padding: 5, fontSize: 18, color: '#008080' }}
                                  onPress={() => this.setState({ deleteId: userUpload.userUploadId, deleteName: userUpload.documentName, deletePressed: true })} />
                              </View>)
                            })
                            }

                            <View>
                              {this.state.errorDeletingFileOfaRequirement && this.state.errorDeletingFileOfaRequirement.errors && this.state.errorDeletingFileOfaRequirement.errors.length &&
                                this.state.errorDeletingFileOfaRequirement.errors.map((error, errorInd) => {
                                  return (<Text style={{ color: 'red' }} key={errorInd}>{error.message}</Text>)
                                })
                              }
                            </View>
                          </View>
                        }
                      </View>
                      {this.state.selectedReq && this.state.selectedReq.canFFPUserUpload && !this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate && !this.state.selectedReq.formCode && <View>
                        
                        <View style={{ margin: 5, maxWidth: '50%' }}>
                          
                            <FFPFileUpload type="file" fileChange={(e) => this.fileSelected(e)}
                              multiple={this.state.selectedReq.canHaveMultipleDocs} />
                        </View>
                        <View style={{ padding: 5 }}>
                          <Text>Selected Files: {(!this.state.selectedFiles || !this.state.selectedFiles.length) && 'There are no files selected yet.'} </Text>
                        </View>
                        {this.state.selectedFiles && this.state.selectedFiles.length &&
                          (
                            this.state.selectedFiles.map((x, selectedFileInd) => {
                              return <Text key={selectedFileInd}>{x.name}</Text>
                            })
                          )
                        }
                        <View>
                          {this.state.selectionErrorMessage
                            && this.state.selectionErrorMessage.map(msg => (<Text style={{ color: 'red' }}>{msg}</Text>))
                          }

                        </View>
                        <View style={{ padding: 5, maxWidth: '50%' }}>
                          <Button style={{ borderRadius: 10, justifyContent: 'center' }} onPress={() => this.uploadFile()}
                            disabled={!this.state.selectedFiles ||
                              !this.state.selectedFiles.length ||
                              this.state.uploadFileInProgress}
                            title="Upload">
                            
                          </Button>
                        </View>
                        <View>
                          {this.state.uploadErrorMessage
                            && this.state.uploadErrorMessage.map(msg => (<Text style={{ color: 'red' }}>{msg}</Text>))
                          }
                        </View>
                      </View>}
                      {this.state.selectedReq && <View style={{ flexDirection: 'row',flexWrap: Platform.OS == 'web' ? undefined : 'wrap' }}>

                        {this.state.selectedReq.hasBlankDocument && !this.state.selectedReq.formCode && <TouchableOpacity style={styles.button0} onPress={() => this.viewFile("blankDoc")}>
                          <View style={{ paddingRight: 10 }}>
                            <Text style={styles.docText}>Download</Text>
                            <Text style={styles.docText}>template</Text>
                          </View>
                          <Icon name="file" size={40} color={"white"} />
                        </TouchableOpacity>}

                        {/*!this.state.selectedReq.responseToUploads.length ? <Text></Text> : <TouchableOpacity style={styles.button0} onPress={() => this.viewFile("returnedDoc")}>
                      <View style={{ paddingRight: 10 }}>
                        <Text style={styles.docText}>Returned</Text>
                        <Text style={styles.docText}>Documents</Text>
                      </View>
                      <Icon name="file-text" size={40} color={"white"} />
                    </TouchableOpacity>*/}
                        {this.state.selectedReq.userFormIdInProgress && !this.state.selectedLicensePeriod.licensePeriodLevelRequirements[0].endDate &&
                          <TouchableOpacity style={styles.button0} onPress={() => this.setFormVisible(!this.state.formVisible)}>
                            <View style={{ paddingRight: 10 }}>
                              <Text style={styles.docText}>Complete</Text>
                              <Text style={styles.docText}>Form</Text>
                            </View>
                            <Icon name="file-text" size={40} color={"white"} />
                          </TouchableOpacity>}
                      </View>}
                      {this.state.selectedReq && this.state.selectedReq.responseToUploads.length != 0 &&
                        <View>
                          <Text style={styles.h2b}>Returned Documents</Text>
                          <View style={{ backgroundColor: 'whitesmoke', borderRadius: 10, borderWidth: 2, borderColor: 'teal' }}>
                            {this.state.selectedReq.responseToUploads.map((facesUpload, uploadFileIndex) => {
                              return (<View key={uploadFileIndex} style={{ flexDirection: 'row', padding: 5 }}>
                                <TouchableOpacity onPress={() => this.viewFile("returnedDoc", uploadFileIndex)}>
                                  <Text style={styles.button1}>
                                    {facesUpload.documentName}
                                  </Text>
                                </TouchableOpacity>
                              </View>)
                            })
                            }

                            <View>
                              {this.state.errorDeletingFileOfaRequirement && this.state.errorDeletingFileOfaRequirement.errors && this.state.errorDeletingFileOfaRequirement.errors.length &&
                                this.state.errorDeletingFileOfaRequirement.errors.map((error, errorInd) => {
                                  return (<Text style={{ color: 'red' }} key={errorInd}>{error.message}</Text>)
                                })
                              }
                            </View>
                          </View>
                        </View>
                      }
                    </View>
                  </ScrollView>
                </SafeAreaView>
              </FFPModal>
              <FFPModal visible={this.state.formVisible}
                onModalToggle={() => this.setFormVisible(!this.state.formVisible)}
                onRequestClose={() => this.setFormVisible(!this.state.formVisible)}>
                <FormFiller req={this.state.selectedReq} />
              </FFPModal>
            </View>}
        </ScrollView >
      </SafeAreaView >
    );
  }
}
// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
  //console.log(state)
  // Redux Store --> Component
  return {
    userRequirements: licensingReduxTypes.selectors.getUserRequirements(state),
    //licensePeriods: licensingReduxTypes.selectors.getUserRequirements(state),
    isFetchingUserRequirements: licensingReduxTypes.selectors.getIsFetchingUserRequirements(state),
    errorFetchingUserRequirements: licensingReduxTypes.selectors.getErrorFetchingUserRequirements(state),
    errorDeletingFileOfaRequirement: licensingReduxTypes.selectors.getErrorDeletingDocument(state),
    userDetails: userAuthReduxTypes.selectors.getAuthDetails(state),
    licensePeriods: licensingReduxTypes.selectors.getLicensePeriods(state),
    isFetchingLicensePeriods: licensingReduxTypes.selectors.getIsFetchingLicensePeriods(state),
    errorFetchingLicensePeriods: licensingReduxTypes.selectors.getErrorFetchingLicensePeriods(state),
    downloadReqFileResp: licensingReduxTypes.selectors.getDownloadReqFileResp(state),
    isDownloadReqFileInProgress: licensingReduxTypes.selectors.getIsDownloadReqFileInProgress(state),
    errorDownloadingReqFile: licensingReduxTypes.selectors.getErrorDownloadingReqFile(state),
    downloadReqBlankDocFileResp: licensingReduxTypes.selectors.getDownloadReqBlankDocFileResp(state),
    isDownloadReqBlankDocFileInProgress: licensingReduxTypes.selectors.getIsDownloadReqBlankDocFileInProgress(state),
    errorDownloadingReqBlankDocFile: licensingReduxTypes.selectors.getErrorDownloadingReqBlankDocFile(state),
    
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  // Action
  return {
    fetchUserRequirements: (searchDetails, callback) => licensingReduxTypes.actions.fetchUserRequirements(dispatch, searchDetails, callback),
    fetchLicensingPeriods: (fetchReqDetails, callback) => licensingReduxTypes.actions.fetchLicensingPeriods(dispatch, fetchReqDetails, callback),
    deleteFile: (deleteDetails, callback) => licensingReduxTypes.actions.deleteUserUploadOfRequirement(dispatch, deleteDetails, callback),
    downloadRequirementFile: (downloadReqDetails, config, successCallback, failureCallback) => licensingReduxTypes.actions.downloadRequirementFile(dispatch, downloadReqDetails, config, successCallback, failureCallback),
    downloadRequirementBlankDocFile: (downloadReqBlankDocDetails, config, successCallback, failureCallback) => licensingReduxTypes.actions.downloadRequirementBlankDocFile(dispatch, downloadReqBlankDocDetails, config, successCallback, failureCallback),
    //fetchLicensePeriods: (familyDetails, callback) => licensingReduxTypes.actions.fetchOverallLicenseStatus(dispatch, familyDetails, callback),
  };
};

LicensingScreenRedesign.defaultProps = {
  userDetails: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LicensingScreenRedesign)
