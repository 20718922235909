import React from "react";
import { View, Text, TouchableOpacity, ActivityIndicator, SafeAreaView, ScrollView, Dimensions, Platform, FlatList } from "react-native";
import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import { connect } from 'react-redux';
import FFPModal from './../common/Modal/modal'
import styles from './AppStyles';
import * as configHelper from "./../common/configHelper";
import commonStyles from "./../../Styles/commonStyles";
import * as apiHelper from '../common/apiHelper'
const apiBaseUrl = configHelper.getConfigValue('apiBaseUrl');

const headingText = Platform.select({ ios: 25, android: 20, web: 25 })
const headingButtonText = Platform.select({ ios: 24, android: 20, web: 20 })
const DEFINITELYINTERESTED = "DEFINITELYINTERESTED";
const MAYBEINTERESTED = "MAYBEINTERESTED";
const NOTINTERESTED = "NOTINTERESTED";
const selectedColor = '#008080';
const notSelectedColor = '#FFFFFF';
const selectedHoverColor = 'rgb(0,100,100)';
const notSelectedHoverColor = '#F4f4f4';

class YourMatchesScreen extends React.Component {
    constructor(props) {
        super(props);
        this.openClientDetails = this.openClientDetails.bind(this);
        this.updateInterest = this.updateInterest.bind(this);
        var deviceWidth = Dimensions.get("window").width;
        var deviceHeight = Dimensions.get("window").height;
        this.state = {
            familyMatches: undefined,
            archivedMatches: undefined,
            modalVisible: false,
            selectedClientInfo: { gender: "male", assignedIntakeWorker: "steve", siblings: [], clientId: "1" },
            selectedClientInterest: "",
            selectdClientKey: undefined,
            deviceWidth: deviceWidth,
            deviceHeight: deviceHeight,
            isFetchingMatches: true,
            interest: undefined,
            isArchive: false,
            definitelyColor: "#FFFFFF",
            maybeColor: "#FFFFFF",
            notColor: "#FFFFFF",
            hover: undefined
        };
    }
    componentDidMount() {
        this.getMatches()
    }
    async getInterest(familyMatches) {

        if (familyMatches && familyMatches.clientsData && familyMatches.clientsData.length) {
            /*let xhr = new XMLHttpRequest();
            xhr.withCredentials = false;

            xhr.addEventListener("readystatechange", () => {
                if (xhr.readyState === 4) {
                    let interestList = JSON.parse(xhr.response);
                    //console.log(interestList)
                    let interestList1 = [];
                    let interestList2 = [];
                    let isArchived = []; //array to determine if client has had interest expressed
                    let archivedMatches = [];
                    let newMatches = [];
                    //console.log(familyMatches)
                    for (let i = 0; i < familyMatches.clientsData.length; i++) {
                        for (let j = 0; j < interestList.length; j++) {
                            if (interestList[j].clientId == familyMatches.clientsData[i].clientId) {
                                interestList1.push(interestList[j].interestCode);
                                isArchived.push(1);
                                break;
                            }
                            else if (j + 1 == interestList.length) {
                                isArchived.push(0);
                                interestList1.push("")
                            }
                        }
                    }
                    //console.log(isArchived);
                    for (let i = 0; i < familyMatches.clientsData.length; i++) {
                        newMatches.push(familyMatches.clientsData[i])
                    }
                    //console.log(interestList1)
                    this.setState({ interest: interestList1, familyMatches: newMatches, archivedMatches: archivedMatches, isFetchingMatches: false, isArchived: isArchived });
                }
            });

            xhr.open("GET", `${apiBaseUrl}/your-matches/interest?userUniqueCode=${this.props.userDetails.userUniqueCode}`);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Authorization", "Bearer " + this.props.userDetails.token);
            xhr.send();*/
            let response = await apiHelper.get(`/your-matches/interest?userUniqueCode=${this.props.userDetails.userUniqueCode}`)
            if (response.status == "200") {
                let interestList = response.data;
                //console.log(interestList)
                let interestList1 = [];
                let interestList2 = [];
                let isArchived = []; //array to determine if client has had interest expressed
                let archivedMatches = [];
                let newMatches = [];
                //console.log(familyMatches)
                for (let i = 0; i < familyMatches.clientsData.length; i++) {
                    for (let j = 0; j < interestList.length; j++) {
                        if (interestList[j].clientId == familyMatches.clientsData[i].clientId) {
                            interestList1.push(interestList[j].interestCode);
                            isArchived.push(1);
                            break;
                        }
                        else if (j + 1 == interestList.length) {
                            isArchived.push(0);
                            interestList1.push("")
                        }
                    }
                }
                //console.log(isArchived);
                for (let i = 0; i < familyMatches.clientsData.length; i++) {
                    newMatches.push(familyMatches.clientsData[i])
                }
                //console.log(interestList1)
                this.setState({ interest: interestList1, familyMatches: newMatches, archivedMatches: archivedMatches, isFetchingMatches: false, isArchived: isArchived });
            }
        }
        else {
            this.setState({ interest: [], familyMatches: [], archivedMatches: [], isFetchingMatches: false, isArchived: [] });
        }
    }
    async getMatches() {

        /*let xhr = new XMLHttpRequest();
        xhr.withCredentials = false;

        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                //console.log(xhr.response);
                //this.setState({ familyMatches: JSON.parse(xhr.response), isFetchingMatches: false });
                this.getInterest(JSON.parse(xhr.response))
            }
        });

        xhr.open("GET", `${apiBaseUrl}/your-matches/clients-data?userUniqueCode=${this.props.userDetails.userUniqueCode}`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + this.props.userDetails.token);
        xhr.send();*/

        let response = await apiHelper.get(`/your-matches/clients-data?userUniqueCode=${this.props.userDetails.userUniqueCode}`)
        if (response.status == "200") {
            //console.log(xhr.response);
            //this.setState({ familyMatches: JSON.parse(xhr.response), isFetchingMatches: false });
            //this.getInterest(JSON.parse(xhr.response))
            this.getInterest(response.data)
        }

    }
    async updateInterest(interestLevel, clientKey) {

        let xhr = new XMLHttpRequest();
        let data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode +
            "\",\n	\"ClientId\": \"" + this.state.selectedClientID + "\",\n	\"InterestCode\": \"" + interestLevel + "\",\n	\"RecordStatus\": \"SAVE\"\n	\n}";
        xhr.withCredentials = false;
        let interestList = this.state.interest
        //console.log(clientKey)
        //console.log(interestList)
            this.setState({ definitelyColor: interestLevel == DEFINITELYINTERESTED ? selectedColor : notSelectedColor
                            , maybeColor: interestLevel == MAYBEINTERESTED ? selectedColor : notSelectedColor
                            , notColor: interestLevel == NOTINTERESTED ? selectedColor : notSelectedColor
                            , selectedClientInterest: interestLevel })
        //let interests = this.state.interest;
        //console.log(this.state.interest)
        //console.log(this.state.selectedClientID)
        //interests[this.state.selectedClientID] = interestLevel;
        //console.log(interests);
        //this.setState({ interest: interests })

        interestList[clientKey] = interestLevel;
        //console.log(interestList)
        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                this.setState({ interest: interestList })
                this.getMatches()
                //console.log(xhr.response);
                //console.log(data)

            }
        });

        xhr.open("POST", `${apiBaseUrl}/your-matches/family/interest/save`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + this.props.userDetails.token);
        xhr.send(data);
    }
    openArchive() {
        console.log(this.state)
        this.setState({ isArchive: !this.state.isArchive });
    }
    openClientDetails(clientInfo, clientID) {
        let nocolor = "#FFFFFF";
        let maybecolor = "#FFFFFF";
        let definitelycolor = "#FFFFFF";
        /*if (this.state.interest[clientID] == "") {
            selectedInterest = "";
        }*/
        if (this.state.isArchive) {
            if (this.state.interest[clientID] == "DEFINITELYINTERESTED") {
                definitelycolor = "#008080";
            }
            else if (this.state.interest[clientID] == "MAYBEINTERESTED") {
                maybecolor = "#008080";
            }
            else if (this.state.interest[clientID] == "NOTINTERESTED") {
                nocolor = "#008080";
            }
        }
        this.setState({
            selectedClientID: clientInfo.clientId,
            selectedClientKey: clientID,
            selectedClientInfo: clientInfo,
            selectedClientInterest: this.state.interest[clientID],
            definitelyColor: definitelycolor,
            maybeColor: maybecolor,
            notColor: nocolor
        });
        this.setModalVisible(!this.state.modalVisible)
    }
    setModalVisible(modalVisibility) {
        this.setState({ modalVisible: modalVisibility });
    }
    onMouseEnter(buttonType){
        this.setState({
            definitelyColor: buttonType == DEFINITELYINTERESTED ? (this.state.selectedClientInterest == DEFINITELYINTERESTED ?  selectedHoverColor : notSelectedHoverColor) : (this.state.selectedClientInterest == DEFINITELYINTERESTED ?  selectedColor : notSelectedColor)
            , maybeColor: buttonType == MAYBEINTERESTED ? this.state.selectedClientInterest == MAYBEINTERESTED ?  selectedHoverColor : notSelectedHoverColor : (this.state.maybeColor == MAYBEINTERESTED ?  selectedColor : notSelectedColor)
            , notColor: buttonType == NOTINTERESTED ? this.state.selectedClientInterest == NOTINTERESTED ?  selectedHoverColor : notSelectedHoverColor : (this.state.notColor == NOTINTERESTED ?  selectedColor : notSelectedColor)
             })
    }
    onMouseLeave(buttonType){
        this.setState({ 
            definitelyColor: buttonType == DEFINITELYINTERESTED ? this.state.selectedClientInterest == DEFINITELYINTERESTED ?  selectedColor : notSelectedColor : (this.state.selectedClientInterest == DEFINITELYINTERESTED ?  selectedColor : notSelectedColor)
            , maybeColor: buttonType == MAYBEINTERESTED ? this.state.selectedClientInterest == MAYBEINTERESTED ?  selectedColor : notSelectedColor : (this.state.maybeColor == MAYBEINTERESTED ?  selectedColor : notSelectedColor)
            , notColor: buttonType == NOTINTERESTED ? this.state.selectedClientInterest == NOTINTERESTED ?  selectedColor : notSelectedColor    : (this.state.notColor == NOTINTERESTED ?  selectedColor : notSelectedColor)
        })
    }
    render() {
        return (<View>
            <View style={styles.h1v}>
                <View style={{ flexDirection: 'row', justifyContent: Platform.OS == "web" ? 'space-between' : 'center' }}>
                    {Platform.OS == "web" && <Text style={[styles.h1, { marginLeft: 0, fontSize: headingText }]}>{!this.state.isArchive ? "Your Matches" : "Matches Archive"}</Text>}
                    <TouchableOpacity onPress={() => this.openArchive()} style={{ padding: 2, alignSelf: 'flex-end', marginBottom: 10, flexWrap: 'wrap' }}>
                        <Text style={[styles.button1, { fontSize: headingButtonText }]}>{!this.state.isArchive ? "To Matches Archive" : "To New Matches"}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <SafeAreaView style={Platform.OS == "web" ? { height: this.state.deviceHeight - 150, marginBottom: 0 } : { height: this.state.deviceHeight - 165 }}>
                <ScrollView>
                    <View style={{ maxWidth: 1300, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start' }}>


                        {this.state.isFetchingMatches ? <ActivityIndicator animating={this.props.isFetchingMatches} size="large" color="#0000ff" /> :
                            (!this.state.familyMatches || !this.state.familyMatches.length) ? <Text>{this.state.isArchive ? "No archived Matches" : "No Matches"}</Text> :
                                this.state.familyMatches.map((clientInfo, clientID) => {
                                    return (
                                        <View key={clientInfo.clientId}>
                                            {!this.state.isArchive && !this.state.isArchived[clientID] ?
                                                <View style={{ flex: 1, minWidth: 400, maxWidth: 425 }}>
                                                    <TouchableOpacity style={styles.card1} onPress={() => this.openClientDetails(clientInfo, clientID)}>

                                                        <View style={{ flexDirection: 'column', backgroundColor: "#FEFCF3" }}>
                                                            <View style={{ padding: 5 }}>
                                                                <Text style={styles.h2b}>Client ID: {clientInfo.clientId}</Text>
                                                                {!!this.state.interest[clientID] && <Text style={this.state.interest[clientID] == "DEFINITELYINTERESTED" ? { fontSize: 12, borderColor: '#20F366', borderWidth: 2, borderRadius: 5, padding: 2 } :
                                                                    this.state.interest[clientID] == "MAYBEINTERESTED" ? { fontSize: 12, borderColor: '#FFD500', borderWidth: 2, borderRadius: 5, padding: 2 } :
                                                                        this.state.interest[clientID] == "NOTINTERESTED" ? { fontSize: 12, borderColor: '#EC4911', borderWidth: 2, borderRadius: 5, padding: 2 } : ""}>
                                                                    {this.state.interest[clientID] == "DEFINITELYINTERESTED" ? "Definitely Interested" :
                                                                        this.state.interest[clientID] == "MAYBEINTERESTED" ? "Maybe Interested" :
                                                                            this.state.interest[clientID] == "NOTINTERESTED" ? "Not Interested" : " "}</Text>}
                                                            </View>
                                                            <View style={{ margin: 5 }}>
                                                                <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Gender: </Text><Text>{clientInfo.gender}</Text></View>
                                                                <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Age:</Text><Text> {clientInfo.age} years old</Text></View>
                                                                <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Assigned Worker:</Text><Text> {clientInfo.assignedIntakeWorker}</Text></View>
                                                                <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Siblings: </Text> 
                                                                {
                                                                            (!clientInfo.siblings || !clientInfo.siblings.length) ?  <Text>None</Text> :
                                                                            clientInfo.siblings.map((sibling, sid) => 
                                                                            <Text>
                                                                                { 
                                                                                    sibling.age + " years old " + sibling.gender + (sibling.clientId ? " (Id:"+sibling.clientId+")":"" ) + (sid == clientInfo.siblings.length - 1 ? " ":", " )
                                                                                }
                                                                            </Text>)
                                                                }
                                                                </View>
                                                                {/* <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Level of Care: </Text><Text>{!clientInfo.levelOfCare ? "Unknown" : clientInfo.levelOfCare}</Text></View> */}
                                                            </View>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                                : this.state.isArchive && this.state.isArchived[clientID] ?
                                                    <View key={clientInfo.clientId} style={{ flex: 1, minWidth: 400, maxWidth: 425 }}>
                                                        <TouchableOpacity style={styles.card1} onPress={() => this.openClientDetails(clientInfo, clientID)}>
                                                            <View style={{ flexDirection: 'column', backgroundColor: "#FEFCF3" }}>
                                                                {/* <View style={{ flex: 1, padding: 5 }}>
                                                                    <Text>{clientInfo.gender} {clientInfo.age} years old</Text>
                                                                    <Text>Assigned Worker: {clientInfo.assignedIntakeWorker}</Text>
                                                                </View>
                                                                <View style={{ flex: 1, padding: 5, alignItems: 'center' }}>
                                                                    <Text style={styles.h2b}>Client ID: {clientInfo.clientId}</Text>
                                                                    <Text style={this.state.interest[clientID] == "DEFINITELYINTERESTED" ? { fontSize: 12, borderColor: '#20F366', borderWidth: 2, borderRadius: 5, padding: 2 } :
                                                                        this.state.interest[clientID] == "MAYBEINTERESTED" ? { fontSize: 12, borderColor: '#FFD500', borderWidth: 2, borderRadius: 5, padding: 2 } :
                                                                            this.state.interest[clientID] == "NOTINTERESTED" ? { fontSize: 12, borderColor: '#EC4911', borderWidth: 2, borderRadius: 5, padding: 2 } : ""}>
                                                                        {this.state.interest[clientID] == "DEFINITELYINTERESTED" ? "Definitely Interested" :
                                                                            this.state.interest[clientID] == "MAYBEINTERESTED" ? "Maybe Interested" :
                                                                                this.state.interest[clientID] == "NOTINTERESTED" ? "Not Interested" : ""}</Text>
                                                                </View>
                                                                <View style={{ flex: 1, padding: 5 }}>
                                                                    <Text>{!clientInfo.siblings.length ? "No Siblings" : "Siblings: \n" + clientInfo.siblings[0].age + " years old " + clientInfo.siblings[0].gender}</Text>
                                                                </View> */}
                                                                <View style={{ flex: 1, padding: 5, alignItems: 'center' }}>
                                                                    <Text style={styles.h2b}>Client ID: {clientInfo.clientId}</Text>
                                                                    <Text style={this.state.interest[clientID] == "DEFINITELYINTERESTED" ? { fontSize: 12, borderColor: '#20F366', borderWidth: 2, borderRadius: 5, padding: 2 } :
                                                                        this.state.interest[clientID] == "MAYBEINTERESTED" ? { fontSize: 12, borderColor: '#FFD500', borderWidth: 2, borderRadius: 5, padding: 2 } :
                                                                            this.state.interest[clientID] == "NOTINTERESTED" ? { fontSize: 12, borderColor: '#EC4911', borderWidth: 2, borderRadius: 5, padding: 2 } : ""}>
                                                                        {this.state.interest[clientID] == "DEFINITELYINTERESTED" ? "Definitely Interested" :
                                                                            this.state.interest[clientID] == "MAYBEINTERESTED" ? "Maybe Interested" :
                                                                                this.state.interest[clientID] == "NOTINTERESTED" ? "Not Interested" : ""}</Text>
                                                                </View>
                                                                <View style={{ margin: 5 }}>
                                                                    <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Gender: </Text><Text>{clientInfo.gender}</Text></View>
                                                                    <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Age:</Text><Text> {clientInfo.age} years old</Text></View>
                                                                    <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Assigned Worker:</Text><Text> {clientInfo.assignedIntakeWorker}</Text></View>
                                                                    <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Siblings: </Text>
                                                                        {
                                                                            (!clientInfo.siblings || !clientInfo.siblings.length) ?  <Text>None</Text> :
                                                                            clientInfo.siblings.map((sibling, sid) => 
                                                                            <Text>
                                                                                { 
                                                                                    sibling.age + " years old " + sibling.gender + (sibling.clientId ? " (Id:"+sibling.clientId+")":"" ) + (sid == clientInfo.siblings.length - 1 ? " ":", " )
                                                                                }
                                                                            </Text>)
                                                                        }
                                                                    </View>
                                                                    {/* <View style={[commonStyles.childInRow, commonStyles.jusFlexSt]}><Text style={[commonStyles.boldText]}>Level of Care: </Text><Text>{!clientInfo.levelOfCare ? "Unknown" : clientInfo.levelOfCare}</Text></View> */}
                                                                </View>
                                                            </View>
                                                        </TouchableOpacity>
                                                    </View> : <View></View>}
                                        </View>

                                    )
                                })
                        }
                    </View>
                </ScrollView>
            </SafeAreaView>
            {
                this.state.isFetchingMatches ? console.log("modal loading") :
                    <SafeAreaView style={Platform.OS == "web" ? { height: "100vh", marginBottom: 0 } : { height: '100%' }}>

                        <FFPModal visible={this.state.modalVisible}
                            onModalToggle={() => this.setModalVisible(!this.state.modalVisible)}
                            onRequestClose={() => this.setModalVisible(!this.state.modalVisible)}
                        >
                            <ScrollView>
                                <View style={styles.card2}>
                                    <Text style={styles.h2b}>ID: {this.state.selectedClientInfo.clientId}</Text>
                                    <View style={{ flexDirection: 'row' }}>

                                        <View style={{ flex: 1, padding: 5 }}>
                                            <Text>Sex: {this.state.selectedClientInfo.gender} </Text>
                                            <Text>Age: {this.state.selectedClientInfo.age} years</Text>
                                            <Text>Height: {this.state.selectedClientInfo.height}</Text>
                                            <Text>Ethnicity: {this.state.selectedClientInfo.ethnicity}</Text>
                                            <Text>Race: {this.state.selectedClientInfo.race}</Text>
                                            {/* <Text>Level of Care: {!this.state.selectedClientInfo.levelOfCare ? "Unknown" : this.state.selectedClientInfo.levelOfCare}</Text> */}
                                            <Text>Religion: {!this.state.selectedClientInfo.religions ? "" : !this.state.selectedClientInfo.religions.length ? "N/A" : this.state.selectedClientInfo.religions[0]}</Text>
                                            <Text>Languages: {!this.state.selectedClientInfo.languages ? "" : this.state.selectedClientInfo.languages[0]}</Text>
                                            <Text>ICWA Status: {this.state.selectedClientInfo.icwaStatus == "" ? "N/A" : this.state.selectedClientInfo.icwaStatus}</Text>
                                        </View>
                                        <View style={{ flex: 1, padding: 5 }}>

                                            <Text>Adjudication: {this.state.selectedClientInfo.adjudication}</Text>
                                            <Text>Case Plan Goal: {this.state.selectedClientInfo.casePlanGoal}</Text>
                                            <Text>Assigned Worker: {this.state.selectedClientInfo.assignedIntakeWorker}</Text>
                                            
                                            </View>
    
    
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ flex: 1, padding: 5 }}>
                                                {
                                                    (!this.state.selectedClientInfo.siblings || !this.state.selectedClientInfo.siblings.length) ?  <Text>No Siblings</Text> : 
                                                    this.state.selectedClientInfo.siblings.map((sibling,sid) => 
                                                    <Text>
                                                        { 
                                                        (sid ==0 ? "Siblings: \n" : "")+sibling.age + " years old " + sibling.gender + (sibling.clientId ? " (Id: "+sibling.clientId+")":"" ) + (sid == this.state.selectedClientInfo.siblings.length-1 ? "" : ",")
                                                        }
                                                    </Text>)
                                                }
                                            </View>
                                            <View style={{ flex: 1, padding: 5 }}>
                                                <Text>Type of Care: {this.state.selectedClientInfo.levelOfCare}</Text>
                                            
                                            </View>
                                        </View>
                                </View>

                                {this.state.selectedClientInfo.characteristics &&
                                    <View style={{ flexDirection: 'row', padding: 5, justifyContent: 'space-around', flex: 1, minHeight: 175, maxHeight: 400 }}>
                                        {this.state.selectedClientInfo.characteristics.map((characteristic, charID) => {
                                            return (
                                                <View style={{ flex: 1 }} key={"char" + charID}>
                                                    <Text style={{ fontSize: 12, fontWeight: 'bold', alignSelf: 'center', flex: 1 }}>{characteristic.characteristicType}</Text>
                                                    <View style={{ borderBottomWidth: 1, borderTopWidth: 1, borderRadius: 10, borderColor: 'teal', flex: 5 }}>

                                                        {characteristic.characteristicName.map((name, charID) => {
                                                            return (
                                                                <Text key={name + characteristic.characteristicType + charID} style={{ flex: 1, flexWrap: 'wrap', alignSelf: 'flex-start' }}>{name}</Text>
                                                            )
                                                        })}
                                                    </View>
                                                </View>
                                            )
                                        })}

                                    </View>}

                                <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'center', justifySelf: 'flex-end' }}>
                                    
                                        <TouchableOpacity style={{
                                            padding: 10,
                                            margin: 5,
                                            flexWrap: 'wrap',
                                            backgroundColor: this.state.definitelyColor,
                                            borderTopLeftRadius: 10,
                                            borderBottomLeftRadius: 10,
                                            borderColor: '#008080',
                                            borderWidth: 1,
                                        }}
                                        onMouseEnter={() => this.onMouseEnter(DEFINITELYINTERESTED)} onMouseLeave={() => this.onMouseLeave(DEFINITELYINTERESTED)}
                                        onPressOut={() => this.updateInterest(DEFINITELYINTERESTED, this.state.selectedClientKey)}>
                                            <Text style={[styles.buttonText0, { color: this.state.definitelyColor == selectedHoverColor ? notSelectedColor : this.state.definitelyColor == selectedColor ? notSelectedColor : selectedColor }]}>Definitely{"\n"}Interested</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{
                                            padding: 10,
                                            margin: 5,
                                            flexWrap: 'wrap',
                                            backgroundColor: this.state.maybeColor,
                                            borderColor: '#008080',
                                            borderWidth: 1
                                        }}
                                        onMouseEnter={() => this.onMouseEnter(MAYBEINTERESTED)} onMouseLeave={() => this.onMouseLeave(MAYBEINTERESTED)}
                                        onPressOut={() => this.updateInterest(MAYBEINTERESTED, this.state.selectedClientKey)}>
                                            <Text style={[styles.buttonText0, { color: this.state.maybeColor == selectedHoverColor ? notSelectedColor : this.state.maybeColor == selectedColor ? notSelectedColor : selectedColor}]}>Maybe{"\n"}Interested</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{
                                            padding: 10,
                                            margin: 5,
                                            flexWrap: 'wrap',
                                            backgroundColor: this.state.notColor,
                                            borderTopRightRadius: 10,
                                            borderBottomRightRadius: 10,
                                            borderColor: '#008080',
                                            borderWidth: 1
                                        }} 
                                        onMouseEnter={() => this.onMouseEnter(NOTINTERESTED)} onMouseLeave={() => this.onMouseLeave(NOTINTERESTED)}
                                        onPressOut={() => this.updateInterest(NOTINTERESTED, this.state.selectedClientKey)}>
                                            <Text style={[styles.buttonText0, { color: this.state.notColor == selectedHoverColor ? notSelectedColor : this.state.notColor == selectedColor ? notSelectedColor : selectedColor}]}>Not{"\n"}Interested</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <View style={{padding: 10}}>
                                <Text style={{ fontStyle: 'italic', fontSize: 14}}>
                                TFI is dedicated to placing children in homes that best match their needs.
                                If you are not contacted about a child, it is because the child may have been placed in a different home.
                                If you have questions about our matching process please reach out to your Foster Care Worker.
                                </Text>
                                </View>
                            </ScrollView>
                        </FFPModal>
                    </SafeAreaView>
            }

        </View >
        );
    }

}

const mapStateToProps = (state) => {
    //console.log(state)
    // Redux Store --> Component
    return {
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state)
    };
};
export default connect(mapStateToProps)(YourMatchesScreen)
