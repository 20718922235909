import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import * as AsyncStorage from "./../../features/common/AsyncStorage/storageHelper";
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/index';

// Middleware: Redux Persist Config
const persistConfig = {
  timeout: 0, // The code base checks for falsy, so 0 disables
  // Root
  key: 'root',
  // // Storage Method (React Native)
  storage: AsyncStorage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'authReducer',
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    
  ],
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);
// Redux: Store
const store = createStore(
  persistedReducer,
  applyMiddleware(
    //createLogger(),
  ),
);

// // Middleware: Redux Persist Persister
let persistor = persistStore(store);
// Exports
export {
  store,
  persistor,
};