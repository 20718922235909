import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    baseContainer: {
        flex: 1,
        //justifyContent: 'center',
        alignItems: 'center'
    },
    buttonBorderContainer: {
        paddingLeft: 5,
        //justifyContent: 'left',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        borderRadius: 5,
        borderColor: "bisque",
        borderBottomWidth: 1,
        borderTopWidth: 1
        //margin: 2

    },
    buttonBorderContainer2: {
        alignItems: 'center',
        borderWidth: 1,
        height: 50,
        width: 100,
        flexDirection: 'row',
        backgroundColor: 'teal'
    },
    h1: {
        //textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        fontSize: 30,
        fontWeight: 'bold',
        marginTop: 10,
        flexWrap: 'wrap'
    },
    h1v: {
        borderStyle: 'solid',
        borderColor: '#008080',
        borderBottomWidth: 2,
        margin: 10
    },
    h2b: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        //borderBottomWidth: 2,
        fontSize: 26,
        fontWeight: 'bold'
    },
    h2: {
        textAlign: 'left',
        borderStyle: 'solid',
        borderColor: '#008080',
        //borderBottomWidth: 2,
        fontSize: 20,
        fontWeight: 'bold',
        color: '#008080'
    },
    h2c:
    {
        textAlign: 'center',
        borderStyle: 'solid',
        color: '#008080',
        //borderBottomWidth: 2,
        fontSize: 20,
        fontWeight: 'bold'
    },
    h3: {
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#008080',
        //borderBottomWidth: 2,
        fontSize: 20
    },
    docText:
    {
        color: 'white'
    },
    button0:
    {
        padding: 10,
        margin: 5,
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: "#008080",
        borderRadius: 10,
    },
    button1:
    {
        borderRadius: 5,
        padding: 5,
        fontSize: 17,
        fontWeight: "bold",
        color: "white",
        backgroundColor: "teal",
        maxWidth: 400
    }

})