import React from 'react';
import { Text, View } from 'react-native';
import styles from './../Admin/styles'

export default class GuestUserScreen extends React.Component {
  constructor(props) {
    super(props);

    this.onPress = this.onPress.bind(this);
  }
  
    onPress() {
      // call getValue() to get the values of the form
      var value = this.refs.form.getValue();
      if (value) { // if validation fails, value will be null
        console.log(value); // value here is an instance of Person
      }
    }
  
    render() {
      return (
        <View style={styles.container}>
          {/* display */}
             
                <View>
                    <Text>
                        Thank you for submitting you registrationfor the Foster
                        Family Portal, the best way to collaborate with your agency
                        to manage your foster parenting experience.
                    </Text>
                </View>
                <View>
                    <Text>
                        Your agency, Agency Name, is in the process of approving your registration
                        information so that oyu can start submitting and receiving important
                        foster parent documents.
                    </Text>
                </View>
                <View>
                    <Text>
                        Please check your email for login creds that will be sent after your account 
                        has been verified in TFI's system.
                    </Text>
                </View>
                <View>
                    <Text>
                        Once you have received your credentialsyou can login to this mobile app.
                    </Text>
                </View>
          
        </View>
      );
    }
}


  