import React from 'react';
import { View, Text, Image, StatusBar, TouchableOpacity } from 'react-native';

const LOGO_URL = '../assets/logo.png';

const DrawerHeader = ({ navigateToCallback, navigation }) => (
	<TouchableOpacity onPress={() => {navigateToCallback('Notifications'); navigation.closeDrawer();}}>
		<View
			style={{
				flexDirection: 'column',
				backgroundColor: 'white',
				paddingVertical: 14,
				paddingHorizonal: 5,
				paddingTop: 0,
				alignItems: 'center',
			}}
		>

			<Text style={{ color: '#FFF', paddingLeft: 0, fontSize: 16 }}>
				Foster Family Portal
			</Text>
			<Image
				source={require('../common/assets/logo.png')
				}
				style={{ width: 160, height: 80 }}
			/>
		</View>
	</TouchableOpacity>
);

export default DrawerHeader;
