//https://www.freecodecamp.org/news/how-to-build-a-nested-drawer-menu-with-react-native-a1c2fdcab6c9/
//https://github.com/appbaseio-apps/native-kitchensink
import React, { Component } from 'react';
import {
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	ScrollView,
} from 'react-native';
import { connect } from 'react-redux';
import { DrawerItems } from 'react-navigation-drawer';
import Icon from 'react-native-vector-icons/FontAwesome'

import { evaluateOuterDrawerListItems } from './utils';
import OuterDrawerItem from './OuterDrawerItem';
import DrawerHeader from './DrawerHeader';
import { SignoutScreen } from '../Authentication';
import GuestUserScreen from '../Home/guestUserScreen';
import { userAuthReduxTypes } from './../../redux/users/auth.redux'

const styles = StyleSheet.create({
	customDrawerTouch: {
		paddingLeft: 13,
		paddingTop: 15,
	},
	customDrawerIcon: { paddingRight: 10 },
	backButtonRow: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingBottom: 17,
		paddingLeft: 3,
		borderBottomColor: '#F0F0F0',
		borderBottomWidth: 1,
	},
});

class MainDrawer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mainDrawer: true
		};
	}

	toggleMainDrawer = () =>
		this.setState(prevState => ({ mainDrawer: !prevState.mainDrawer }));

	renderMainDrawerComponents = mainDrawerItems =>
		(
			<View>
				{Object.keys(mainDrawerItems).map((item, index) => {
					const drawerItem = mainDrawerItems[item]
					return (
						<OuterDrawerItem
							key={index}
							label={item == "CurrentPlacements" ?
								"Current Placements" : item == "YourMatches" ?
									"Your Matches" : item == "FosterParentInfo" ?
										"Foster Family Info" : item == "Licensing" ? "Licensing & Renewal" :
											item}
							expandable={drawerItem.end > 0 ? true : false}
							onPress={() => {
								drawerItem.end > 0 ? this.setState({
									currentComponent: item,
									mainDrawer: false,
								}) : this.navigateToCallback(item)
							}}
						/>
					)
				})}
				<View style={{ marginTop: 100 }}>
					<SignoutScreen />
				</View>
			</View>
		);

	navigateToCallback = routeName => {
		this.setState({ mainDrawer: true });
		this.props.navigation.navigate(routeName);
	};

	render() {
		const { items, ...restProps } = this.props;
		const { mainDrawer, currentComponent } = this.state;

		// get items objects with unique items and indexes
		const scopedItemsObject = evaluateOuterDrawerListItems(items);

		if (mainDrawer) {
			return (
				<ScrollView>
					<DrawerHeader navigateToCallback={this.navigateToCallback} navigation={this.props.navigation} />
					{this.renderMainDrawerComponents(scopedItemsObject)}
				</ScrollView>
			);
		}

		const index = scopedItemsObject[currentComponent];

		const scopedItemsArr = items.slice(index.start, index.end);

		return (
			<ScrollView style={{ color: 'red' }}>
				<DrawerHeader navigateToCallback={this.navigateToCallback} />
				<TouchableOpacity
					onPress={this.toggleMainDrawer}
					style={styles.customDrawerTouch}
				>
					<View style={styles.backButtonRow}>
						<Icon
							name="ios-arrow-back"
							size={25}
							style={styles.customDrawerIcon}
							color="#666666"
						/>
						<Text style={{ color: '#666666' }}>Back to Components</Text>
					</View>
				</TouchableOpacity>
				<DrawerItems items={scopedItemsArr} {...restProps} labelStyle={{ color: '#000000' }} />
			</ScrollView>
		);
	}
}


// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
	return {
		userDetails: userAuthReduxTypes.selectors.getAuthDetails(state)
	};
};


export default connect(mapStateToProps)(MainDrawer);
