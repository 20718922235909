import React from "react";
import Icon from "react-native-vector-icons/FontAwesome";
import axiosInst from '../axiosHelper'

const maxFileSizeAllowedInMb = 10
export default class FFPFileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.fileChange = this.fileChange.bind(this);
        this.state = { selectedFile: null };
    }

    fileChange(e) {
        console.log(e.target.value)

        if(e.target && e.target.files && e.target.files.length) {
            const fileHavingLargeSize = [... e.target.files].find(x => x.size > maxFileSizeAllowedInMb*1024*1024)
            if(fileHavingLargeSize) {
                alert(fileHavingLargeSize.name + " - filesize cannot be greater than " + maxFileSizeAllowedInMb + " MB.")
            }
            else{
                this.props.fileChange(e)
            }
        }
    }

    uploadFile() {

    }
    submitFile() {
    }

    render() {
        return <input type="file" onChange={this.fileChange} multiple={this.props.multiple} />
    }
}