import React, { useState } from "react";
import { View, Text } from "react-native";
import { ScrollView, SafeAreaView, Dimensions, Platform, ActivityIndicator, StyleSheet, RefreshControl, TouchableOpacity, TextInput, Button, PermissionsAndroid } from 'react-native';
import FFPLabelWithValue from './../common/controls/ffpLabelWithValue'
import FFPLabel from './../common/controls/ffpLabel';
import FFPModal from './../common/Modal/modal'
import FFPAlert from './../common/Alert/alert'
import FFPFileUpload from "../common/FileUpload/FileUpload";
import * as generalHelper from './../common/generalHelper';
import styles from "./../../Styles/commonStyles";
import currPlacementStyles from "./currentPlacementStyles";
import { currentPlacementReduxTypes } from './../../redux/users/currentplacements.redux'
import { userAuthReduxTypes } from './../../redux/users/auth.redux';
import { connect } from 'react-redux';
import * as moment from 'moment';
import currentPlacementStyles from "./currentPlacementStyles";
import commonStyles from "./../../Styles/commonStyles";

const allMarPadBor = [styles.basicBorder, styles.allPadding10, styles.allMargin10]
class CurrentPlacementsHomeScreen extends React.Component {
    constructor(props) {
        super(props);

        this.onRefresh = this.onRefresh.bind(this);

        this.state = {
            openUploadUserDoc: false,
            deletePressed: false
        };

            
        
        this.goToDetail = this.goToDetail.bind(this);
    }

    componentDidMount() {
        if(!this.props.currentPlacements || !this.props.currentPlacements.clients) {
            this.props.fetchCurrentPlacementsForFamily({ UserUniqueCode: this.props.userDetails.userUniqueCode });
        }
    }

    goToDetail(plDetail){
        this.props.navigation.navigate(
            'Detail',
            { clientId: plDetail.clientId },
          );
    }

    onRefresh() {
        this.setState({ refreshing: true});
        this.props.fetchCurrentPlacementsForFamily({ UserUniqueCode: this.props.userDetails.userUniqueCode }, () => {
            this.setState({ refreshing: false});
        });
    }

    render() {
        return (
            <SafeAreaView style={Platform.OS == "web" ? { height: "90vh", marginBottom: 0 } : {}}>
                <ScrollView showsVerticalScrollIndicator={false} style={styles.paddingBottom}
                    refreshControl={
                        <RefreshControl refreshing={this.state.refreshing} onRefresh={this.onRefresh} />
                    }>

                    {Platform.OS == "web" && <Text style={[styles.h1, styles.topPadding10]}>Current Placements</Text>}

                    <View style={[Platform.OS == "web" ? styles.childInRow : styles.childInCol, { width: '100%'}]}>
                    {this.props.isFetchingCurrentPlacements ? <ActivityIndicator /> : (this.props.currentPlacements && this.props.currentPlacements.clients && this.props.currentPlacements.clients.length) ?
                        this.props.currentPlacements.clients.map(plDetail => (
                            <View style={[currentPlacementStyles.homeScreenClientWidth, currentPlacementStyles.cardBackgroundColor, styles.card1, { borderWidth: 1, margin: 10, borderRadius: 10 }]}>
                                
                                <View style={[styles.childInCol, {alignItems: 'center'}]}>
                                <View style={[styles.allMargin10, styles.childInRow, {justifyContent: 'flex-start', flexDirection: 'row'}]}><TouchableOpacity onPress={() => this.goToDetail(plDetail)} style={{flexDirection: 'row'}}><Text style={{fontSize: 30}}>Client ID:</Text><Text style={{fontSize: 30, flexDirection: 'row-reverse', color: '#888888'}}>{plDetail.clientId}</Text></TouchableOpacity></View>
                                <View style={[ styles.childInRow]}><Text style={[currPlacementStyles.textSize]}>{`${plDetail.gender}`}</Text></View>
                                <View style={[styles.childInRow]}><Text style={[currPlacementStyles.textSize]}>{`DOB: ${plDetail.fmtDateOfBirth || 'Unknown'}`}</Text></View>
                                <View style={[styles.childInRow, {justifyContent: 'flex-start'}]}><Text style={[currPlacementStyles.textSize]}>Permanency Case Manager{Platform.OS == "android" ? " " : ""}: {plDetail.permanencyCaseManager}</Text></View>
                                <View style={[styles.childInRow]}><Text style={[currPlacementStyles.textSize]}>Date sent: {plDetail.fmtPlacementStartDate || 'Unknown'}</Text></View>
                        
            
        </View>
                            </View>
                        ))
                        : (<FFPLabel labelName="There are no current placements for this family." />)}
                        </View>
                </ScrollView>

            </SafeAreaView>
        );
    }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
    //console.log(state)
    // Redux Store --> Component
    return {
        currentPlacements: currentPlacementReduxTypes.selectors.getCurrentPlacements(state),
        isFetchingCurrentPlacements: currentPlacementReduxTypes.selectors.getIsFetchingCurrentPlacements(state),
        errorFetchingCurrentPlacements: currentPlacementReduxTypes.selectors.getErrorFetchingCurrentPlacements(state),
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state),
    };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
    // Action
    return {
        fetchCurrentPlacementsForFamily: (requestObj, callback) => currentPlacementReduxTypes.actions.fetchCurrentPlacements(dispatch, requestObj, callback),
    };
};

CurrentPlacementsHomeScreen.defaultProps = {
    userDetails: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlacementsHomeScreen)
