
import autodux from "autodux";
import * as apiHelper from "../../features/common/apiHelper";
// import { getActionCreatorName } from "autodux/src/helpers";
// import {join, o, adjust, toUpper} from 'ramda'
import * as helper from "../../features/common/generalHelper";
import * as reduxHelper from '../helper'

// const autodux = () => {return {actions: {}, selectors: {}, reducer: (state, action) => {return state || {}}}}

const initialState = {
    overAllLicenseDetails: undefined,
    isFetchingLicenseDetails: false,
    errorFetchingLicenseDetails: undefined,
    userRequirements: false,
    isFetchingUserRequirements: false,
    errorFetchingUserRequirements: undefined,
    uploadDocToReqDetails: undefined,
    isUploadDocToReqInProgress: undefined,
    errorUploadDocToReq: undefined,
    deleteDocumentResponse: undefined,
    isDeleteDocumentInProgress: false,
    errorDeletingDocument: undefined,
    isInitial: true,
    licensePeriods: [],
    isFetchingLicensePeriods: false,
    errorFetchingLicensePeriods: undefined,
    downloadReqFileResp: undefined,
    isDownloadReqFileInProgress: undefined,
    errorDownloadingReqFile: undefined,
    downloadReqBlankDocFileResp: undefined,
    isDownloadReqBlankDocFileInProgress: undefined,
    errorDownloadingReqBlankDocFile: undefined,
}
const licensingReduxTypes = autodux({
    slice: 'licensingReducer',
    initial: initialState
})

//   const capitalizeFirstWord = o(join(''), adjust(0, toUpper));

//   const getActionCreatorName = (key) => `set${capitalizeFirstWord(key)}`



licensingReduxTypes.actions = {
    ...licensingReduxTypes.actions,
    fetchUserRequirements: (dispatch, searchDetails, callback) => {
        const fetchUserRequirementsUrl = `/licensing/family/requirements/search`;
        const apiFunc = (fetchUserRequirementsUrl, searchDetails) => () => apiHelper.post(fetchUserRequirementsUrl, searchDetails)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(fetchUserRequirementsUrl, searchDetails),
            callback,
            null,
            licensingReduxTypes.actions,
            helper.getPropertyName(() => initialState.userRequirements),
            "data",
            helper.getPropertyName(() => initialState.isFetchingUserRequirements),
            helper.getPropertyName(() => initialState.errorFetchingUserRequirements)
        )

    }
}
/*licensingReduxTypes.actions = {
    ...licensingReduxTypes.actions,
    fetchOverallLicenseStatus: (dispatch, familyDetails, callback) => {
        const fetchOverallLicenseStatusUrl = `/licensing/family/requirements/overall-status`;
        const apiFunc = (fetchOverallLicenseStatusUrl, familyDetails) => () => apiHelper.get(fetchOverallLicenseStatusUrl + "?UserUniqueCode=" + familyDetails);
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(fetchOverallLicenseStatusUrl, familyDetails),
            callback,
            null,
            licensingReduxTypes.actions,
            helper.getPropertyName(() => initialState.overAllLicenseDetails),
            "data",
            helper.getPropertyName(() => initialState.isFetchingLicenseDetails),
            helper.getPropertyName(() => initialState.errorFetchingLicenseDetails)
        )

    }
}*/
licensingReduxTypes.actions = {
    ...licensingReduxTypes.actions,
    uploadDocToRequirement: (dispatch, docDetails, callback) => {
        const addDocumentUrl = `/licensing/family/requirement/document/add`;
        const apiFunc = (addDocumentUrl, docDetails) => () => apiHelper.post(addDocumentUrl, docDetails)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(addDocumentUrl, docDetails),
            callback,
            null,
            licensingReduxTypes.actions,
            helper.getPropertyName(() => initialState.deleteDocument),
            "data",
            helper.getPropertyName(() => initialState.isUploadDocToReqInProgress),
            helper.getPropertyName(() => initialState.errorUploadDocToReq)
        )

    }
}

licensingReduxTypes.actions = {
    ...licensingReduxTypes.actions,
    deleteUserUploadOfRequirement: (dispatch, deleteDetails, callback) => {
        const deleteDocumentUrl = `/licensing/family/requirement/document/delete`;
        const apiFunc = (deleteDocumentUrl, docDetails) => () => apiHelper.post(deleteDocumentUrl, deleteDetails)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(deleteDocumentUrl, deleteDetails),
            callback,
            null,
            licensingReduxTypes.actions,
            helper.getPropertyName(() => initialState.deleteDocumentResponse),
            "data",
            helper.getPropertyName(() => initialState.isDeleteDocumentInProgress),
            helper.getPropertyName(() => initialState.errorDeletingDocument)
        )

    }
}

licensingReduxTypes.actions = {
    ...licensingReduxTypes.actions,
    downloadRequirementFile: (dispatch, downloadReqDetails, config, successCallback, failureCallBack) => {
        const downloadReqUrl = `/document/download/requirement`;
        const apiFunc = (downloadReqUrl, downloadReqDetails) => () => apiHelper.post(downloadReqUrl, downloadReqDetails, config)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(downloadReqUrl, downloadReqDetails),
            successCallback,
            failureCallBack,
            licensingReduxTypes.actions,
            helper.getPropertyName(() => initialState.downloadReqFileResp),
            "data",
            helper.getPropertyName(() => initialState.isDownloadReqFileInProgress),
            helper.getPropertyName(() => initialState.errorDownloadingReqFile)
        )

    }
}
licensingReduxTypes.actions = {
    ...licensingReduxTypes.actions,
    downloadRequirementBlankDocFile: (dispatch, blankDocDownloadReqDetails, config, successCallback, failureCallBack) => {
        const blankDocDownloadUrl = `/document/download/requirement/blank-document`;
        const apiFunc = (blankDocDownloadUrl, blankDocDownloadReqDetails) => () => apiHelper.post(blankDocDownloadUrl, blankDocDownloadReqDetails, config)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(blankDocDownloadUrl, blankDocDownloadReqDetails),
            successCallback,
            failureCallBack,
            licensingReduxTypes.actions,
            helper.getPropertyName(() => initialState.downloadReqBlankDocFileResp),
            "data",
            helper.getPropertyName(() => initialState.isDownloadReqBlankDocFileInProgress),
            helper.getPropertyName(() => initialState.errorDownloadingReqBlankDocFile)
        )

    }
}

licensingReduxTypes.actions = {
    ...licensingReduxTypes.actions,
    fetchLicensingPeriods: (dispatch, fetchReqDetails, callback) => {
        const fetchLicPeriodsUrl = `/licensing/family/requirements/overall-status?UserUniqueCode=` + fetchReqDetails.userUniqueCode;
        const apiFunc = (fetchLicPeriodsUrl, fetchReqDetails) => () => apiHelper.get(fetchLicPeriodsUrl, fetchReqDetails)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(fetchLicPeriodsUrl, fetchReqDetails),
            callback,
            null,
            licensingReduxTypes.actions,
            helper.getPropertyName(() => initialState.licensePeriods),
            "data.licensePeriods",
            helper.getPropertyName(() => initialState.isFetchingLicensePeriods),
            helper.getPropertyName(() => initialState.errorFetchingLicensePeriods)
            
        )

    }
}

export { licensingReduxTypes }
