
import autodux from "autodux";
import * as apiHelper from "../../features/common/apiHelper";
// import {join, o, adjust, toUpper} from 'ramda'
import * as helper from "../../features/common/generalHelper";
import * as reduxHelper from '../helper'

// const autodux = () => {return {actions: {}, selectors: {}, reducer: (state, action) => {return state || {}}}}


const initialState = {
    currentPlacements: {},
    isFetchingCurrentPlacements: false,
    errorFetchingCurrentPlacements: false,
    //Upload and associate user upload to client placement
    uploadUserDocResponse: {},
    isUploadingUserDoc: false,
    errorUploadingUserDoc: false,
    //Delete user upload doc
    deleteUserDocResponse: {},
    isDeletingUserDoc: false,
    errorDeletingUserDoc: false,
}
const currentPlacementReduxTypes = autodux({
    slice: 'currentPlacementsReducer',
    initial: initialState
})

currentPlacementReduxTypes.actions = {
    ...currentPlacementReduxTypes.actions,
    fetchCurrentPlacements: (dispatch, requestParams, successCallback, failureCallBack) => {
        const getfetchCurrentPlacementsUrl = '/current-placements/search';
        const apiFunc = (getfetchCurrentPlacementsUrl, requestParams) => () => apiHelper.post(getfetchCurrentPlacementsUrl, requestParams)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(getfetchCurrentPlacementsUrl, requestParams),
            successCallback,
            failureCallBack,
            currentPlacementReduxTypes.actions,
            helper.getPropertyName(() => initialState.currentPlacements),
            "data",
            helper.getPropertyName(() => initialState.isFetchingCurrentPlacements),
            helper.getPropertyName(() => initialState.errorFetchingCurrentPlacements)
        )

    },
    uploadUserDocAndAssociateToClient: (dispatch, requestParams, config, successCallback, failureCallBack) => {
        const uploadAndAssociateCurrentPlacementsUrl = '/current-placements/client/document/upload-associate';
        const apiFunc = (uploadAndAssociateCurrentPlacementsUrl, requestParams) => () => apiHelper.post(uploadAndAssociateCurrentPlacementsUrl, requestParams, config)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(uploadAndAssociateCurrentPlacementsUrl, requestParams),
            successCallback,
            failureCallBack,
            currentPlacementReduxTypes.actions,
            helper.getPropertyName(() => initialState.uploadUserDocResponse),
            "data",
            helper.getPropertyName(() => initialState.isUploadingUserDoc),
            helper.getPropertyName(() => initialState.errorUploadingUserDoc)
        )

    },
    deleteUserDocFromClient: (dispatch, requestParams, successCallback, failureCallBack) => {
        const removeUserDocForClientUrl = '/current-placements/client/document/remove';
        const apiFunc = (removeUserDocForClientUrl, requestParams) => () => apiHelper.post(removeUserDocForClientUrl, requestParams)
        reduxHelper.asyncActionGenerator(dispatch,
            apiFunc(removeUserDocForClientUrl, requestParams),
            successCallback,
            failureCallBack,
            currentPlacementReduxTypes.actions,
            helper.getPropertyName(() => initialState.deleteUserDocResponse),
            "data",
            helper.getPropertyName(() => initialState.isDeletingUserDoc),
            helper.getPropertyName(() => initialState.errorDeletingUserDoc)
        )

    },
}


export { currentPlacementReduxTypes }
