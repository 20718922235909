import * as moment from 'moment';
import t from 'tcomb-form-native';
import {Platform , PermissionsAndroid } from 'react-native';
import FileViewer from 'react-native-file-viewer';
import {downloadFile} from "./fileDownload";

const resolvePath = (object, path, defaultValue) => path
   .split('.')
   .reduce((o, p) => o ? o[p] : defaultValue, object)


/**
 * 
 * @param {This function has a format of arrow function. Do not include bracket(}) for function return value.
 * USE this format                      () => obj.propertyNameToBeReturnedAsString
 * DONOT use this format                () => {return obj.propertyNameToBeReturnedAsString}
 * } propertyFunction 
 */
const getPropertyName = function(propertyFunction) {
    var results=  [];
    //eslint-disable-line no-useless-escape
    results = /\.([^\.;]+)[;\s]*[\}]*$/.exec(propertyFunction.toString())
    if(!results || !results.length || (results.length == 2 && results[1].indexOf("}") > -1)) {
        //eslint-disable-line no-useless-escape
        results = /\.([^\.;]+)[;\s]*[\}]+$/.exec(propertyFunction.toString()) 
    }
    return results[1];
}

//https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try/3710226
const isJsonString= function(jsonData) {
    if (typeof jsonData == 'string') {
        //eslint-disable-line no-useless-escape
        if (/^[\[|\{](\s|.*|\w)*[\]|\}]$/.test(jsonData)) {
            return true;
        }
    }
    return false
}

function isDummyObject(obj) {
    //eslint-disable-line no-useless-escape
    return JSON.stringify(obj) == "\"[object Object]\""
}

function tCombDateFormat(platform, date) {
    if(platform === 'web') {
        return new Date(date);
    }
    else {
        return moment.default(date).format("MM-DD-YYYY").toString()
    }
}

function onFormChange(value, compThisRef) {
    compThisRef.setState({value}); // <- keep track of value changes
  }

  function getErrorMessageFromErrorResp(errorResp, defaultmsg) {
      if(!defaultmsg) {
        defaultmsg = 'Error Performing action. Please contact administrator.'
      }
      var fluentMsg = checkFluentValidationErrors(errorResp);
      return fluentMsg ? fluentMsg : ((errorResp && errorResp.error) ? errorResp.error  : defaultmsg);
  }

  function checkFluentValidationErrors(errorResp) {
      var errorMessage = '';
      if(errorResp && errorResp.errors){
          for (const keyName in errorResp.errors) {
              if (errorResp.errors.hasOwnProperty(keyName)) {
                  const errorsArray = errorResp.errors[keyName];
                  if(errorsArray && errorsArray.length) {
                    errorMessage += errorsArray[0] + "\n";
                  }

              }
          }
      }
      return errorMessage;
  }

  const extTCombCompTypes = {
        pwdPolicyType: t.refinement(t.String, pwd => {
            const pwdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
            return pwdRegex.test(pwd);
        }),
        emailType: t.refinement(t.String, email => {
            const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; //or any other regexp
            return reg.test(email);
        }),
        pwdOption: {password: true,
            secureTextEntry: true
        },
        cellMaskedFormatConfig: {
            mask: 'custom',
            options:{
                mask: '(999)-999-9999',
            }
        }
    }


// Safari 3.0+ "[object HTMLElementConstructor]" 
// const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
function isSafari() {
    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1) { 
    if (ua.indexOf('chrome') > -1) {
        return false; // Chrome
    } else {
        return true // Safari
    }
    return false;
    }
}

async function saveDownloadedFile(resp, fileName, successCallBack) {
    // if (Platform.OS == "web") {

    //     var blob = new Blob([resp.data], { type: resp.headers["content-type"] }),
    //         url = window.URL.createObjectURL(blob),
    //         a = document.createElement('a')
    //     a.href = url;
    //     a.download = fileName;
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    // }
    // else {
    //     var RNFetchBlob = require('rn-fetch-blob').default
    //     let dirs = RNFetchBlob["fs"]["dirs"];
    //     let isPermissionGranted = false;
    //     var locationDownloaded = dirs.DownloadDir;
    //     if (Platform.OS == "android") {
    //         const granted = await PermissionsAndroid.request(
    //             PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    //             {
    //                 title: 'Foster Family Protal External Storage Permission',
    //                 message:
    //                     'Foster Family Protal needs access to your storage ' +
    //                     'so you can save files.',
    //                 buttonNeutral: 'Ask Me Later',
    //                 buttonNegative: 'Cancel',
    //                 buttonPositive: 'OK',
    //             },
    //         );
    //         console.log('permission acuired')
    //         isPermissionGranted = granted === PermissionsAndroid.RESULTS.GRANTED;
    //     }
    //     else {
    //         isPermissionGranted = true;
    //         locationDownloaded = dirs.DocumentDir;
    //     }
    //     if (isPermissionGranted) {
    //         // var base64 = RNFetchBlob.base64;
    //         // var base64Data = RNFetchBlob.base64.encode(resp.data);
    //         RNFetchBlob.fs.writeFile(locationDownloaded + '/' + fileName, resp.data, 'base64')
    //             .then((downloadPath, arg1, arg2) => {
    //                 FileViewer.open(locationDownloaded + '/' + fileName);
    //             }, (err) => console.log(err))
    //     }
    // }

    // if (successCallBack) {
    //     successCallBack();
    // }
    downloadFile(resp, fileName, successCallBack)
}



   export {
    resolvePath,
    getPropertyName,
    isJsonString,
    isDummyObject,
    tCombDateFormat,
    onFormChange,
    getErrorMessageFromErrorResp,
    extTCombCompTypes,
    isSafari,
    saveDownloadedFile
   }